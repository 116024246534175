import React from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { Route, RouteComponentProps } from 'react-router';
import MapBoxContainer from '../components/MapBoxContainer';
import './Home.css';

const Home: React.FC<RouteComponentProps> = ({match}) => {
  return (
  <IonRouterOutlet>
    <Route exact path={match.url} component={MapBoxContainer} />
  </IonRouterOutlet>
  );
};


export default Home;
