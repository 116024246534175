import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupConfig } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { Capacitor } from '@capacitor/core';
import { AppPreferences } from './preferences/preferences';

setupConfig({
  rippleEffect: true,
  mode: 'md'
});

const App: React.FC = () => {

  useEffect(() => {
    // Include OneTrust cookies script on production web builds
    if (!Capacitor.isNativePlatform() && AppPreferences.getAppPreferences().preferences.devPrefs.general.buildConfig!.production) {
      cookiesCheck();
    }
  });

  // Method to include OneTrust cookies script in page head
  function cookiesCheck() {
    const script = document.createElement('script');
    script.src = 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js';
    script.type = 'text/javascript';
    script.charset = 'UTF-8';
    script.setAttribute('data-domain-script', '52924642-40a5-4821-9dda-217bb0d67566-test');
    script.id = 'onetrustCookies';
    document.head.appendChild(script);
  }

  return (
    <IonApp className={Capacitor.isNativePlatform() || !AppPreferences.getAppPreferences().preferences.devPrefs.general.buildConfig!.production ? "noCookies" : ""}>
      {!AppPreferences.getAppPreferences().preferences.devPrefs.general.buildConfig!.production &&
        <div id="devMode">
          <div id="devModeDot"></div>
          <h5 id='devModeText'>Dev Mode</h5>
        </div>
      }
      {!AppPreferences.getAppPreferences().preferences.devPrefs.general.buildConfig!.production && AppPreferences.getAppPreferences().preferences.devPrefs.general.buildConfig!.collectAnalytics &&
        <div id="googleAnalyticsMode">
          <div id="googleAnalyticsModeDot"></div>
          <h5 id='googleAnalyticsModeText'>Google Analytics On</h5>
        </div>
      }

      <IonReactRouter>
        <IonRouterOutlet>
          <Route path="/" render={props => <Home {...props} />} />
          <Route exact path="/opendataportal" render={() => <Redirect to="/" />} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
