import React from 'react';
import './MapBoxContainer.css';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl, { GeoJSONSource } from '!mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { IonButton, IonContent, IonIcon, IonImg, IonItem, IonLabel, IonPage, withIonLifeCycle, isPlatform, IonModal, IonList, IonThumbnail, IonRow, IonCol, IonGrid } from '@ionic/react';
import MediaQuery from 'react-responsive';
import { arrowBack, arrowDown, caretBack, caretForward, carSportSharp, closeCircle, cubeOutline, downloadSharp, earthSharp, funnelSharp, home, imageOutline, key, mapOutline, menuSharp, prismOutline } from 'ionicons/icons';
import GenerationAvailability from './GenerationAvailability';
import NetworkCapacity from './NetworkCapacity';
import FilterPage from './FilterPage';
import Acronyms from './Acronyms';
import Downloads from './Downloads';
import { http, HttpResponse, dataResponse, infoURL, generationURL, demandURL, evChargerResponse } from '../helpers/httpHelper';
import fetchOutlineData from './fetchOutlineData';
import { addDataLayer } from './addDataLayer';
import fetchGenerationData from './fetchGenerationData';
import { GenerationCollection, FetchState, Coordinate } from './generationJSONStructure';
import GADetails from './GADetails';
import * as turf from '@turf/turf';
import { DemandCollection, Feature } from './demandJSONStructure';
import fetchDemandData from './fetchDemandData';
import DemandDetails from './DemandDetails';
import GASubstationDetails from './GASubstationDetails';
import DemandSubstationDetails from './DemandSubstationDetails';
import { throttle } from 'lodash';
import ReactTooltip from "react-tooltip";
import { logScreenView } from '../helpers/firebase';
import fetchEvData from './fetchEvData';
import EvList from './EvList';
import { EvFilterState } from '../helpers/EvFilterState';
import LicencesPage from './licences';
import ContactUsPage from './contactUs';
import { AppPreferences } from '../preferences/preferences';

export enum MapLoadState {
    IDLE,
    LOADING,
    SUCCESS,
    FAILED,
}

export enum MapStyle {
    SATELLITE,
    STREET,
    COLOURBLIND
}

function PageHeader(props: any) {
    return (
        <div className="pageHeader">
            <MediaQuery minWidth={821}>
                {(matches: any) =>
                    matches
                        ?
                        <IonImg src={"/assets/img/ssenlogo.jpg"} />
                        :
                        <IonImg src={"/assets/img/ssenlogo-small.png"} />
                }
            </MediaQuery>

            {props.titlePage === "generationAvailability" ? <h3 className="pageTitle">Generation Availability</h3> : <h3 className="pageTitle">Network Capacity</h3>}


            <IonButton id="returnToSsenButton" href="https://www.ssen.co.uk" size="small" color="primary" fill="solid" shape="round"><IonIcon md={home}></IonIcon><span>Back to SSEN</span></IonButton>
        </div>
    );
}

function drawKeys(props: any) {
    return (
        <>
            {(props.constrainedGSPSOn || props.unconstrainedGSPSOn || props.partiallyConstrainedGSPSOn) &&
                <div className="keyGroup">
                    {props.unconstrainedGSPSOn &&
                        <IonItem id="mapKeyItem" lines="none">
                            <IonIcon id="mapKeyIcon" src="/assets/img/unconstrainedGSP.svg" />
                            <IonLabel id="mapKeyLabel">Unconstrained GSP</IonLabel>
                        </IonItem>
                    }
                    {props.partiallyConstrainedGSPSOn &&
                        <IonItem id="mapKeyItem" lines="none">
                            <IonIcon id="mapKeyIcon" src="/assets/img/partiallyConstrainedGSP.svg" />
                            <IonLabel id="mapKeyLabel">Partially Constrained GSP</IonLabel>
                        </IonItem>
                    }
                    {props.constrainedGSPSOn &&
                        <IonItem id="mapKeyItem" lines="none">
                            <IonIcon id="mapKeyIcon" src="/assets/img/constrainedGSP.svg" />
                            <IonLabel id="mapKeyLabel">Constrained GSP</IonLabel>
                        </IonItem>
                    }
                </div>
            }
            {(props.constrainedBSPSOn || props.unconstrainedBSPSOn || props.partiallyConstrainedBSPSOn) &&
                <div className="keyGroup">
                    {props.unconstrainedBSPSOn &&
                        <IonItem id="mapKeyItem" lines="none">
                            <IonIcon id="mapKeyIcon" src="/assets/img/bulk_unconstrained.svg" />
                            <IonLabel id="mapKeyLabel">Unconstrained BSP</IonLabel>
                        </IonItem>
                    }
                    {props.partiallyConstrainedBSPSOn &&
                        <IonItem id="mapKeyItem" lines="none">
                            <IonIcon id="mapKeyIcon" src="/assets/img/bulk_partial.svg" />
                            <IonLabel id="mapKeyLabel">Partially Constrained BSP</IonLabel>
                        </IonItem>
                    }
                    {props.constrainedBSPSOn &&
                        <IonItem id="mapKeyItem" lines="none">
                            <IonIcon id="mapKeyIcon" src="/assets/img/bulk_constrained.svg" />
                            <IonLabel id="mapKeyLabel">Constrained BSP</IonLabel>
                        </IonItem>
                    }
                </div>
            }
            {(props.constrainedPSSSOn || props.unconstrainedPSSSOn || props.partiallyConstrainedPSSSOn) &&
                <div className="keyGroup">
                    {props.unconstrainedPSSSOn &&
                        <IonItem id="mapKeyItem" lines="none">
                            <IonIcon id="mapKeyIcon" src="/assets/img/substation_unconstrained.svg" />
                            <IonLabel id="mapKeyLabel">Unconstrained Substation</IonLabel>
                        </IonItem>
                    }
                    {props.partiallyConstrainedPSSSOn &&
                        <IonItem id="mapKeyItem" lines="none">
                            <IonIcon id="mapKeyIcon" src="/assets/img/substation_partial.svg" />
                            <IonLabel id="mapKeyLabel">Partially Constrained Substation</IonLabel>
                        </IonItem>
                    }
                    {props.constrainedPSSSOn &&
                        <IonItem id="mapKeyItem" lines="none">
                            <IonIcon id="mapKeyIcon" src="/assets/img/substation_constrained.svg" />
                            <IonLabel id="mapKeyLabel">Constrained Substation</IonLabel>
                        </IonItem>
                    }
                </div>
            }
            {props.isAssSubs &&
                <div className="keyGroup">
                    <IonItem id="mapKeyItem" lines="none">
                        <IonIcon id="mapKeyIcon" src="/assets/img/assSubstationUnconstrained.svg" />
                        <IonLabel id="mapKeyLabel">Associated Unconstrained Substation</IonLabel>
                    </IonItem>
                    <IonItem id="mapKeyItem" lines="none">
                        <IonIcon id="mapKeyIcon" src="/assets/img/assSubstationPartiallyConstrained.svg" />
                        <IonLabel id="mapKeyLabel">Associated Partially Constrained Substation</IonLabel>
                    </IonItem>
                    <IonItem id="mapKeyItem" lines="none">
                        <IonIcon id="mapKeyIcon" src="/assets/img/assSubstationConstrained.svg" />
                        <IonLabel id="mapKeyLabel">Associated Constrained Substation</IonLabel>
                    </IonItem>
                </div>
            }
            {props.isAssBSPs &&
                <div className="keyGroup">
                    <IonItem id="mapKeyItem" lines="none">
                        <IonIcon id="mapKeyIcon" src="/assets/img/assBulk_unconstrained.svg" />
                        <IonLabel id="mapKeyLabel">Associated Unconstrained BSPs</IonLabel>
                    </IonItem>
                    <IonItem id="mapKeyItem" lines="none">
                        <IonIcon id="mapKeyIcon" src="/assets/img/assBulk_partiallyConstrained.svg" />
                        <IonLabel id="mapKeyLabel">Associated Partially Constrained BSPs</IonLabel>
                    </IonItem>
                    <IonItem id="mapKeyItem" lines="none">
                        <IonIcon id="mapKeyIcon" src="/assets/img/assBulk_constrained.svg" />
                        <IonLabel id="mapKeyLabel">Associated Constrained BSPs</IonLabel>
                    </IonItem>
                </div>
            }
            {props.evChargersShown &&
                <div className="keyGroup">
                    <IonItem id="mapKeyItem" lines="none">
                        <IonIcon id="mapKeyIcon" src="/assets/img/evMapMarker.svg" />
                        <IonLabel id="mapKeyLabel">Available EV Chargers</IonLabel>
                    </IonItem>
                    <IonItem id="mapKeyItem" lines="none">
                        <IonIcon id="mapKeyIcon" className="grayscale" src="/assets/img/evMapMarker.svg" />
                        <IonLabel id="mapKeyLabel">Unavailable EV Chargers</IonLabel>
                    </IonItem>
                </div>
            }
        </>
    )
}

function MapKey(props: any) {

    // Get current used set value for map key expansion
    let mapKeyExpanded = props.mapKeyExpanded;
    let nothingToShow = false;
    if (!!!props.constrainedGSPSOn && !!!props.unconstrainedGSPSOn && !!!props.partiallyConstrainedGSPSOn
        && !!!props.constrainedBSPSOn && !!!props.unconstrainedBSPSOn && !!!props.partiallyConstrainedBSPSOn
        && !!!props.constrainedPSSSOn && !!!props.unconstrainedPSSSOn && !!!props.partiallyConstrainedPSSSOn && !!!props.evChargersShown) {
        nothingToShow = true;
    }

    if (mapKeyExpanded) {
        return (
            <>
                {props.pageLoaded === "generationAvailability" ?
                    <div className="mapKey" onClick={() => { props.toggleMapKey() }}>

                        <IonButton data-tip="Filter" onClick={e => props.showFilterFromMapKey(e)} size="small" className="show-filter-from-mapkey">
                            <IonLabel id="show-filter-label-from-mapkey">Filter</IonLabel><IonIcon id="show-filter-icon-from-mapkey" md={funnelSharp} />
                        </IonButton>

                        <h2 className="mapKeyH2">Key<IonIcon md={closeCircle} /></h2>
                        <div className="keyGroups">
                            {nothingToShow ?
                                <div className="keyGroup">
                                    <IonItem id="mapKeyItem" lines="none">
                                        {/* <IonIcon id="mapKeyIcon" src="/assets/img/assBulk_unconstrained.svg" /> */}
                                        <IonLabel id="mapKeyLabel">Please adjust filters</IonLabel>
                                    </IonItem>
                                </div>
                                : drawKeys(props)
                            }
                        </div>
                    </div>
                    :
                    <div className="mapKey" onClick={() => { props.toggleMapKey() }}>
                        <IonButton data-tip="Filter" onClick={e => props.showFilterFromMapKey(e)} size="small" className="show-filter-from-mapkey">
                            <IonLabel id="show-filter-label-from-mapkey">Filter</IonLabel><IonIcon id="show-filter-icon-from-mapkey" md={funnelSharp} />
                        </IonButton>
                        <h2 className="mapKeyH2"> Key<IonIcon md={closeCircle} /></h2>
                        <div className="keyGroups">
                            {(props.demandGSPsOn || props.demandBSPsOn || props.demandPSSsOn || props.demandAssSubs) &&
                                <div className="keyGroup">
                                    {props.demandGSPsOn &&
                                        <IonItem id="mapKeyItem" lines="none">
                                            <IonIcon id="mapKeyIcon" src="/assets/img/gsp_demand.svg" />
                                            <IonLabel id="mapKeyLabel">Grid Supply Points</IonLabel>
                                        </IonItem>
                                    }
                                    {props.demandBSPsOn &&
                                        <IonItem id="mapKeyItem" lines="none">
                                            <IonIcon id="mapKeyIcon" src="/assets/img/bulk_demand.svg" />
                                            <IonLabel id="mapKeyLabel">Bulk Supply Points</IonLabel>
                                        </IonItem>
                                    }
                                    {props.demandPSSsOn &&
                                        <IonItem id="mapKeyItem" lines="none">
                                            <IonIcon id="mapKeyIcon" src="/assets/img/substation_demand.svg" />
                                            <IonLabel id="mapKeyLabel">Substations</IonLabel>
                                        </IonItem>
                                    }
                                </div>
                            }
                            {props.isAssSubs &&
                                <div className="keyGroup">
                                    <IonItem id="mapKeyItem" lines="none">
                                        <IonIcon id="mapKeyIcon" src="/assets/img/demandAssSubstation.svg" />
                                        <IonLabel id="mapKeyLabel">Associated Substations</IonLabel>
                                    </IonItem>
                                </div>
                            }
                            {props.isAssBSPs &&
                                <div className="keyGroup">
                                    <IonItem id="mapKeyItem" lines="none">
                                        <IonIcon id="mapKeyIcon" src="/assets/img/assBulk_demand.svg" />
                                        <IonLabel id="mapKeyLabel">Associated BSPs</IonLabel>
                                    </IonItem>
                                </div>
                            }
                            {props.evChargersShown &&
                                <div className="keyGroup">
                                    <IonItem id="mapKeyItem" lines="none">
                                        <IonIcon id="mapKeyIcon" src="/assets/img/evMapMarker.svg" />
                                        <IonLabel id="mapKeyLabel">Available EV Chargers</IonLabel>
                                    </IonItem>
                                    <IonItem id="mapKeyItem" lines="none">
                                        <IonIcon id="mapKeyIcon" className="grayscale" src="/assets/img/evMapMarker.svg" />
                                        <IonLabel id="mapKeyLabel">Unavailable EV Chargers</IonLabel>
                                    </IonItem>
                                </div>
                            }

                        </div>
                    </div>
                }
            </>
        );
    } else {
        return (
            <>
                <div className="mapKeyClosed">
                    <IonButton className="customMapButton keyButtonOnMap" size="large" color="secondary" onClick={() => { props.toggleMapKey() }}>
                        <IonIcon md={key} />
                    </IonButton>
                </div>
            </>
        );
    }
}
class MapBoxContainer extends React.Component<{}, {}> {

    private mapContainer: any;
    public state: any;
    public mapTypeString = MapStyle.STREET;
    public gColl!: GenerationCollection;
    public dColl!: DemandCollection;

    private evFilterState: EvFilterState;

    private mapReturnBounds: any = undefined;

    constructor(props: any) {
        super(props);
        this.state = {
            mapObj: mapboxgl.Map,
            sideBar: false,
            pageLoaded: "generationAvailability",
            sortAtoZ: true,
            sortAtoZDemand: true,
            fetchStateDemand: FetchState.IDLE,
            fetchStateGeneration: FetchState.IDLE,
            mapLoadState: MapLoadState.IDLE,
            mapStyleLoaded: false,
            mapStyle: MapStyle.STREET,
            mapKeyExpanded: false,
            filterLoaded: false,
            constrainedGSPSOn: true,
            unconstrainedGSPSOn: true,
            partiallyConstrainedGSPSOn: true,
            constrainedBSPSOn: true,
            unconstrainedBSPSOn: true,
            partiallyConstrainedBSPSOn: true,
            constrainedPSSSOn: false,
            unconstrainedPSSSOn: false,
            partiallyConstrainedPSSSOn: false,
            demandGSPsOn: true,
            demandBSPsOn: true,
            demandPSSsOn: false,
            acronymsLoaded: false,
            downloadsLoaded: false,
            outlineData: JSON,
            mapOutlineData: null,
            gAJSON: this.gColl,
            redraw: false,
            gADetailsLoaded: false,
            associatedSubstations: null,
            featureClicked: null,
            substationDetailsLoaded: false,
            substationClicked: null,
            demandDetailsLoaded: false,
            associatedBSPs: null,
            parentGSP: null,
            demandJSON: this.dColl,
            evChargerFC: null,
            evFeatureClicked: null,
            multipleEvFeaturesClicked: [],
            evDetailsLoaded: false,
            disableEvButtons: true,
            showTsandCs: true,
            tandcsToggle: false,
            multipleFeaturesClicked: [],
            substationDetailsFromMapClick: false,
            northGAOn: true,
            southGAOn: true,
            northNCOn: true,
            southNCOn: true,
            nCToggles: {
                gspNorth: false,
                bspNorth: false,
                pssNorth: false,
                gspSouth: false,
                bspSouth: false,
                pssSouth: false
            },
            gAToggles: {
                gspNorth: false,
                bspNorth: false,
                pssNorth: false,
                gspSouth: false,
                bspSouth: false,
                pssSouth: false
            },
            tilted: false,
            showLicences: false,
            showContactUs: false,
            showMapTypeDialog: false,
        };
        this.evFilterState = new EvFilterState();
    }


    addPopUpHoversForAllLayers(map: any) {
        //Generation Availability
        this.addPopUpHoverForLayer('constrained-layer', map);
        this.addPopUpHoverForLayer('unconstrained-layer', map);
        this.addPopUpHoverForLayer('partial-constrained-layer', map);

        this.addPopUpHoverForLayer('bulk-constrained-layer', map);
        this.addPopUpHoverForLayer('bulk-unconstrained-layer', map);
        this.addPopUpHoverForLayer('bulk-partial-constrained-layer', map);

        this.addPopUpHoverForLayer('substation-constrained-layer', map);
        this.addPopUpHoverForLayer('substation-unconstrained-layer', map);
        this.addPopUpHoverForLayer('substation_partial', map);

        this.addPopUpHoverForLayer('ass-substation-constrained-layer', map);
        this.addPopUpHoverForLayer('ass-substation-unconstrained-layer', map);
        this.addPopUpHoverForLayer('ass-substation-partial-constrained-layer', map);

        this.addPopUpHoverForLayer('ass-bsp-constrained-layer', map);
        this.addPopUpHoverForLayer('ass-bsp-unconstrained-layer', map);
        this.addPopUpHoverForLayer('ass-bsp-partial-constrained-layer', map);

        //Network Capacity
        this.addPopUpHoverForLayer('gsp-demand-layer', map);
        this.addPopUpHoverForLayer('bulk-demand-layer', map);
        this.addPopUpHoverForLayer('substation-demand-layer', map);

        this.addPopUpHoverForLayer('ass-substation-demand-layer', map);
        this.addPopUpHoverForLayer('ass-bulk-demand-layer', map);

        //ev
        this.addPopUpHoverForLayer('ev-layer', map);
    }

    addPopUpHoverForLayer(layer: any, map: any) {
        // Create a popup, but don't add it to the map yet.
        const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
            offset: [0, -42]
        });

        map.on('mouseenter', layer, (e: any) => {

            // Change the cursor style as a UI indicator.
            map.getCanvas().style.cursor = 'pointer';

            // Copy coordinates array.
            const coordinates = e?.features[0]?.geometry?.coordinates.slice();
            const description = "<h6>" + e?.features[0]?.properties?.Name?.toUpperCase() + "</h6>";

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            // Populate the popup and set its coordinates
            // based on the feature found.
            if (layer !== "ev-layer") {
                popup.setLngLat(coordinates).setHTML(description).addTo(map);
            }
        });

        map.on('mouseleave', layer, () => {
            map.getCanvas().style.cursor = '';
            popup.remove();
        });

    }

    triggerShowDemandDetails = (feature: any) => {

        // if (this.state.parentGSP === null) {
        //     this.clearAssSubstations();
        //     this.clearAssBSPs();
        // }
        //grab list of substations

        let listSubs: any[] = [];
        listSubs = listSubs.concat(this.state.demandJSON?.pss.features);

        let CompositeId = feature.properties.CompositeId;
        const newList = listSubs.filter((p: { properties: any; }) => p.properties.ParentMarker === CompositeId);

        let listBSPs: any[] = [];
        listBSPs = listBSPs.concat(this.state.demandJSON?.bsp.features);
        const newBSPList = listBSPs.filter((p: { properties: any; }) => p.properties.ParentMarker === CompositeId);

        setTimeout(
            () => { this.zoomToLocationOnMap(feature?.geometry?.coordinates, feature) },
            100)

        this.setState({
            ...this.state,
            gADetailsLoaded: false,
            filterLoaded: false,
            acronymsLoaded: false,
            downloadsLoaded: false,
            substationDetailsLoaded: false,
            sideBar: true,
            featureClicked: feature,
            associatedSubstations: newList,
            substationClicked: null,
            demandDetailsLoaded: true,
            associatedBSPs: newBSPList,
            parentGSP: null,
            evDetailsLoaded: false
        });
    }

    showGAOrAssBSP(feature: any, showAss: any) {
        if (showAss) {
            this.showBSPDetails(feature);
        } else {
            this.triggerShowGADetails(feature);
        }
    }

    showDemandOrAssBSP(feature: any, showAss: any) {
        if (showAss) {
            this.showBSPDetails(feature);
        } else {
            this.triggerShowDemandDetails(feature);
        }
    }

    triggerShowGADetails = (feature: any) => {

        // if (this.state.parentGSP === null) {
        //     this.clearAssSubstations();
        //     this.clearAssBSPs();
        // }

        //grab list of substations
        let listSubs: any[] = [];
        listSubs = listSubs.concat(this.state.gAJSON?.substation_constrained.features);
        listSubs = listSubs.concat(this.state.gAJSON?.substation_unconstrained.features);
        listSubs = listSubs.concat(this.state.gAJSON?.substation_partiallyConstrained.features);

        let CompositeId = feature.properties.CompositeId;
        const newList = listSubs.filter((p: { properties: any; }) => p.properties.ParentMarker === CompositeId);

        let listBSPs: any[] = [];
        listBSPs = listBSPs.concat(this.state.gAJSON?.bulk_constrained.features);
        listBSPs = listBSPs.concat(this.state.gAJSON?.bulk_unconstrained.features);
        listBSPs = listBSPs.concat(this.state.gAJSON?.bulk_partiallyConstrained.features);

        const newBSPList = listBSPs.filter((q: { properties: any; }) => q.properties.ParentMarker === CompositeId);

        setTimeout(
            () => { this.zoomToLocationOnMap(feature?.geometry?.coordinates, feature) },
            100)

        this.setState({
            ...this.state,
            gADetailsLoaded: true,
            filterLoaded: false,
            acronymsLoaded: false,
            downloadsLoaded: false,
            substationDetailsLoaded: false,
            sideBar: true,
            featureClicked: feature,
            associatedSubstations: newList,
            substationClicked: null,
            demandDetailsLoaded: false,
            associatedBSPs: newBSPList,
            parentGSP: null,
            evDetailsLoaded: false
        });
    }

    showSubstationDetails = (feature: any, fromMapClick: any) => {

        this.setState({
            ...this.state,
            substationDetailsLoaded: true,
            filterLoaded: false,
            acronymsLoaded: false,
            downloadsLoaded: false,
            sideBar: true,
            substationClicked: feature,
            gADetailsLoaded: false,
            substationDetailsFromMapClick: fromMapClick,
            demandDetailsLoaded: false,
            evDetailsLoaded: false
        });

        setTimeout(
            () => { this.zoomToLocationOnMap(feature?.geometry?.coordinates, feature) },
            100)

        if (fromMapClick) {
            this.setState({ featureClicked: null });
        }
    }

    showBSPDetails = (feature: any) => {

        if (this.state.pageLoaded === "networkCapacity") {
            let listSubs: any[] = [];
            listSubs = listSubs.concat(this.state.demandJSON?.pss.features);

            let CompositeId = feature.properties.CompositeId;
            const newList = listSubs.filter((p: { properties: any; }) => p.properties.ParentMarker === CompositeId);

            let listBSPs: any[] = [];
            listBSPs = listBSPs.concat(this.state.demandJSON?.bsp.features);
            const newBSPList = listBSPs.filter((p: { properties: any; }) => p.properties.ParentMarker === CompositeId);

            let previousClicked = this.state.featureClicked;

            this.setState({
                ...this.state,
                gADetailsLoaded: false,
                filterLoaded: false,
                acronymsLoaded: false,
                downloadsLoaded: false,
                substationDetailsLoaded: false,
                sideBar: true,
                featureClicked: feature,
                associatedSubstations: newList,
                substationClicked: null,
                demandDetailsLoaded: true,
                associatedBSPs: newBSPList,
                parentGSP: previousClicked,
                evDetailsLoaded: false,
            });

            setTimeout(
                () => { this.zoomToLocationOnMap(feature?.geometry?.coordinates, feature) },
                100)
        } else {
            //grab list of substations
            let listSubs: any[] = [];
            listSubs = listSubs.concat(this.state.gAJSON?.substation_constrained.features);
            listSubs = listSubs.concat(this.state.gAJSON?.substation_unconstrained.features);
            listSubs = listSubs.concat(this.state.gAJSON?.substation_partiallyConstrained.features);

            let CompositeId = feature.properties.CompositeId;
            const newList = listSubs.filter((p: { properties: any; }) => p.properties.ParentMarker === CompositeId);

            let listBSPs: any[] = [];
            listBSPs = listBSPs.concat(this.state.gAJSON?.bulk_constrained.features);
            listBSPs = listBSPs.concat(this.state.gAJSON?.bulk_unconstrained.features);
            listBSPs = listBSPs.concat(this.state.gAJSON?.bulk_partiallyConstrained.features);

            const newBSPList = listBSPs.filter((q: { properties: any; }) => q.properties.ParentMarker === CompositeId);

            let previousClicked = this.state.featureClicked;

            this.setState({
                ...this.state,
                gADetailsLoaded: true,
                filterLoaded: false,
                acronymsLoaded: false,
                downloadsLoaded: false,
                substationDetailsLoaded: false,
                sideBar: true,
                featureClicked: feature,
                associatedSubstations: newList,
                substationClicked: null,
                demandDetailsLoaded: false,
                associatedBSPs: newBSPList,
                parentGSP: previousClicked,
                evDetailsLoaded: false
            });

            setTimeout(
                () => { this.zoomToLocationOnMap(feature?.geometry?.coordinates, feature) },
                100)
        }
    }

    showCorrespondingGSP = (parentId: any, fromBSP: any) => {
        let assSubs: any[] = this.state.associatedSubstations;
        let assBSPs: any[] = this.state.associatedBSPs;

        //find parent GSP, 
        if (this.state.pageLoaded === "networkCapacity") {
            if ((fromBSP && assBSPs.length > 0) || (!!!fromBSP && assSubs?.length > 0)) {
                setTimeout(
                    () => { this.zoomToLocationOnMap(this.state.featureClicked?.geometry?.coordinates, this.state.featureClicked) },
                    100)
                this.setState({ demandDetailsLoaded: !this.state.demandDetailsLoaded, gADetailsLoaded: false, filterLoaded: false, sideBar: true, acronymsLoaded: false, downloadsLoaded: false, substationDetailsLoaded: false, substationDetailsFromMapClick: false });
            } else {
                let features: any[] = [];
                let dataToSearch: any[] = this.state.demandJSON?.gsp.features;
                dataToSearch = dataToSearch.concat(this.state.demandJSON?.bsp.features);

                features = dataToSearch.filter((p: { properties: any; }) => p.properties.CompositeId === parentId);
                if (features.length > 0) {
                    this.triggerShowDemandDetails(features[0]);
                }
            }
        } else {
            if ((fromBSP && assBSPs.length > 0) || (!!!fromBSP && assSubs?.length > 0)) {
                setTimeout(
                    () => { this.zoomToLocationOnMap(this.state.featureClicked?.geometry?.coordinates, this.state.featureClicked) },
                    100)
                this.setState({ demandDetailsLoaded: false, gADetailsLoaded: !this.state.gADetailsLoaded, filterLoaded: false, sideBar: true, acronymsLoaded: false, downloadsLoaded: false, substationDetailsLoaded: false, substationDetailsFromMapClick: false });
            } else {
                let features: any[] = [];
                let dataToSearch: any[] = this.state.gAJSON?.constrained.features;
                dataToSearch = dataToSearch.concat(this.state.gAJSON?.unconstrained.features);
                dataToSearch = dataToSearch.concat(this.state.gAJSON?.partiallyConstrained.features);
                dataToSearch = dataToSearch.concat(this.state.gAJSON?.bulk_constrained.features);
                dataToSearch = dataToSearch.concat(this.state.gAJSON?.bulk_unconstrained.features);
                dataToSearch = dataToSearch.concat(this.state.gAJSON?.bulk_partiallyConstrained.features);

                features = dataToSearch.filter((p: { properties: any; }) => p.properties.CompositeId === parentId);
                if (features.length > 0) {
                    this.triggerShowGADetails(features[0]);
                }
            }
        }
    }

    showAssociatedBSPs() {
        var isIos = isPlatform('ios');
        var android = isPlatform('android');
        if (isIos || android) {
            this.showSidebar();
        }

        if (this.state.pageLoaded === "networkCapacity") {
            if (this.state.mapObj !== undefined && this.state.fetchStateDemand === FetchState.SUCCESS) {
                if (typeof this.state.mapObj.getSource('demand') !== 'undefined') {

                    let dataToDisplay: any[] = [];
                    dataToDisplay = dataToDisplay.concat(this.state.associatedBSPs);

                    if (typeof this.state.mapObj.getSource('associatedDemandBSPs') !== 'undefined') {
                        (this.state.mapObj.getSource("associatedDemandBSPs") as GeoJSONSource)?.setData({
                            type: 'FeatureCollection',
                            features: dataToDisplay
                        });
                    }
                    this.createLinesToCorrespondingGSP(this.state.associatedBSPs);
                    this.zoomToAssBSPBounds();
                }
            }
        } else {
            if (this.state.mapObj !== undefined && this.state.fetchStateGeneration === FetchState.SUCCESS) {
                if (typeof this.state.mapObj.getSource('generation') !== 'undefined') {

                    let dataToDisplay: any[] = [];
                    dataToDisplay = dataToDisplay.concat(this.state.associatedBSPs);

                    if (typeof this.state.mapObj.getSource('associatedBSPs') !== 'undefined') {
                        (this.state.mapObj.getSource("associatedBSPs") as GeoJSONSource)?.setData({
                            type: 'FeatureCollection',
                            features: dataToDisplay
                        });
                    }
                    this.createLinesToCorrespondingGSP(this.state.associatedBSPs);
                    this.zoomToAssBSPBounds();
                }
            }
        }
    }

    createLinesToCorrespondingGSP(associatedDataSet: any) {

        const lines: any = [];
        let gspCoors = this.state.featureClicked?.geometry?.coordinates;
        let parentId = this.state.featureClicked?.properties?.CompositeId;

        //for some reason the clicked coors can be slightly off so grab the actual gsp
        if (this.state.pageLoaded === "networkCapacity") {
            let features: any[] = [];
            let dataToSearch: any[] = this.state.demandJSON?.gsp.features;
            dataToSearch = dataToSearch.concat(this.state.demandJSON?.bsp.features);
            features = dataToSearch.filter((p: { properties: any; }) => p.properties.CompositeId === parentId);
            if (features.length > 0) {
                gspCoors = features[0].geometry.coordinates;
            }
        } else {
            let features: any[] = [];
            let dataToSearch: any[] = this.state.gAJSON?.constrained.features;
            dataToSearch = dataToSearch.concat(this.state.gAJSON?.unconstrained.features);
            dataToSearch = dataToSearch.concat(this.state.gAJSON?.partiallyConstrained.features);
            dataToSearch = dataToSearch.concat(this.state.gAJSON?.bulk_constrained.features);
            dataToSearch = dataToSearch.concat(this.state.gAJSON?.bulk_unconstrained.features);
            dataToSearch = dataToSearch.concat(this.state.gAJSON?.bulk_partiallyConstrained.features);

            features = dataToSearch?.filter((p: { properties: any; }) => p.properties.CompositeId === parentId);
            if (features?.length > 0) {
                gspCoors = features[0].geometry.coordinates;
            }
        }

        if (gspCoors !== undefined) {
            associatedDataSet.map((subData: any) => {
                const line = {
                    "type": "Feature",
                    geometry: {
                        type: 'LineString',
                        coordinates: [subData?.geometry.coordinates, gspCoors]
                    },
                    properties: {}
                };

                lines.push(line)
            });
        }


        if (typeof this.state.mapObj.getSource('correspondingGSP') !== 'undefined') {
            (this.state.mapObj.getSource("correspondingGSP") as GeoJSONSource)?.setData({
                type: 'FeatureCollection',
                features: lines
            });
        }

        return true;
    }

    showSubstations() {
        var isIos = isPlatform('ios');
        var android = isPlatform('android');
        if (isIos || android) {
            this.showSidebar();
        }

        if (this.state.pageLoaded === "networkCapacity") {
            if (this.state.mapObj !== undefined && this.state.fetchStateDemand === FetchState.SUCCESS) {
                if (typeof this.state.mapObj.getSource('demand') !== 'undefined') {

                    let dataToDisplay: any[] = [];
                    dataToDisplay = dataToDisplay.concat(this.state.associatedSubstations);

                    if (typeof this.state.mapObj.getSource('associatedDemandSubstations') !== 'undefined') {
                        (this.state.mapObj.getSource("associatedDemandSubstations") as GeoJSONSource)?.setData({
                            type: 'FeatureCollection',
                            features: dataToDisplay
                        });
                    }

                    this.createLinesToCorrespondingGSP(this.state.associatedSubstations);
                    this.zoomToAssSubstationBounds();
                }
            }
        } else {
            if (this.state.mapObj !== undefined && this.state.fetchStateGeneration === FetchState.SUCCESS) {
                if (typeof this.state.mapObj.getSource('generation') !== 'undefined') {

                    let dataToDisplay: any[] = [];
                    dataToDisplay = dataToDisplay.concat(this.state.associatedSubstations);

                    if (typeof this.state.mapObj.getSource('associatedSubstations') !== 'undefined') {
                        (this.state.mapObj.getSource("associatedSubstations") as GeoJSONSource)?.setData({
                            type: 'FeatureCollection',
                            features: dataToDisplay
                        });
                    }

                    this.createLinesToCorrespondingGSP(this.state.associatedSubstations);
                    this.zoomToAssSubstationBounds();
                }
            }
        }
    }

    showSubstationOnMap() {

    }

    async zoomToAssSubstationBounds() {

        var bounds = new mapboxgl.LngLatBounds();
        this.state.associatedSubstations.forEach(function (feature: any) {
            bounds.extend(feature?.geometry?.coordinates);
        });

        bounds.extend(this.state.featureClicked?.geometry?.coordinates);

        let fitBoundsOptions: any = { padding: 100 };
        if (window.innerWidth <= 640) {
            fitBoundsOptions.duration = 0;
        }

        if (this.state.associatedSubstations.length + 1 > 1) {
            this.state.mapObj.fitBounds(bounds, fitBoundsOptions);
        } else if (this.state.associatedSubstations.length === 1) {
            let feature = this.state.associatedSubstations[0];
            if (window.innerWidth <= 640) {
                this.state.mapObj.jumpTo({
                    center: feature?.geometry?.coordinates,
                    zoom: 16
                });
            } else {
                this.state.mapObj.flyTo({
                    center: feature?.geometry?.coordinates,
                    zoom: 16
                });
            }
        }
    }

    async zoomToAssBSPBounds() {

        var bounds = new mapboxgl.LngLatBounds();
        this.state.associatedBSPs.forEach(function (feature: any) {
            bounds.extend(feature?.geometry?.coordinates);
        });

        bounds.extend(this.state.featureClicked?.geometry?.coordinates);

        let fitBoundsOptions: any = { padding: 100 };
        if (window.innerWidth <= 640) {
            fitBoundsOptions.duration = 0;
        }

        if (this.state.associatedBSPs.length + 1 > 1) {
            this.state.mapObj.fitBounds(bounds, fitBoundsOptions);
        } else if (this.state.associatedBSPs.length === 1) {
            let feature = this.state.associatedBSPs[0];
            if (window.innerWidth <= 640) {
                this.state.mapObj.jumpTo({
                    center: feature?.geometry?.coordinates,
                    zoom: 16
                });
            } else {
                this.state.mapObj.flyTo({
                    center: feature?.geometry?.coordinates,
                    zoom: 16
                });
            }
        }
    }

    async clearAssociatedAssets() {
        this.clearAssSubstations();
        this.clearAssBSPs();
    }

    async clearAssSubstations() {

        this.setState({ associatedSubstations: [] });

        if (typeof this.state.mapObj.getSource('associatedDemandSubstations') !== 'undefined') {
            (this.state.mapObj.getSource("associatedDemandSubstations") as GeoJSONSource)?.setData({
                type: 'FeatureCollection',
                features: []
            });
            (this.state.mapObj.getSource("correspondingGSP") as GeoJSONSource)?.setData({
                type: 'FeatureCollection',
                features: []
            });
        }

        if (typeof this.state.mapObj.getSource('associatedSubstations') !== 'undefined') {
            (this.state.mapObj.getSource("associatedSubstations") as GeoJSONSource)?.setData({
                type: 'FeatureCollection',
                features: []
            });
            (this.state.mapObj.getSource("correspondingGSP") as GeoJSONSource)?.setData({
                type: 'FeatureCollection',
                features: []
            });
        }

    }

    async clearAssBSPs() {

        this.setState({ associatedBSPs: [] });

        if (typeof this.state.mapObj.getSource('associatedDemandBSPs') !== 'undefined') {
            (this.state.mapObj.getSource("associatedDemandBSPs") as GeoJSONSource)?.setData({
                type: 'FeatureCollection',
                features: []
            });
        }
        if (typeof this.state.mapObj.getSource('associatedBSPs') !== 'undefined') {
            (this.state.mapObj.getSource("associatedBSPs") as GeoJSONSource)?.setData({
                type: 'FeatureCollection',
                features: []
            });
        }
    }

    shouldComponentUpdate(nextProps: any, nextState: any) {

        // Zoom to fault location if fault details view is being shown.
        // if (nextState.filterLoaded === false) {
        //     if (nextState.gADetailsLoaded === true && nextState.featureClicked !== null && nextState.sideBar === true) {
        //         setTimeout(
        //             () => { this.zoomToLocationOnMap(nextState.featureClicked?.geometry?.coordinates, nextState.featureClicked) },
        //             100)
        //     }

        //     if (nextState.demandDetailsLoaded === true && nextState.featureClicked !== null && nextState.sideBar === true) {
        //         setTimeout(
        //             () => { this.zoomToLocationOnMap(nextState.featureClicked?.geometry?.coordinates, nextState.featureClicked) },
        //             100)
        //     }

        //     if (nextState.substationDetailsLoaded === true && nextState.substationClicked !== null && nextState.sideBar === true) {
        //         setTimeout(
        //             () => { this.zoomToLocationOnMap(nextState.substationClicked?.geometry?.coordinates, nextState.substationClicked) },
        //             100)
        //     }

        // }

        return true;
    }

    setUserLocation = () => {
        // let setUserLocation = {
        //     lat: 56.41735079911683,
        //     lng: -3.46419005579868
        // };
        // this.setState({
        //     userLocation: setUserLocation
        // });

        navigator.geolocation.getCurrentPosition((position) => {
            //NOT BEING CALLED
            let setUserLocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };

            // //TODO: TAKE ME OUT! OVERRIDDEN
            // let setUserLocation = {
            //     lat: 55.780445,
            //     lng: -5.036192
            // };

            this.setState({
                userLocation: setUserLocation
            });
        }, function (e) {
            //Your error handling here
        }, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        });


    };

    clickedGA = (e: any, map: any) => {
        let layers: string | any[] = [];

        if (map.getLayer('bulk-constrained-layer') !== undefined) {
            layers = layers.concat('bulk-constrained-layer');
        }
        if (map.getLayer('bulk-unconstrained-layer') !== undefined) {
            layers = layers.concat('bulk-unconstrained-layer');
        }
        if (map.getLayer('bulk-partial-constrained-layer') !== undefined) {
            layers = layers.concat('bulk-partial-constrained-layer');
        }
        if (map.getLayer('constrained-layer') !== undefined) {
            layers = layers.concat('constrained-layer');
        }
        if (map.getLayer('unconstrained-layer') !== undefined) {
            layers = layers.concat('unconstrained-layer');
        }
        if (map.getLayer('partial-constrained-layer') !== undefined) {
            layers = layers.concat('partial-constrained-layer');
        }
        if (map.getLayer('substation-constrained-layer') !== undefined) {
            layers = layers.concat('substation-constrained-layer');
        }
        if (map.getLayer('substation-unconstrained-layer') !== undefined) {
            layers = layers.concat('substation-unconstrained-layer');
        }
        if (map.getLayer('substation-partial-constrained-layer') !== undefined) {
            layers = layers.concat('substation-partial-constrained-layer');
        }

        if (map.getLayer('ass-substation-constrained-layer') !== undefined) {
            layers = layers.concat('ass-substation-constrained-layer');
        }
        if (map.getLayer('ass-substation-unconstrained-layer') !== undefined) {
            layers = layers.concat('ass-substation-unconstrained-layer');
        }
        if (map.getLayer('ass-substation-partial-constrained-layer') !== undefined) {
            layers = layers.concat('ass-substation-partial-constrained-layer');
        }

        if (map.getLayer('ass-bsp-constrained-layer') !== undefined) {
            layers = layers.concat('ass-bsp-constrained-layer');
        }
        if (map.getLayer('ass-bsp-unconstrained-layer') !== undefined) {
            layers = layers.concat('ass-bsp-unconstrained-layer');
        }
        if (map.getLayer('ass-bsp-partial-constrained-layer') !== undefined) {
            layers = layers.concat('ass-bsp-partial-constrained-layer');
        }

        var features = map.queryRenderedFeatures(e.point, { layers: layers });

        if (!features.length) {
            return;
        } else {

            let multipleFeatures = [];
            let nearbyFound = false;
            for (let i = 0; i < features.length; i++) {
                const feature = features[i];
                for (let j = 0; j < features.length; j++) {
                    const feature2 = features[j];
                    if (feature.properties.CompositeId !== feature2.properties.CompositeId) {
                        if (feature.geometry.type === 'Point' && feature2.geometry.type === 'Point') {
                            var from = turf.point([feature.geometry.coordinates[0], feature.geometry.coordinates[1]]);
                            var to = turf.point([feature2.geometry.coordinates[0], feature2.geometry.coordinates[1]]);
                            var distance = turf.distance(from, to, { units: 'kilometers' });

                            if (distance < 0.025) {
                                nearbyFound = true;
                                multipleFeatures.push(feature2);
                            }
                        }
                    }
                }
                if (nearbyFound === true) {
                    multipleFeatures.unshift(feature);
                    break;
                }
            }

            if (multipleFeatures.length < 2) {

                var feature = features[0];

                if (feature.layer.id === "ass-substation-constrained-layer" || feature.layer.id === "ass-substation-unconstrained-layer" || feature.layer.id === "ass-substation-partial-constrained-layer") {
                    this.showSubstationDetails(feature, false);
                } else if (feature.layer.id === "ass-bsp-constrained-layer" || feature.layer.id === "ass-bsp-unconstrained-layer" || feature.layer.id === "ass-bsp-partial-constrained-layer") {
                    this.showBSPDetails(feature);
                } else {

                    let classificationText = feature?.properties?.Classification;
                    if (classificationText !== null && classificationText.startsWith("PSS")) {
                        //check if the substation is in the associated List
                        const isFeatureInAssList = this.state.associatedSubstations?.filter((p: { properties: any; }) => p.properties.CompositeId === feature?.properties?.CompositeId);
                        if (isFeatureInAssList?.length === 0) {
                            this.clearAssociatedAssets();
                        }

                        this.showSubstationDetails(feature, true);
                    } else if (classificationText !== null && classificationText.startsWith("BSP")) {
                        const isFeatureInAssList = this.state.associatedBSPs?.filter((p: { properties: any; }) => p.properties.CompositeId === feature?.properties?.CompositeId);
                        if (isFeatureInAssList?.length === 0) {
                            if (feature?.properties?.CompositeId !== this.state.featureClicked?.properties?.CompositeId) {
                                this.clearAssociatedAssets();
                            }
                            this.triggerShowGADetails(feature);
                        } else {
                            this.showBSPDetails(feature);
                        }
                    } else {
                        if (feature?.properties?.CompositeId !== this.state.featureClicked?.properties?.CompositeId) {
                            this.clearAssociatedAssets();
                        }
                        this.triggerShowGADetails(feature);
                    }
                }
            } else {
                this.setState({
                    multipleFeaturesClicked: multipleFeatures
                });
                if (multipleFeatures[0].geometry.type === 'Point') {
                    let zoomLevel = this?.state?.mapObj?.getZoom();
                    if (zoomLevel < 16) {
                        zoomLevel = 16;
                    }
                    map.flyTo({
                        center: [
                            multipleFeatures[0].geometry.coordinates[0],
                            multipleFeatures[0].geometry.coordinates[1]
                        ],
                        zoom: zoomLevel
                    });

                }
            }
        }
    }

    clickedDemand = (e: any, map: any) => {
        let layers: string | any[] = [];
        if (map.getLayer('gsp-demand-layer') !== undefined) {
            layers = layers.concat('gsp-demand-layer');
        }
        if (map.getLayer('bulk-demand-layer') !== undefined) {
            layers = layers.concat('bulk-demand-layer');
        }
        if (map.getLayer('substation-demand-layer') !== undefined) {
            layers = layers.concat('substation-demand-layer');
        }
        if (map.getLayer('ass-bulk-demand-layer') !== undefined) {
            layers = layers.concat('ass-bulk-demand-layer');
        }
        if (map.getLayer('ass-substation-demand-layer') !== undefined) {
            layers = layers.concat('ass-substation-demand-layer');
        }

        var features = map.queryRenderedFeatures(e.point, { layers: layers });

        if (!features.length) {
            return;
        } else {
            let multipleFeatures = [];
            let nearbyFound = false;
            for (let i = 0; i < features.length; i++) {
                const feature = features[i];
                for (let j = 0; j < features.length; j++) {
                    const feature2 = features[j];
                    if (feature.properties.CompositeId !== feature2.properties.CompositeId) {
                        if (feature.geometry.type === 'Point' && feature2.geometry.type === 'Point') {
                            var from = turf.point([feature.geometry.coordinates[0], feature.geometry.coordinates[1]]);
                            var to = turf.point([feature2.geometry.coordinates[0], feature2.geometry.coordinates[1]]);
                            var distance = turf.distance(from, to, { units: 'kilometers' });

                            if (distance < 0.025) {
                                nearbyFound = true;
                                multipleFeatures.push(feature2);
                            }
                        }
                    }
                }
                if (nearbyFound === true) {
                    multipleFeatures.unshift(feature);
                    break;
                }
            }

            if (multipleFeatures.length < 2) {
                var feature = features[0];

                if (feature.layer.id === "ass-substation-demand-layer") {
                    this.showSubstationDetails(feature, false);
                } else if (feature.layer.id === "ass-bulk-demand-layer") {
                    this.showBSPDetails(feature);
                } else if (feature.layer.id === "substation-demand-layer") {
                    const isFeatureInAssList = this.state.associatedSubstations?.filter((p: { properties: any; }) => p.properties.CompositeId === feature?.properties?.CompositeId);
                    if (isFeatureInAssList?.length === 0) {
                        this.clearAssociatedAssets();
                    }
                    this.showSubstationDetails(feature, true);
                } else if (feature.layer.id === 'bulk-demand-layer') {
                    const isFeatureInAssList = this.state.associatedBSPs?.filter((p: { properties: any; }) => p.properties.CompositeId === feature?.properties?.CompositeId);
                    if (isFeatureInAssList?.length === 0) {
                        if (feature?.properties?.CompositeId !== this.state.featureClicked?.properties?.CompositeId) {
                            this.clearAssociatedAssets();
                        }
                        this.triggerShowDemandDetails(feature);
                    } else {
                        this.showBSPDetails(feature);
                    }
                } else {
                    if (feature?.properties?.CompositeId !== this.state.featureClicked?.properties?.CompositeId) {
                        this.clearAssociatedAssets();
                    }
                    this.triggerShowDemandDetails(feature);
                }
            } else {
                this.setState({
                    multipleFeaturesClicked: multipleFeatures
                });
                if (multipleFeatures[0].geometry.type === 'Point') {
                    let zoomLevel = this?.state?.mapObj?.getZoom();
                    if (zoomLevel < 16) {
                        zoomLevel = 16;
                    }
                    map.flyTo({
                        center: [
                            multipleFeatures[0].geometry.coordinates[0],
                            multipleFeatures[0].geometry.coordinates[1]
                        ],
                        zoom: zoomLevel
                    });

                }
            }
        }
    }

    ionViewWillEnter() {
        logScreenView("odp_map_view", "MapView_ODP");
        this.state.mapObj.resize();
    }

    componentDidMount() {

        setTimeout(() => {
            if (this.state.mapLoadState === MapLoadState.IDLE) {
                this.setState({ mapLoadState: MapLoadState.FAILED });
            }

            // if (this.state.fetchStateDemand === FetchState.IDLE || this.state.fetchStateGeneration === FetchState.IDLE) {
            //     this.fetchData(map, false);
            // }
        }, 10000);

        mapboxgl.accessToken = AppPreferences.getAppPreferences().preferences.devPrefs.map.accessTokens!.web as string;

        // Show sidebar on launch if desktop
        if (window.innerWidth <= 640) {
            this.setState({ sideBar: false });
        } else {
            this.setState({ sideBar: true });
        }

        const isDesktop = window.screen.width > 765;
        if (isDesktop) {
            this.setState({ mapKeyExpanded: true });
        }

        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style:  AppPreferences.getAppPreferences().preferences.devPrefs.map.styles!.street,
            center: [-2.7533, 54.9484],
            zoom: 4.5,
            maxPitch: 60,
            trackResize: true,
        });

        this.setState({ mapObj: map });

        if (window.innerWidth <= 640) {
            map.setPadding({ top: 80, bottom: 30, left: 30, right: 30 });
        } else {
            map.setPadding({ top: 100, bottom: 80, left: 30, right: 30 });
        }

        // add navigation control (the +/- zoom buttons)
        map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
        map.addControl(new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        })
            , "bottom-right");

        const searchEquipment = (query: any) => {
            let geocodes: any = [];

            if (this.state.mapObj !== undefined && this.state.fetchStateDemand === FetchState.SUCCESS && this.state.fetchStateGeneration === FetchState.SUCCESS) {
                if (this.state.pageLoaded === "networkCapacity") {
                    let dataToSearch: any[] = this.getFitleredDemandData(this.state.demandJSON);
                    geocodes = dataToSearch.filter((p: { place_name: any; }) => p.place_name.includes(query.toUpperCase()));
                } else {
                    let dataToSearch: any[] = this.getFilteredGAData(this.state.gAJSON);
                    geocodes = dataToSearch.filter((p: { place_name: any; }) => p.place_name.includes(query.toUpperCase()));
                }
            }

            return geocodes;
        }

        const geocoder = new MapboxGeocoder({ // Initialize the geocoder
            accessToken: mapboxgl.accessToken, // Set the access token
            localGeocoder: searchEquipment,
            marker: false, // Do not use the default marker style
            placeholder: 'Search Map', // Placeholder text for the search bar
            bbox: [-10.854492, 49.771805, 2.2558585, 61.107866],
            mapboxgl: map
        });

        // Add the geocoder to the map
        map.addControl(geocoder, 'top-left');

        geocoder.on('result', (e) => {
            // map.once('moveend', () => {
            if (e !== null) {
                var geocoder_result = e.result;
                console.log(geocoder_result)
                if (geocoder_result !== null) {
                    console.log("RESULT");
                    if (geocoder_result.place_type === "SourceData") {
                        var searchBox = (document.querySelector(".mapboxgl-ctrl-geocoder--input") as HTMLInputElement);
                        if (searchBox !== null) {
                            console.log("SEARCH BOX NOT NULL");
                            searchBox.value = geocoder_result.properties?.Name.toUpperCase();
                        }

                        if (this.state.pageLoaded === "networkCapacity") {
                            this.triggerShowDemandDetails(geocoder_result);
                        } else {
                            this.triggerShowGADetails(geocoder_result);
                        }
                    }
                }
                // });
            }
        });

        //Deal with <img src=/assets/img/constrainedGSP.svg height=15></img> 
        var inputField = (document.querySelector(".mapboxgl-ctrl-geocoder--input") as HTMLInputElement);
        if (inputField !== null) {
            inputField.addEventListener('change', () => {
                if (inputField.value.startsWith("<img src=/assets/img/")) {
                    var last = inputField.value.indexOf("> ");//, inputField.value.indexOf(">") + 1);
                    inputField.value = inputField.value.substring(last + 3, inputField.value.length);
                }
            });

        }

        map.on('touchend', () => {
            handleTouchAndClickUp();
        });

        map.on('mouseup', () => {
            handleTouchAndClickUp();
        });

        const handleTouchAndClickUp = () => {
            this.mapReturnBounds = map.getBounds();
        }

        map.on('move', () => {
            debouncedCheckIfRegionShouldShow();
            debouncedCheckIfEvButtonShouldShow();

            if (this.state.showMapTypeDialog) {
                this.setState({ showMapTypeDialog: false });
            }
        });

        map.on('load', async () => {
            this.setState({ mapLoadState: MapLoadState.SUCCESS });
            map.resize();
            this.fetchData(map, false);
            addDataLayer(map, "");
        });

        map.on('style.load', async () => {

            const waiting = () => {
                if (!map.isStyleLoaded()) {
                    if (this.state.mapStyleLoaded === true) {
                        this.setState({ mapStyleLoaded: false });
                        if (map !== undefined) {
                            map.boxZoom.disable();
                            map.doubleClickZoom.disable();
                            map.dragPan.disable();
                            map.dragRotate.disable();
                            map.keyboard.disable();
                            map.scrollZoom.disable();
                            map.touchPitch.disable();
                            map.touchZoomRotate.disable();
                        }
                    }
                    setTimeout(waiting, 200);
                } else {
                    try {
                        if (map !== undefined) {

                            if (this.state.mapStyleLoaded === false) {
                                this.setState({ mapStyleLoaded: true });
                                map.boxZoom.enable();
                                map.doubleClickZoom.enable();
                                map.dragPan.enable();
                                map.dragRotate.enable();
                                map.keyboard.enable();
                                map.scrollZoom.enable();
                                map.touchPitch.enable();
                                map.touchZoomRotate.enable();
                            }

                            //check if first time by seeing if layers exist, if there is no region then we are changing style 
                            // by the radio button and not the first time map is loaded
                            if (typeof map.getSource('region') === 'undefined') {

                                addDataLayer(map, "");
                                checkIfRegionShouldShow();

                                if (this.state.pageLoaded === "networkCapacity") {

                                    if (typeof map.getSource('associatedDemandSubstations') !== 'undefined') {
                                        if (this.state.associatedSubstations !== undefined && this.state.associatedSubstations?.length > 0) {
                                            (map.getSource("associatedDemandSubstations") as GeoJSONSource)?.setData({
                                                type: 'FeatureCollection',
                                                features: this.state.associatedSubstations
                                            });
                                            this.createLinesToCorrespondingGSP(this.state.associatedSubstations);
                                        }
                                    }

                                    if (typeof this.state.mapObj.getSource('associatedDemandBSPs') !== 'undefined') {
                                        if (this.state.associatedBSPs !== undefined && this.state.associatedBSPs?.length > 0) {
                                            (this.state.mapObj.getSource("associatedDemandBSPs") as GeoJSONSource)?.setData({
                                                type: 'FeatureCollection',
                                                features: this.state.associatedBSPs
                                            });
                                            this.createLinesToCorrespondingGSP(this.state.associatedBSPs);
                                        }
                                    }

                                    this.plotDemandData(this.state.demandJSON, map);
                                } else if (this.state.pageLoaded === "generationAvailability") {

                                    if (typeof this.state.mapObj.getSource('associatedSubstations') !== 'undefined') {
                                        if (this.state.associatedSubstations !== undefined && this.state.associatedSubstations?.length > 0) {
                                            (this.state.mapObj.getSource("associatedSubstations") as GeoJSONSource)?.setData({
                                                type: 'FeatureCollection',
                                                features: this.state.associatedSubstations
                                            });
                                            this.createLinesToCorrespondingGSP(this.state.associatedSubstations);
                                        }
                                    }

                                    if (typeof this.state.mapObj.getSource('associatedBSPs') !== 'undefined') {
                                        if (this.state.associatedBSPs !== undefined && this.state.associatedBSPs?.length > 0) {
                                            (this.state.mapObj.getSource("associatedBSPs") as GeoJSONSource)?.setData({
                                                type: 'FeatureCollection',
                                                features: this.state.associatedBSPs
                                            });
                                            this.createLinesToCorrespondingGSP(this.state.associatedBSPs);
                                        }
                                    }

                                    this.plotGAData(this.state.gAJSON, map);
                                }
                                // else{
                                //     this.showDFES();
                                // }

                                if (typeof map.getSource('region') !== 'undefined') {
                                    (map.getSource("region") as GeoJSONSource)?.setData(this.state.mapOutlineData);
                                }

                                if (typeof map.getSource('evchargers') !== 'undefined') {
                                    if (this.state?.evChargerFC?.evChargers !== undefined) {
                                        (map?.getSource("evchargers") as GeoJSONSource)?.setData(this.state?.evChargerFC?.evChargers);
                                    }
                                }
                            }
                        }
                        // this.setUserLocation();

                    } catch (error) {
                        console.error("Error **", error);
                    }
                }
            };
            waiting();
        });

        map.on('click', (e: any) => {

            if (this.state.showMapTypeDialog) {
                this.setState({ showMapTypeDialog: false });
            }

            if (this.state.pageLoaded === "networkCapacity") {
                this.clickedDemand(e, map);
            } else {
                this.clickedGA(e, map);
            }

            let layers: string | any[] = [];

            if (map.getLayer('ev-layer') !== undefined) {
                layers = layers.concat('ev-layer');
            }

            var features = map.queryRenderedFeatures(e.point, { layers: layers });

            if (!features.length) {
                return;
            }

            let multipleEvFeatures = [];
            let nearbyFound = false;
            for (let i = 0; i < features.length; i++) {
                const feature = features[i];
                if (feature.layer.id === "ev-layer") {
                    for (let j = 0; j < features.length; j++) {
                        const feature2 = features[j];
                        if (feature2.layer.id === "ev-layer" && feature.properties!.id !== feature2.properties!.id) {
                            if (feature.geometry.type === 'Point' && feature2.geometry.type === 'Point') {
                                var from = turf.point([feature.geometry.coordinates[0], feature.geometry.coordinates[1]]);
                                var to = turf.point([feature2.geometry.coordinates[0], feature2.geometry.coordinates[1]]);
                                var distance = turf.distance(from, to, { units: 'kilometers' });

                                if (distance < 0.025) {
                                    nearbyFound = true;
                                    multipleEvFeatures.push(feature2);
                                }
                            }
                        }
                    }
                }
                if (nearbyFound === true) {
                    multipleEvFeatures.unshift(feature);
                    break;
                }
            }

            if (multipleEvFeatures.length < 2) {
                var feature = features[0];
                if (feature.layer.id === "ev-layer") {
                    this.triggerSelectedEvChargerListItem(feature, false);
                }
            } else {
                this.setState({
                    multipleEvFeaturesClicked: multipleEvFeatures
                });
                if (multipleEvFeatures[0].geometry.type === 'Point') {
                    let zoomLevel = this?.state?.mapObj?.getZoom();
                    if (zoomLevel < 16) {
                        zoomLevel = 16;
                    }
                    map.flyTo({
                        center: [
                            multipleEvFeatures[0].geometry.coordinates[0],
                            multipleEvFeatures[0].geometry.coordinates[1]
                        ],
                        zoom: zoomLevel
                    });

                }
            }
        });

        //add hovers
        this.addPopUpHoversForAllLayers(map);

        this.setUserLocation();

        function checkIfRegionShouldShow() {
            try {
                if (AppPreferences.getAppPreferences().preferences.userPrefs.accessibility.colourBlindMode) {
                    if (typeof map.getLayer('outline') !== 'undefined') {
                        map.setPaintProperty(
                            'outline',
                            'fill-opacity',
                            0
                        );
                    }
                    if (typeof map.getLayer('outline-line') !== 'undefined') {
                        map.setPaintProperty(
                            'outline-line',
                            'line-width',
                            3
                        );
                    }
                } else {
                    var zoom: number = +map.getZoom().toFixed(2);
                    if (zoom > 11) {
                        if (typeof map.getLayer('outline') !== 'undefined') {
                            map.setPaintProperty(
                                'outline',
                                'fill-opacity',
                                0
                            );
                        }
                        if (typeof map.getLayer('outline-line') !== 'undefined') {
                            map.setPaintProperty(
                                'outline-line',
                                'line-width',
                                1
                            );
                        }
                    } else {
                        if (typeof map.getLayer('outline') !== 'undefined') {
                            map.setPaintProperty(
                                'outline',
                                'fill-opacity',
                                0.35
                            );
                        }
                        if (typeof map.getLayer('outline-line') !== 'undefined') {
                            map.setPaintProperty(
                                'outline-line',
                                'line-width',
                                1
                            );
                        }
                    }
                }
            } catch (error) {
                console.error("Catch setting region");
            }
        }

        const debouncedCheckIfRegionShouldShow = throttle(() => checkIfRegionShouldShow(), 250);

        const checkIfEvButtonShouldShow = () => {
            try {
                let zoomLevel = 11;
                var zoom: number = +map.getZoom().toFixed(2);
                if (this.state.disableEvButtons && zoom >= zoomLevel) {
                    this.setState({ disableEvButtons: false });
                } else if (!this.state.disableEvButtons && zoom < zoomLevel) {
                    this.setState({ disableEvButtons: true });
                }
            } catch (error) { }
        }

        const debouncedCheckIfEvButtonShouldShow = throttle(() => checkIfEvButtonShouldShow(), 250);

          // Retrieve locally stored app preferences and run post fetch methods
          this.localPreferencesTasks();
    }

     // Methods to run after locally stored app preferences are retrieved
     async localPreferencesTasks() {
        // Wait for preference fetch from local storage to complete then run post fetch tasks
        let isLocalAvailable = await AppPreferences.getAppPreferences().restoreLocallyStoredPreferences();
        if (isLocalAvailable) {
            // Check which map type should show (Colourblind or standard)
            if (AppPreferences.getAppPreferences().preferences.userPrefs.accessibility.colourBlindMode) {
                this.switchMapStyle(MapStyle.COLOURBLIND);
            }
        }
    }

    getFilteredGAData(generationData: any) {
        let dataToDisplay: any[] = [];
        if (this.state.constrainedGSPSOn) {
            var constrained = generationData?.constrained.features;
            if (this.state.northGAOn) {
                let cs: any = [];
                cs = constrained?.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
                dataToDisplay = dataToDisplay.concat(cs);
            }
            if (this.state.southGAOn) {
                let cs: any = [];
                cs = constrained?.filter((p: { properties: any; }) => p.properties.Area === "England");
                dataToDisplay = dataToDisplay.concat(cs);
            }
        }
        if (this.state.unconstrainedGSPSOn) {
            var unconstrained = generationData?.unconstrained.features;
            if (this.state.northGAOn) {
                let cs: any = [];
                cs = unconstrained?.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
                dataToDisplay = dataToDisplay.concat(cs);
            }
            if (this.state.southGAOn) {
                let cs: any = [];
                cs = unconstrained?.filter((p: { properties: any; }) => p.properties.Area === "England");
                dataToDisplay = dataToDisplay.concat(cs);
            }
        }
        if (this.state.partiallyConstrainedGSPSOn) {
            var partiallyConstrained = generationData?.partiallyConstrained.features;
            if (this.state.northGAOn) {
                let cs: any = [];
                cs = partiallyConstrained?.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
                dataToDisplay = dataToDisplay.concat(cs);
            }
            if (this.state.southGAOn) {
                let cs: any = [];
                cs = partiallyConstrained?.filter((p: { properties: any; }) => p.properties.Area === "England");
                dataToDisplay = dataToDisplay.concat(cs);
            }
        }
        if (this.state.constrainedBSPSOn) {
            var bulk_constrained = generationData?.bulk_constrained.features;
            if (this.state.northGAOn) {
                let cs: any = [];
                cs = bulk_constrained?.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
                dataToDisplay = dataToDisplay.concat(cs);
            }
            if (this.state.southGAOn) {
                let cs: any = [];
                cs = bulk_constrained?.filter((p: { properties: any; }) => p.properties.Area === "England");
                dataToDisplay = dataToDisplay.concat(cs);
            }
        }
        if (this.state.unconstrainedBSPSOn) {
            var bulk_unconstrained = generationData?.bulk_unconstrained.features;
            if (this.state.northGAOn) {
                let cs: any = [];
                cs = bulk_unconstrained?.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
                dataToDisplay = dataToDisplay.concat(cs);
            }
            if (this.state.southGAOn) {
                let cs: any = [];
                cs = bulk_unconstrained?.filter((p: { properties: any; }) => p.properties.Area === "England");
                dataToDisplay = dataToDisplay.concat(cs);
            }
        }
        if (this.state.partiallyConstrainedBSPSOn) {
            var bulk_partiallyConstrained = generationData?.bulk_partiallyConstrained.features;
            if (this.state.northGAOn) {
                let cs: any = [];
                cs = bulk_partiallyConstrained?.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
                dataToDisplay = dataToDisplay.concat(cs);
            }
            if (this.state.southGAOn) {
                let cs: any = [];
                cs = bulk_partiallyConstrained?.filter((p: { properties: any; }) => p.properties.Area === "England");
                dataToDisplay = dataToDisplay.concat(cs);
            }
        }
        if (this.state.constrainedPSSSOn) {
            var substation_constrained = generationData?.substation_constrained.features;
            if (this.state.northGAOn) {
                let cs: any = [];
                cs = substation_constrained?.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
                dataToDisplay = dataToDisplay.concat(cs);
            }
            if (this.state.southGAOn) {
                let cs: any = [];
                cs = substation_constrained?.filter((p: { properties: any; }) => p.properties.Area === "England");
                dataToDisplay = dataToDisplay.concat(cs);
            }
        }
        if (this.state.unconstrainedPSSSOn) {
            var substation_unconstrained = generationData?.substation_unconstrained.features;
            if (this.state.northGAOn) {
                let cs: any = [];
                cs = substation_unconstrained?.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
                dataToDisplay = dataToDisplay.concat(cs);
            }
            if (this.state.southGAOn) {
                let cs: any = [];
                cs = substation_unconstrained?.filter((p: { properties: any; }) => p.properties.Area === "England");
                dataToDisplay = dataToDisplay.concat(cs);
            }
        }
        if (this.state.partiallyConstrainedPSSSOn) {
            var substation_partiallyConstrained = generationData?.substation_partiallyConstrained.features;
            if (this.state.northGAOn) {
                let cs: any = [];
                cs = substation_partiallyConstrained?.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
                dataToDisplay = dataToDisplay.concat(cs);
            }
            if (this.state.southGAOn) {
                let cs: any = [];
                cs = substation_partiallyConstrained?.filter((p: { properties: any; }) => p.properties.Area === "England");
                dataToDisplay = dataToDisplay.concat(cs);
            }
        }

        return dataToDisplay;
    }

    getFitleredDemandData(demandData: any) {
        let dataToDisplay: any[] = [];
        if (this.state.demandGSPsOn) {
            var gsp = demandData?.gsp.features;
            if (this.state.northNCOn) {
                let cs: any = [];
                cs = gsp?.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
                dataToDisplay = dataToDisplay.concat(cs);
            }
            if (this.state.southNCOn) {
                let cs: any = [];
                cs = gsp?.filter((p: { properties: any; }) => p.properties.Area === "England");
                dataToDisplay = dataToDisplay.concat(cs);
            }
        }

        if (this.state.demandBSPsOn) {
            var bsp = demandData?.bsp.features;
            if (this.state.northNCOn) {
                let cs: any = [];
                cs = bsp?.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
                dataToDisplay = dataToDisplay.concat(cs);
            }
            if (this.state.southNCOn) {
                let cs: any = [];
                cs = bsp?.filter((p: { properties: any; }) => p.properties.Area === "England");
                dataToDisplay = dataToDisplay.concat(cs);
            }
        }
        if (this.state.demandPSSsOn) {
            var pss = demandData?.pss.features;
            if (this.state.northNCOn) {
                let cs: any = [];
                cs = pss?.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
                dataToDisplay = dataToDisplay.concat(cs);
            }
            if (this.state.southNCOn) {
                let cs: any = [];
                cs = pss?.filter((p: { properties: any; }) => p.properties.Area === "England");
                dataToDisplay = dataToDisplay.concat(cs);
            }
        }
        return dataToDisplay;
    }

    plotDemandData(demandData: any, map: any) {

        let dataToDisplay: any[] = this.getFitleredDemandData(demandData);

        if (typeof map.getSource('demand') !== 'undefined') {
            (map.getSource("demand") as GeoJSONSource)?.setData({
                type: 'FeatureCollection',
                features: dataToDisplay
            });
        }

        if (typeof map.getSource('generation') !== 'undefined') {
            (map.getSource("generation") as GeoJSONSource)?.setData({
                type: 'FeatureCollection',
                features: []
            });
        }
    }

    plotGAData(generationData: any, map: any) {
        let dataToDisplay: any[] = this.getFilteredGAData(generationData);

        if (typeof map.getSource('generation') !== 'undefined') {
            (map.getSource("generation") as GeoJSONSource)?.setData({
                type: 'FeatureCollection',
                features: dataToDisplay
            });
        }

        if (typeof map.getSource('demand') !== 'undefined') {
            (map.getSource("demand") as GeoJSONSource)?.setData({
                type: 'FeatureCollection',
                features: []
            });
        }
    }

    async fetchGAData(map: any, firstFetch: boolean, autoRefreshed: boolean) {
        let response: HttpResponse<dataResponse[]>;
        try {
            response = await http(generationURL, 20000);
            if (response.status !== 304) {
                let data = response.parsedBody;
                const generationData = await fetchGenerationData(data);
                this.plotGAData(generationData, map);

                this.setState({ gAJSON: generationData as GenerationCollection });

                if (!firstFetch && autoRefreshed) {
                    this.setState({ faultDataAutoRefreshed: true });
                }
            }

            if (!firstFetch && !autoRefreshed) {
                this.setState({ faultDataRefreshed: true });
            }

            this.setState({ fetchStateGeneration: FetchState.SUCCESS });

        } catch (response) {
            console.error("Error **", response);
            this.setState({ fetchStateGeneration: FetchState.FAILED });
        }
    }

    async fetchDemandData() {
        let response: HttpResponse<dataResponse[]>;
        try {
            response = await http(demandURL, 20000);

            if (response.status !== 304) {
                let data = response.parsedBody;
                const demandData = await fetchDemandData(data);
                this.setState({ demandJSON: demandData as DemandCollection });
            }

            this.setState({ fetchStateDemand: FetchState.SUCCESS });

        } catch (response) {
            console.error("Error **", response);
            this.setState({ fetchStateDemand: FetchState.FAILED });
        }
    }

    async fetchOutline(map: any) {
        let outlineResponse: HttpResponse<dataResponse[]>;
        try {
            outlineResponse = await http(infoURL + "?areas&rfc7946", 12000);
            this.state.outlineData = outlineResponse.parsedBody;
            const outlineData = await fetchOutlineData(this.state.outlineData);
            this.state.mapOutlineData = outlineData;
            (map.getSource("region") as GeoJSONSource)?.setData(outlineData);
            // this.setState({ fetchState: FetchState.SUCCESS });
        } catch (outlineResponse) {
            console.error("Error **", outlineResponse);
            // this.setState({ fetchState: FetchState.FAILED });
        }
    }

    fetchData = async (map: any, autoRefreshed: boolean) => {
        let firstFetch = false;
        if (this.state.fetchState === FetchState.IDLE) {
            firstFetch = true;
        }

        if (!autoRefreshed) {
            this.setState({ fetchState: FetchState.FETCHING });
        }

        this.fetchOutline(map);
        this.fetchGAData(map, firstFetch, autoRefreshed);
        this.fetchDemandData();
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.sideBar !== this.state.sideBar) {
            this.state.mapObj.resize();
        }
    }

    toggleMapKey = () => {
        this.setState({ mapKeyExpanded: !this.state.mapKeyExpanded });
    }

    toggleMapTypeDialog = () => {
        this.setState({ showMapTypeDialog: !this.state.showMapTypeDialog });
    }

    toggleLicences = (state: boolean) => {
        this.setState({ showLicences: state });
    }

    toggleContactUs = (state: boolean) => {
        this.setState({ showContactUs: state });
    }

    showSidebar = () => {
        this.setState({ sideBar: !this.state.sideBar });
    }

    showFilter = () => {
        this.setState({ filterLoaded: true, sideBar: true, acronymsLoaded: false, downloadsLoaded: false, substationDetailsLoaded: false, evDetailsLoaded: false });
    }

    plotData = () => {
        if (this.state.pageLoaded === "networkCapacity") {
            this.plotDemandData(this.state.demandJSON, this.state.mapObj);
        } else {
            this.plotGAData(this.state.gAJSON, this.state.mapObj);
        }
    }

    filterGANorth = async () => {
        this.setState({ northGAOn: !this.state.northGAOn });
        let ass = this.state.associatedSubstations;
        let regionScot = false;
        if (ass?.length > 0) {
            let firstAss = ass[0];
            if (firstAss?.properties?.Area === "Scotland") {
                regionScot = true;
            }
        }
        if (regionScot) {
            this.clearAssociatedAssets();
        }
        this.plotGAData(this.state.gAJSON, this.state.mapObj);
    }

    filterGASouth = () => {
        this.setState({ southGAOn: !this.state.southGAOn });
        let ass = this.state.associatedSubstations;
        let regionEngSub = false;
        if (ass?.length > 0) {
            let firstAss = ass[0];
            if (firstAss?.properties?.Area === "England") {
                regionEngSub = true;
            }
        }
        let assBSP = this.state.associatedBSPs;
        let regionEngBSP = false;
        if (assBSP?.length > 0) {
            let firstAssBSP = assBSP[0];
            if (firstAssBSP?.properties?.Area === "England") {
                regionEngBSP = true;
            }
        }
        if (regionEngSub || regionEngBSP) {
            this.clearAssociatedAssets();
        }
        this.plotGAData(this.state.gAJSON, this.state.mapObj);
    }

    filterNCNorth = () => {
        this.setState({ northNCOn: !this.state.northNCOn });
        let ass = this.state.associatedSubstations;
        let regionScot = false;
        if (ass?.length > 0) {
            let firstAss = ass[0];
            if (firstAss?.properties?.Area === "Scotland") {
                regionScot = true;
            }
        }
        if (regionScot) {
            this.clearAssociatedAssets();
        }
        this.plotDemandData(this.state.demandJSON, this.state.mapObj);
    }

    filterNCSouth = () => {
        this.setState({ southNCOn: !this.state.southNCOn });
        let ass = this.state.associatedSubstations;
        let regionEngSub = false;
        if (ass?.length > 0) {
            let firstAss = ass[0];
            if (firstAss?.properties?.Area === "England") {
                regionEngSub = true;
            }
        }
        let assBSP = this.state.associatedBSPs;
        let regionEngBSP = false;
        if (assBSP?.length > 0) {
            let firstAssBSP = assBSP[0];
            if (firstAssBSP?.properties?.Area === "England") {
                regionEngBSP = true;
            }
        }
        if (regionEngSub || regionEngBSP) {
            this.clearAssociatedAssets();
        }
        this.plotDemandData(this.state.demandJSON, this.state.mapObj);
    }

    setGAToggles = (stateOfToggles: any) => {
        this.setState({ gAToggles: stateOfToggles });
    }

    setNCToggles = (stateOfToggles: any) => {
        this.setState({ nCToggles: stateOfToggles });
    }

    sortByTime = () => {
        this.setState({ sortAtoZ: !this.state.sortAtoZ });
    }

    sortByTimeDemand = () => {
        this.setState({ sortAtoZDemand: !this.state.sortAtoZDemand });
    }

    showGenerationAvailability = () => {
        this.setState({ pageLoaded: "generationAvailability" });
        this.clearAssociatedAssets();
        this.plotGAData(this.state.gAJSON, this.state.mapObj);
    }

    showNetworkCapacity = () => {
        this.setState({ pageLoaded: "networkCapacity" });
        this.clearAssociatedAssets();
        this.plotDemandData(this.state.demandJSON, this.state.mapObj);
    }

    showSummary = () => {
        this.setState({ filterLoaded: false, sideBar: true, acronymsLoaded: false, downloadsLoaded: false, substationDetailsLoaded: false, evDetailsLoaded: false, gADetailsLoaded: false, demandDetailsLoaded: false });
    }

    showDownloads(): void {
        this.setState({ downloadsLoaded: true, filterLoaded: false, sideBar: true, acronymsLoaded: false, substationDetailsLoaded: false, evDetailsLoaded: false });
    }

    setDemandGSPS = () => {
        this.setState({ demandGSPsOn: !this.state.demandGSPsOn });
        this.plotDemandData(this.state.demandJSON, this.state.mapObj);
        this.setState({ redraw: !this.state.redraw });
    }

    setDemandBSPS = () => {
        this.setState({ demandBSPsOn: !this.state.demandBSPsOn });
        this.plotDemandData(this.state.demandJSON, this.state.mapObj);
        this.setState({ redraw: !this.state.redraw });
    }

    setDemandPSSS = () => {
        this.setState({ demandPSSsOn: !this.state.demandPSSsOn });
        this.plotDemandData(this.state.demandJSON, this.state.mapObj);
        this.setState({ redraw: !this.state.redraw });
    }

    setConstrainedGSPS = () => {
        this.setState({ constrainedGSPSOn: !this.state.constrainedGSPSOn });
        if (this.state.mapObj !== undefined && this.state.fetchStateGeneration === FetchState.SUCCESS) {
            this.plotGAData(this.state.gAJSON, this.state.mapObj);
            this.setState({ redraw: !this.state.redraw });
        }
    }

    setUnConstrainedGSPS = () => {
        this.setState({ unconstrainedGSPSOn: !this.state.unconstrainedGSPSOn });
        if (this.state.mapObj !== undefined && this.state.fetchStateGeneration === FetchState.SUCCESS) {
            this.plotGAData(this.state.gAJSON, this.state.mapObj);
            this.setState({ redraw: !this.state.redraw });
        }
    }

    setPartiallyConstrainedGSPS = () => {
        this.setState({ partiallyConstrainedGSPSOn: !this.state.partiallyConstrainedGSPSOn });
        if (this.state.mapObj !== undefined && this.state.fetchStateGeneration === FetchState.SUCCESS) {
            this.plotGAData(this.state.gAJSON, this.state.mapObj);
            this.setState({ redraw: !this.state.redraw });
        }
    }

    setBulkConstrained = () => {
        this.setState({ constrainedBSPSOn: !this.state.constrainedBSPSOn });
        if (this.state.mapObj !== undefined && this.state.fetchStateGeneration === FetchState.SUCCESS) {
            this.plotGAData(this.state.gAJSON, this.state.mapObj);
            this.setState({ redraw: !this.state.redraw });
        }
    }

    setBulkUnConstrained = () => {
        this.setState({ unconstrainedBSPSOn: !this.state.unconstrainedBSPSOn });
        if (this.state.mapObj !== undefined && this.state.fetchStateGeneration === FetchState.SUCCESS) {
            this.plotGAData(this.state.gAJSON, this.state.mapObj);
            this.setState({ redraw: !this.state.redraw });
        }
    }

    setBulkPartiallyConstrained = () => {
        this.setState({ partiallyConstrainedBSPSOn: !this.state.partiallyConstrainedBSPSOn });
        if (this.state.mapObj !== undefined && this.state.fetchStateGeneration === FetchState.SUCCESS) {
            this.plotGAData(this.state.gAJSON, this.state.mapObj);
            this.setState({ redraw: !this.state.redraw });
        }
    }

    setSubstationConstrained = () => {
        this.setState({ constrainedPSSSOn: !this.state.constrainedPSSSOn });
        if (this.state.mapObj !== undefined && this.state.fetchStateGeneration === FetchState.SUCCESS) {
            this.plotGAData(this.state.gAJSON, this.state.mapObj);
            this.setState({ redraw: !this.state.redraw });
        }
    }

    setSubstationUnconstrained = () => {
        this.setState({ unconstrainedPSSSOn: !this.state.unconstrainedPSSSOn });
        if (this.state.mapObj !== undefined && this.state.fetchStateGeneration === FetchState.SUCCESS) {
            this.plotGAData(this.state.gAJSON, this.state.mapObj);
            this.setState({ redraw: !this.state.redraw });
        }
    }

    setSubstationPartiallyConstrained = () => {
        this.setState({ partiallyConstrainedPSSSOn: !this.state.partiallyConstrainedPSSSOn });
        if (this.state.mapObj !== undefined && this.state.fetchStateGeneration === FetchState.SUCCESS) {
            this.plotGAData(this.state.gAJSON, this.state.mapObj);
            this.setState({ redraw: !this.state.redraw });
        }
    }

    backFromFilter = () => {
        this.setState({ filterLoaded: false, sideBar: true, acronymsLoaded: false, downloadsLoaded: false, substationDetailsLoaded: false });
    }

    showAcronyms(): void {
        this.setState({ filterLoaded: false, sideBar: true, acronymsLoaded: true, downloadsLoaded: false, substationDetailsLoaded: false });
    }

    // Switches between Satellite and Street map styles (or colourblind firendly style if enabled in settings)
    switchMapStyle = (mapStyle: MapStyle) => {
        // Ensure map is initialised and timeout is not still in effect
        if (this.state.mapObj !== undefined) {

            if (mapStyle === MapStyle.SATELLITE && this.state.mapStyle !== MapStyle.SATELLITE) {
                this.state.mapObj.setStyle(AppPreferences.getAppPreferences().preferences.devPrefs.map.styles!.satellite);
                this.setState({ mapStyle: MapStyle.SATELLITE });
                AppPreferences.getAppPreferences().setColourBlindMode(false);
            } else if (mapStyle === MapStyle.STREET && this.state.mapStyle !== MapStyle.STREET) {
                this.state.mapObj.setStyle(AppPreferences.getAppPreferences().preferences.devPrefs.map.styles!.street);
                this.setState({ mapStyle: MapStyle.STREET });
                AppPreferences.getAppPreferences().setColourBlindMode(false);
            } else if (mapStyle === MapStyle.COLOURBLIND && this.state.mapStyle !== MapStyle.COLOURBLIND) {
                this.state.mapObj.setStyle(AppPreferences.getAppPreferences().preferences.devPrefs.map.styles!.colourBlindCompat);
                this.setState({ mapStyle: MapStyle.COLOURBLIND });
                AppPreferences.getAppPreferences().setColourBlindMode(true);
            }
        }
    }

    zoomOutToUK = async () => {
        if (this.state.mapObj !== undefined) {
            var northEast = new mapboxgl.LngLat(2.12374, 60.06005);
            var southWest = new mapboxgl.LngLat(-10.41237, 49.64729);
            var boundingBox = new mapboxgl.LngLatBounds(southWest, northEast);
            this.state.mapObj.fitBounds(boundingBox, {
                padding: 20
            });
        }
    }

    threeDimensional = () => {
        if (this.state.mapObj !== undefined) {
            if (!!!this.state.tilted) {
                this.state.mapObj.flyTo({ pitch: 110 })
            } else {
                this.state.mapObj.flyTo({ pitch: 0 })
            }
        }
        this.setState({ tilted: !this.state.tilted });
    }

    clearMapReturnBounds = () => {
        this.mapReturnBounds = undefined;
    }

    zoomToMapReturnBounds = () => {
        if (this.mapReturnBounds !== undefined) {
            if (window.innerWidth <= 640) {
                setTimeout(() => {
                    this.state.mapObj.fitBounds(this.mapReturnBounds, {
                        padding: 0,
                        duration: 0
                    });
                }, 200);
            } else {
                this.state.mapObj.fitBounds(this.mapReturnBounds, {
                    padding: 0
                });
            }
        } else {
            this.zoomOutToUK();
        }
    }

    showEvs = () => {
        this.setState({
            ...this.state,
            // gADetailsLoaded: false,
            filterLoaded: false,
            acronymsLoaded: false,
            downloadsLoaded: false,
            // substationDetailsLoaded: false,
            // sideBar: true,
            // featureClicked: null,
            // associatedSubstations: [],
            // substationClicked: null,
            // demandDetailsLoaded: false,
            // associatedBSPs: [],
            // parentGSP: null,
            evDetailsLoaded: true
        });

        if (this.state.evDetailsLoaded) {
            this.getEvChargersInMapArea();
        }
    }

    hideEvs = () => {
        this.setState({
            ...this.state,
            // gADetailsLoaded: false,
            // filterLoaded: false,
            // acronymsLoaded: false,
            // downloadsLoaded: false,
            // substationDetailsLoaded: false,
            // sideBar: true,
            // featureClicked: null,
            // associatedSubstations: [],
            // substationClicked: null,
            // demandDetailsLoaded: false,
            // associatedBSPs: [],
            // parentGSP: null,
            evDetailsLoaded: false
        });
    }

    // Pan and Zoom the map to the specified fault coordinate pair or fit map bounds to the fault boundary area.
    zoomToLocationOnMap = async (location: Coordinate[], feature: any) => {
        if (this.state?.mapObj !== undefined) {
            let boundsData = feature?.geometry;
            if (boundsData?.type !== "Point") {
                let latLngBounds = {
                    'type': 'FeatureCollection',
                    'features': [
                        {
                            'type': 'Feature',
                            'geometry': {
                                'type': 'Polygon',
                                'properties': {},
                                'coordinates': boundsData?.coordinates
                            }
                        }
                    ]
                };

                let bounds = turf.bbox(latLngBounds);

                let fitBoundsOptions: any = { padding: 20 };
                if (window.innerWidth <= 640) {
                    fitBoundsOptions.duration = 0;
                }

                this.state.mapObj?.fitBounds(bounds, fitBoundsOptions);
            } else {
                if (window.innerWidth <= 640) {
                    this.state.mapObj.jumpTo({
                        center: [location[0], location[1]],
                        zoom: 16
                    });
                } else {
                    this.state.mapObj.flyTo({
                        center: [location[0], location[1]],
                        zoom: 16
                    });
                }
            }
        }
    }

    showList() {
        this.clearAssociatedAssets();
        if (this.state.pageLoaded === "networkCapacity") {
            this.zoomToMapReturnBounds();
            this.setState({ demandDetailsLoaded: false, gADetailsLoaded: false, filterLoaded: false, sideBar: true, acronymsLoaded: false, downloadsLoaded: false, substationDetailsLoaded: false, substationDetailsFromMapClick: false });
        } else {
            this.zoomToMapReturnBounds();
            this.setState({ demandDetailsLoaded: false, gADetailsLoaded: false, filterLoaded: false, sideBar: true, acronymsLoaded: false, downloadsLoaded: false, substationDetailsLoaded: false, substationDetailsFromMapClick: false });
        }
    }

    showDetails() {
        if (this.state.pageLoaded === "networkCapacity") {
            if (this.state.substationDetailsFromMapClick) {
                this.zoomToMapReturnBounds();
                this.setState({ demandDetailsLoaded: false, gADetailsLoaded: false, filterLoaded: false, sideBar: true, acronymsLoaded: false, downloadsLoaded: false, substationDetailsLoaded: false, substationDetailsFromMapClick: false });
            } else {
                setTimeout(
                    () => { this.zoomToLocationOnMap(this.state.featureClicked?.geometry?.coordinates, this.state.featureClicked) },
                    100)
                this.setState({ demandDetailsLoaded: !this.state.demandDetailsLoaded, gADetailsLoaded: false, filterLoaded: false, sideBar: true, acronymsLoaded: false, downloadsLoaded: false, substationDetailsLoaded: false, substationDetailsFromMapClick: false });
            }
        } else {
            if (this.state.substationDetailsFromMapClick) {
                this.zoomToMapReturnBounds();
                this.setState({ demandDetailsLoaded: false, gADetailsLoaded: false, filterLoaded: false, sideBar: true, acronymsLoaded: false, downloadsLoaded: false, substationDetailsLoaded: false, substationDetailsFromMapClick: false });
            } else {
                setTimeout(
                    () => { this.zoomToLocationOnMap(this.state.featureClicked?.geometry?.coordinates, this.state.featureClicked) },
                    100)
                this.setState({ demandDetailsLoaded: false, gADetailsLoaded: !this.state.gADetailsLoaded, filterLoaded: false, sideBar: true, acronymsLoaded: false, downloadsLoaded: false, substationDetailsLoaded: false, substationDetailsFromMapClick: false });
            }
        }
    }

    async getEvChargersInMapArea() {

        // EV API BASE URL
        let url: string;
        url = "https://ev-uk.api.opcld.com/";

        // GET BOUNDS OF MAP AND CONVERT TO GEOJSON
        let mapLngLatBounds = this.state.mapObj.getBounds();
        let NE = [mapLngLatBounds.getNorthEast().lng, mapLngLatBounds.getNorthEast().lat];
        let SE = [mapLngLatBounds.getSouthEast().lng, mapLngLatBounds.getSouthEast().lat];
        let SW = [mapLngLatBounds.getSouthWest().lng, mapLngLatBounds.getSouthWest().lat];
        let NW = [mapLngLatBounds.getNorthWest().lng, mapLngLatBounds.getNorthWest().lat];
        const mapAreaToSearch = {
            "type": "Polygon",
            "coordinates": [[NE, SE, SW, NW, NE]]
        }

        // APPLY URI ENCODING TO MAP BOUNDS
        const encodedMapAreaToSearch = encodeURIComponent(JSON.stringify(mapAreaToSearch));

        // GENERATE EV FILTERING QUERY STRING
        const filterParameters = this.evFilterState.generateFilterQueryString();

        let request = "";
        request = url + "search?limit=250&" + "geojson=" + encodedMapAreaToSearch + "&" + filterParameters;
        let response: HttpResponse<evChargerResponse[]>;
        try {
            response = await http(request, 12000);

            if (this.state.evDetailsLoaded) {
                this.state.evChargerData = response.parsedBody;

                const evChargerFC = await fetchEvData(this.state.evChargerData);
                (this.state.mapObj?.getSource("evchargers") as GeoJSONSource)?.setData(evChargerFC.evChargers);
                this.setState({ evChargerFC: evChargerFC });

                // this.zoomToEvChargerBounds();

                return evChargerFC.evChargers.features;
            } else {
                const errorMessage = 'EV List closed before Data Fetch could complete.';
                throw errorMessage;
            }

        } catch (response) {
            console.error("Error retrieving EV charger data -", response);
        }
    }

    triggerSelectedEvChargerListItem = (feature: any, allowDeselect: boolean) => {

        let currentId = feature.properties.id;
        let previousId = null;
        if (this.state.evFeatureClicked !== null) {
            previousId = this.state.evFeatureClicked.properties.id;
        }

        if (previousId == null || currentId !== previousId) {

            this.setState({ sideBar: true });

            this.setState({
                ...this.state,
                evFeatureClicked: feature,
            })

            let lat = feature.properties.location.latitude
            let lon = feature.properties.location.longitude;
            if (typeof lat !== 'number') {
                lat = JSON.parse(feature.properties.location).latitude
            }
            if (typeof lon !== 'number') {
                lon = JSON.parse(feature.properties.location).longitude
            }
            let zoomLevel = this?.state?.mapObj?.getZoom();
            if (zoomLevel < 16) {
                zoomLevel = 16;
            }

            if (window.innerWidth <= 640) {
                setTimeout(() => {
                    this.state.mapObj.jumpTo({
                        center: [lon, lat],
                        zoom: zoomLevel
                    });
                }, 1000);
            } else {
                this.state.mapObj.flyTo({
                    center: [lon, lat],
                    zoom: zoomLevel
                });
            }

        } else {
            if (allowDeselect) {
                this.setState({
                    ...this.state,
                    evFeatureClicked: null,
                })
                this.zoomToEvChargerBounds();
            } else {
                this.setState({ sideBar: true });
            }
        }
    }

    async zoomToEvChargerBounds() {
        var evChargerFC = this.state.evChargerFC.evChargers;

        var bounds = new mapboxgl.LngLatBounds();
        evChargerFC.features.forEach(function (feature: any) {
            bounds.extend(feature.geometry.coordinates);
        });

        let fitBoundsOptions: any = { padding: 100 };
        if (window.innerWidth <= 640) {
            fitBoundsOptions.duration = 0;
        }

        if (evChargerFC.features.length > 1) {
            if (window.innerWidth <= 640) {
                setTimeout(() => {
                    this.state.mapObj.fitBounds(bounds, fitBoundsOptions);
                }, 1000);
            } else {
                this.state.mapObj.fitBounds(bounds, fitBoundsOptions);
            }
        } else if (evChargerFC.features.length === 1) {
            let feature = evChargerFC.features[0];
            if (window.innerWidth <= 640) {
                setTimeout(() => {
                    this.state.mapObj.jumpTo({
                        center: feature.geometry.coordinates,
                        zoom: 15
                    });
                }, 1000);
            } else {
                this.state.mapObj.flyTo({
                    center: feature.geometry.coordinates,
                    zoom: 16
                });
            }
        }
    }

    async clearSelectedEvFeature() {
        this.setState({
            ...this.state,
            evFeatureClicked: null,
        })
    }

    async clearEvChargers() {
        this.state.evChargerData = [];

        const evChargerFC = await fetchEvData(this.state.evChargerData);
        (this.state.mapObj?.getSource("evchargers") as GeoJSONSource)?.setData(evChargerFC.evChargers);
        this.setState({ evChargerFC: evChargerFC });
    }

    // Renders map style switcher
    MapStyleSwitcher(props: any) {

        let currentMapTypeImg;
        if (props.mapStyle === MapStyle.STREET) {
            currentMapTypeImg = <img src={"/assets/img/map.png"} alt="Map View" onClick={props.toggleMapTypeDialog} />;
        } else if (props.mapStyle === MapStyle.SATELLITE) {
            currentMapTypeImg = <img src={"/assets/img/sat.png"} alt="Sat View" onClick={props.toggleMapTypeDialog} />;
        } else if (props.mapStyle === MapStyle.COLOURBLIND) {
            currentMapTypeImg = <img src={"/assets/img/colourblind.png"} alt="Colour Blind View" onClick={props.toggleMapTypeDialog} />;
        }

        return (
            <>
                <div id="mapTypeContainer">
                    {currentMapTypeImg}
                </div>
                <div id="mapTypeDialog" className={!props.showMapTypeDialog ? "hide" : ""}>
                    <div className={props.mapStyle === MapStyle.STREET ? "active" : ""} onClick={() => props.switchMapStyle(MapStyle.STREET)}>
                        <img src={"/assets/img/map.png"} alt="Map View" />
                        <IonLabel>Standard</IonLabel>
                    </div>
                    <div className={props.mapStyle === MapStyle.SATELLITE ? "active" : ""} onClick={() => props.switchMapStyle(MapStyle.SATELLITE)}>
                        <img src={"/assets/img/sat.png"} alt="Sat View" />
                        <IonLabel>Satellite</IonLabel>
                    </div>
                    <div className={props.mapStyle === MapStyle.COLOURBLIND ? "active" : ""} onClick={() => props.switchMapStyle(MapStyle.COLOURBLIND)}>
                        <img src={"/assets/img/colourblind.png"} alt="Colour Blind View" />
                        <IonLabel>Colour Blind</IonLabel>
                    </div>
                </div>
            </>
        );
    }

    render() {

        let touchStart: any = undefined;
        let touchEnd: any = undefined;

        function handleTouchStart(e: any) {
            touchStart = e.targetTouches[0].clientY;
        }

        const handleTouchMove = (e: any) => {
            touchEnd = e.targetTouches[0].clientY;

            if (touchStart - touchEnd > 50) {
                // do your stuff here for left swipe
                this.setState({ sideBar: true });
            }

            if (touchStart - touchEnd < -50) {
                // do your stuff here for right swipe
                this.setState({ sideBar: false });
            }
        }

        const showFilterFromMapKey = (e: any) => {
            e.stopPropagation();
            this.showFilter();
        }

        const handleTouchEnd = () => {
            // if (touchStart - touchEnd > 50) {
            //     // do your stuff here for left swipe
            //     this.setState({ sideBar: true });
            // }

            // if (touchStart - touchEnd < -50) {
            //     // do your stuff here for right swipe
            //     this.setState({ sideBar: false });
            // }
        }

        let divCode;
        if (this.state.filterLoaded) {
            divCode = <FilterPage pageLoaded={this.state.pageLoaded} constrainedGSPS={this.setConstrainedGSPS} constrainedGSPSOn={this.state.constrainedGSPSOn} unconstrainedGSPS={this.setUnConstrainedGSPS} unconstrainedGSPSOn={this.state.unconstrainedGSPSOn} partiallyConstrainedGSPS={this.setPartiallyConstrainedGSPS} partiallyConstrainedGSPSOn={this.state.partiallyConstrainedGSPSOn}
                bulkConstrained={this.setBulkConstrained} bulkConstrainedOn={this.state.constrainedBSPSOn} bulkUnconstrained={this.setBulkUnConstrained} bulkUnconstrainedOn={this.state.unconstrainedBSPSOn} bulkPartiallyConstrained={this.setBulkPartiallyConstrained} bulkPartiallyConstrainedOn={this.state.partiallyConstrainedBSPSOn}
                substationConstrained={this.setSubstationConstrained} substationConstrainedOn={this.state.constrainedPSSSOn} substationUnconstrained={this.setSubstationUnconstrained} substationUnconstrainedOn={this.state.unconstrainedPSSSOn} substationPartiallyConstrained={this.setSubstationPartiallyConstrained} substationPartiallyConstrainedOn={this.state.partiallyConstrainedPSSSOn}
                showFilter={this.backFromFilter} showSidebar={this.showSidebar} handleTouchStart={handleTouchStart} handleTouchMove={handleTouchMove} handleTouchEnd={handleTouchEnd}
                demandGSPs={this.setDemandGSPS} demandGSPsOn={this.state.demandGSPsOn} demandBSPs={this.setDemandBSPS} demandBSPsOn={this.state.demandBSPsOn} demandPSSs={this.setDemandPSSS} demandPSSsOn={this.state.demandPSSsOn} />
        } else if (this.state.acronymsLoaded) {
            divCode = <Acronyms pageLoaded={this.state.pageLoaded} showFilter={this.backFromFilter} showSidebar={this.showSidebar} handleTouchStart={handleTouchStart} handleTouchMove={handleTouchMove} handleTouchEnd={handleTouchEnd} />
        } else if (this.state.downloadsLoaded) {
            divCode = <Downloads pageLoaded={this.state.pageLoaded} showFilter={this.backFromFilter} showSidebar={this.showSidebar} handleTouchStart={handleTouchStart} handleTouchMove={handleTouchMove} handleTouchEnd={handleTouchEnd} />
        } else if (this.state.evDetailsLoaded) {
            divCode = <EvList selectedEvFeature={this.state.evFeatureClicked} clearSelectedEvFeature={() => this.clearSelectedEvFeature()} showDetails={() => this.hideEvs()} showSideBar={() => this.showSidebar()} evFilterState={this.evFilterState} getEvChargersInMapArea={() => this.getEvChargersInMapArea()} evChargerFC={this.state.evChargerFC} clearEvChargers={() => this.clearEvChargers()} triggerSelectedEvChargerListItem={(feature: any, allowDeselect: boolean) => this.triggerSelectedEvChargerListItem(feature, allowDeselect)} userLocation={this.state.userLocation} handleTouchStart={handleTouchStart} handleTouchMove={handleTouchMove} handleTouchEnd={handleTouchEnd} />
        } else if (this.state.gADetailsLoaded) {
            divCode = <GADetails feature={this.state.featureClicked} showDetails={() => this.showList()} showSideBar={() => this.showSidebar()} zoomToMapReturnBounds={this.zoomToMapReturnBounds}
                handleTouchStart={handleTouchStart} handleTouchMove={handleTouchMove} handleTouchEnd={handleTouchEnd}
                associatedSubstations={this.state.associatedSubstations} showSubstations={() => this.showSubstations()} clearAssAssets={() => this.clearAssociatedAssets()} showSubstationDetails={this.showSubstationDetails}
                associatedBSPs={this.state.associatedBSPs} showBSPDetails={this.showBSPDetails} showAssociatedBSPs={() => this.showAssociatedBSPs()}
                parentGSP={this.state.parentGSP} showParentDetails={this.triggerShowGADetails} showCorrespondingGSP={this.showCorrespondingGSP} shouldShowCorrespondingLink={this.state.constrainedGSPSOn && this.state.unconstrainedGSPSOn && this.state.partiallyConstrainedGSPSOn} />
        } else if (this.state.substationDetailsLoaded) {
            if (this.state.pageLoaded === "networkCapacity") {
                divCode = <DemandSubstationDetails feature={this.state.substationClicked} showDetails={() => this.showDetails()} showSideBar={() => this.showSidebar()} handleTouchStart={handleTouchStart} handleTouchMove={handleTouchMove} handleTouchEnd={handleTouchEnd}
                    showSubstationOnMap={() => this.showSubstationOnMap()} fromMapClick={this.state.substationDetailsFromMapClick} pageLoaded={this.state.pageLoaded} showCorrespondingGSP={this.showCorrespondingGSP} shouldShowCorrespondingLink={this.state.demandGSPsOn} />;
            } else {
                divCode = <GASubstationDetails feature={this.state.substationClicked} showDetails={() => this.showDetails()} showSideBar={() => this.showSidebar()} handleTouchStart={handleTouchStart} handleTouchMove={handleTouchMove} handleTouchEnd={handleTouchEnd}
                    showSubstationOnMap={() => this.showSubstationOnMap()} fromMapClick={this.state.substationDetailsFromMapClick} pageLoaded={this.state.pageLoaded} showCorrespondingGSP={this.showCorrespondingGSP} shouldShowCorrespondingLink={this.state.constrainedGSPSOn && this.state.unconstrainedGSPSOn && this.state.partiallyConstrainedGSPSOn} />;
            }
        } else if (this.state.demandDetailsLoaded) {
            divCode = <DemandDetails feature={this.state.featureClicked} showDetails={() => this.showList()} showSideBar={() => this.showSidebar()} zoomToMapReturnBounds={this.zoomToMapReturnBounds}
                handleTouchStart={handleTouchStart} handleTouchMove={handleTouchMove} handleTouchEnd={handleTouchEnd} associatedSubstations={this.state.associatedSubstations} showSubstations={() => this.showSubstations()} clearAssAssets={() => this.clearAssociatedAssets()} showSubstationDetails={this.showSubstationDetails}
                associatedBSPs={this.state.associatedBSPs} showBSPDetails={this.showBSPDetails} showAssociatedBSPs={() => this.showAssociatedBSPs()}
                parentGSP={this.state.parentGSP} showParentDetails={this.triggerShowDemandDetails} showCorrespondingGSP={this.showCorrespondingGSP} shouldShowCorrespondingLink={this.state.demandGSPsOn} />
        } else {

            if (this.state.pageLoaded === "networkCapacity") {
                let demandDataToPass: any = undefined;
                if (this.state.demandJSON !== undefined) {

                    let demandJsonString = JSON.stringify(this.state.demandJSON);
                    demandDataToPass = JSON.parse(demandJsonString) as DemandCollection;

                    // demandDataToPass = Object.create(this.state.demandJSON);


                    if (!!!this.state.demandGSPsOn) {
                        demandDataToPass.gsp.features = [];
                    }

                    if (!!!this.state.demandBSPsOn) {
                        demandDataToPass.bsp.features = [];
                    }

                    if (!!!this.state.demandPSSsOn) {
                        demandDataToPass.pss.features = [];
                    }
                }
                divCode = <NetworkCapacity demandJSON={demandDataToPass} showSidebar={() => this.showSidebar()} showFilter={() => this.showFilter()} sortByTime={this.sortByTimeDemand} fetchState={this.state.fetchStateDemand} sortAtoZ={this.state.sortAtoZDemand} showNetworkCapacity={() => this.showNetworkCapacity()}
                    showGenerationAvailability={() => this.showGenerationAvailability()} handleTouchStart={handleTouchStart} handleTouchMove={handleTouchMove} handleTouchEnd={handleTouchEnd} showDemandDetails={this.triggerShowDemandDetails} showSubstationDetails={this.showSubstationDetails}
                    northNCOn={this.state.northNCOn} filterNCNorth={() => this.filterNCNorth()} southNCOn={this.state.southNCOn} filterNCSouth={() => this.filterNCSouth()} nCToggles={this.state.nCToggles} setNCToggles={this.setNCToggles}
                    toggleLicences={this.toggleLicences} toggleContactUs={this.toggleContactUs}
                />
            } else {
                //TODO: Only filter once            
                let dataToPass: any = undefined;
                if (this.state.gAJSON !== undefined) {

                    let jsonString = JSON.stringify(this.state.gAJSON);
                    dataToPass = JSON.parse(jsonString) as GenerationCollection;
                    // dataToPass = Object.create(this.state.gAJSON);

                    if (!!!this.state.constrainedGSPSOn) {
                        dataToPass.constrained.features = [];
                    }

                    if (!!!this.state.unconstrainedGSPSOn) {
                        dataToPass.unconstrained.features = [];
                    }

                    if (!!!this.state.partiallyConstrainedGSPSOn) {
                        dataToPass.partiallyConstrained.features = [];
                    }

                    if (!!!this.state.constrainedBSPSOn) {
                        dataToPass.bulk_constrained.features = [];
                    }

                    if (!!!this.state.unconstrainedBSPSOn) {
                        dataToPass.bulk_unconstrained.features = [];
                    }

                    if (!!!this.state.partiallyConstrainedBSPSOn) {
                        dataToPass.bulk_partiallyConstrained.features = [];
                    }

                    if (!!!this.state.constrainedPSSSOn) {
                        dataToPass.substation_constrained.features = [];
                    }

                    if (!!!this.state.unconstrainedPSSSOn) {
                        dataToPass.substation_unconstrained.features = [];
                    }

                    if (!!!this.state.partiallyConstrainedPSSSOn) {
                        dataToPass.substation_partiallyConstrained.features = [];
                    }
                }
                divCode = <GenerationAvailability gAJSON={dataToPass} fetchState={this.state.fetchStateGeneration} showSidebar={() => this.showSidebar()} showFilter={() => this.showFilter()} sortByTime={this.sortByTime} sortAtoZ={this.state.sortAtoZ} showNetworkCapacity={() => this.showNetworkCapacity()}
                    showGenerationAvailability={() => this.showGenerationAvailability()} handleTouchStart={handleTouchStart} handleTouchMove={handleTouchMove} handleTouchEnd={handleTouchEnd}
                    showGADetails={this.triggerShowGADetails} showSubstationDetails={this.showSubstationDetails}
                    northGAOn={this.state.northGAOn} filterGANorth={() => this.filterGANorth()} southGAOn={this.state.southGAOn} filterGASouth={() => this.filterGASouth()} gAToggles={this.state.gAToggles} setGAToggles={this.setGAToggles}
                    toggleLicences={this.toggleLicences} toggleContactUs={this.toggleContactUs}
                />
            }
        }

        var isIos = isPlatform('ios');
        var isAndroid = isPlatform('android');

        var isAssSubs = false;
        if (this.state.associatedSubstations?.length > 0) {
            isAssSubs = true;
        }

        var isAssBSPs = false;
        if (this.state.associatedBSPs?.length > 0) {
            isAssBSPs = true;
        }

        return (
            <IonContent>
                <IonPage id="main">
                    <PageHeader titlePage={this.state.pageLoaded} />
                    <div className={`splitLeft ${this.state.sideBar ? "sidebarOpen" : "fullscreen"}`}>
                        <div ref={el => this.mapContainer = el} className='mapContainer' />
                        {!this.state.sideBar ?
                            <div id="sidebarExpander" onClick={this.showSidebar}>
                                <IonIcon md={caretBack} />
                            </div>
                            :
                            <div id="sidebarExpander" onClick={this.showSidebar}>
                                <IonIcon md={caretForward} />
                            </div>
                        }

                        {isIos || isAndroid ?
                            <>
                                <IonButton slot="icon-only" className="customMapButton acronymButtonOnMap" size="large" color="secondary" onClick={() => this.showAcronyms()}>
                                    <IonLabel>&rdquo;</IonLabel>
                                </IonButton>
                                <IonButton slot="icon-only" className="customMapButton filterButtonOnMap" size="large" color="secondary" onClick={() => this.showFilter()} disabled={this.state.fetchStateGeneration !== FetchState.SUCCESS || this.state.fetchStateDemand !== FetchState.SUCCESS}>
                                    <IonIcon md={funnelSharp} />
                                </IonButton>
                                <IonButton slot="icon-only" className="customMapButton summaryButtonOnMap" size="large" color="secondary" onClick={() => this.showSummary()}>
                                    <IonIcon md={menuSharp} />
                                </IonButton>
                                <IonButton slot="icon-only" className="customMapButton downloadButtonOnMap" size="large" color="secondary" onClick={() => this.showDownloads()}>
                                    <IonIcon md={downloadSharp} />
                                </IonButton>
                                <IonButton slot="icon-only" className="customMapButton zoomOutButtononMap" size="large" color="secondary" onClick={() => this.zoomOutToUK()}>
                                    <IonIcon md={earthSharp} />
                                </IonButton>
                                <IonButton slot="icon-only" id="threedbutton" className="customMapButton threedButtonOnMap" size="large" color="secondary" onClick={() => this.threeDimensional()}>
                                    <IonIcon md={this.state.tilted ? mapOutline : cubeOutline} />
                                </IonButton>
                                <IonButton slot="icon-only" disabled={this.state.disableEvButtons} className="customMapButton evButtononMap" size="large" color="secondary" onClick={() => this.showEvs()}>
                                    <IonIcon md={carSportSharp} />
                                </IonButton>
                            </>
                            :
                            <>
                                <IonButton data-tip="View Acronyms" slot="icon-only" className="customMapButton acronymButtonOnMap" size="large" color="secondary" onClick={() => this.showAcronyms()}>
                                    <IonLabel>&rdquo;</IonLabel>
                                </IonButton>
                                <IonButton data-tip="Filter" slot="icon-only" className="customMapButton filterButtonOnMap" size="large" color="secondary" onClick={() => this.showFilter()} disabled={this.state.fetchStateGeneration !== FetchState.SUCCESS || this.state.fetchStateDemand !== FetchState.SUCCESS}>
                                    <IonIcon md={funnelSharp} />
                                </IonButton>
                                <IonButton data-tip="Summary" slot="icon-only" className="customMapButton summaryButtonOnMap" size="large" color="secondary" onClick={() => this.showSummary()}>
                                    <IonIcon md={menuSharp} />
                                </IonButton>
                                <IonButton data-tip="Downloads" slot="icon-only" className="customMapButton downloadButtonOnMap" size="large" color="secondary" onClick={() => this.showDownloads()}>
                                    <IonIcon md={downloadSharp} />
                                </IonButton>
                                <IonButton data-tip="Reset Map" slot="icon-only" className="customMapButton zoomOutButtononMap" size="large" color="secondary" onClick={() => this.zoomOutToUK()}>
                                    <IonIcon md={earthSharp} />
                                </IonButton>
                                <IonButton data-tip={this.state.tilted ? "Turn Tilt Off" : "Turn Tilt On"} slot="icon-only" id="threedbutton" className="customMapButton threedButtonOnMap" size="large" color="secondary" onClick={() => this.threeDimensional()}>
                                    <IonIcon md={this.state.tilted ? mapOutline : cubeOutline} />
                                </IonButton>
                                <IonButton data-tip="View Nearby EV Chargers" disabled={this.state.disableEvButtons} slot="icon-only" className="customMapButton evButtononMap" size="large" color="secondary" onClick={() => this.showEvs()}>
                                    <IonIcon md={carSportSharp} />
                                </IonButton>
                            </>
                        }

                        <MediaQuery orientation="portrait">
                            {(matches: any) =>
                                matches ?
                                    <MediaQuery minWidth={540} >
                                        <ReactTooltip class='toolTips' backgroundColor="#003E66" place="left" effect="solid" />
                                    </MediaQuery>
                                    :
                                    <MediaQuery minHeight={540} >
                                        <ReactTooltip class='toolTips' backgroundColor="#003E66" place="left" effect="solid" />
                                    </MediaQuery>

                            }
                        </MediaQuery>

                        <IonModal isOpen={this.state.showTsandCs} id={'tsandcs' + this.state.tandcsToggle} animated={true} onDidDismiss={() => this.setShowModal(false)} backdropDismiss={false}>

                            <h3>Terms and Conditions</h3>
                            {!!!this.state.tandcsToggle &&
                                <div id="agree"><p>Before using this service, you must agree to our <a id="link" onClick={() => { this.setState({ tandcsToggle: true }) }}>terms and conditions</a></p></div>
                            }

                            {this.state.tandcsToggle &&
                                <div className="tsandcsContentScroll">
                                    <div id="terms">
                                        <p>The generation availability/network capacity maps provide an indication of the networks capability to connect large-scale developments to Major Substations. The main assumptions are:</p>

                                        <p>For generation availability</p>
                                        <ul>
                                            <li>The data is provided as an indication only.</li>
                                            <li>The existing generation and hence the constraints shown are based on existing generation commissioned on the network and do not include any outstanding and quoted generation.</li>
                                            <li>Each substation has been given an overall classification based on the known constraints.</li>
                                            <li>Connections to the substations with an overall RED classification are still possible, however there might be a requirement for significant network reinforcement to overcome the impact on the network constraints.</li>
                                            <li>Connections to the substations with an overall GREEN classification may still not be possible, because of up-stream requirement for significant network reinforcement to overcome the impact on the network constraints.</li>
                                        </ul>

                                        <p>For network capacity</p>
                                        <ul>
                                            <li>The data is provided as an indication capability and capacity only.</li>
                                            <li>The existing minimum and maximum load and hence the constraints shown are based on existing demand commissioned on the network and do not include any outstanding and quoted demand.</li>
                                            <li>Connections to the substations with classification limited available capacity may still be possible, however there might be a requirement for significant network reinforcement to overcome the impact on the network constraints.</li>
                                        </ul>

                                        <p><b>Use of Information: </b>
                                            By viewing or using any data from this area, you are confirming that you are aware that [Southern Electric Power Distribution plc (SEPD)] or [Scottish Hydro Electric Power Distribution plc (SHEPD)] is the owner of the data and that you will not (i) share with or sell the data to any third party, (ii) use the data for any purpose other than assessing the suitability of capability of the network to connect large scale developments, or (ii) in any other way make the data available to a person not employed by your business without the prior agreement of SEPD/SHEPD. You will indemnify SEPD or SHEPD against all costs, claims, damages, losses and expenses arising as a result of any failure by you to comply with these requirements.</p>
                                        <p><b>Legal disclaimer: </b>We have developed the generation availability/network capacity maps to assist you with connections applications in constrained areas. They give a general illustration of generation availability constraints only and cannot be relied upon to assess the terms of connection for specific premises. Nothing in this disclaimer limits or excludes our liability for (a) death or personal injury caused by our negligence; (b) fraud or fraudulent misrepresentation; or (c) any other liability that cannot be limited or excluded by applicable law. Subject to the above, we shall not be liable to you in tort (including negligence) or otherwise, arising under or in connection with your use of the generation availability map for: (a) loss of profits; (b) loss of sales or business; (c) loss of agreements or contracts; (d) loss of anticipated savings; (e) loss of or damage to goodwill; or (f) any indirect or consequential loss.
                                        </p>
                                        <p>Nothing in this disclaimer limits or excludes our liability for (a) death or personal injury caused by our negligence; (b) fraud or fraudulent misrepresentation; or (c) any other liability that cannot be limited or excluded by applicable law. Subject to the above, we shall not be liable to you in tort (including negligence) or otherwise, arising under or in connection with your use of the generation availability map for: (a) loss of profits; (b) loss of sales or business; (c) loss of agreements or contracts; (d) loss of anticipated savings; (e) loss of or damage to goodwill; or (f) any indirect or consequential loss.</p>
                                        <p>We try to ensure that the content of this website is accurate, regularly updated and obtained from reliable sources, but there may be delays, omissions or inaccuracies in information contained in this site.  All information in this website is provided “as is”.   We give no guarantee of the completeness, accuracy, suitability or currentness of the information on this website and make no representation or warranty of any kind, express or implied, as to the accuracy of information hosted on this website or its fitness for a particular purpose. We do not accept responsibility for any errors or omissions in the information or for any results obtained, decisions made or actions taken following review of the information.</p>
                                        <p> <b> Severance: </b>
                                            If any of these provisions should be determined unlawful, invalid or otherwise unenforceable by reason of law then, to the extent and within the jurisdiction which that provision is found to be unlawful, invalid or unenforceable, then that provision shall be deleted and shall not affect the validity and enforceability of the remaining provisions which shall continue to be binding and in force.</p>
                                        <p><b>Governing Law and Jurisdiction: </b>
                                            These terms and conditions and any dispute arising herein shall be governed by and construed in accordance with the laws of either England or Wales or Scotland depending on the country from which access is made, and subject to the exclusive jurisdiction of the English or Scottish Courts, whichever is applicable.</p>
                                        <p> By continuing to access this area or using the date therein, you accept the terms and conditions detailed above.</p>
                                        <p>Last Updated 16/11/2021</p>
                                    </div>
                                    <div id="note">
                                        <p>Please note that distribution network constraints may be caused by a number of issues such as thermal, fault level, voltage constraints. Other constraints can be caused by physical space limitations within SSEN licence areas or limitations imposed by the Transmission System Operator.</p>
                                        <p>Please note that the Constrained term at distribution level in our Central Southern England Generation Availability Map is assessed based on the addition of at least 5MVA synchronous generator resulting in SSEN network constraints. If you intend to apply for generator capacities below 5MVA you may well consider the constrained areas as well as unconstrained areas.</p>
                                        <p>Our Innovation Team are looking at different ways to overcome distribution network thermal constraints, please view our current projects by clicking <a id="link" href="https://www.ssepd.co.uk/DistributionInnovation/" target="_blank" rel="noopener noreferrer">here</a>. For any other queries please send an e-mail at commercial.contracts@sse.com</p></div>
                                </div>
                            }
                            <div id="buttons">
                                <IonButton shape="round" onClick={event => window.location.href = 'https://www.ssen.co.uk'}>Decline</IonButton>
                                <IonButton shape="round" onClick={() => this.setShowModal(false)}>Accept and Continue</IonButton>
                            </div>
                        </IonModal>

                        <this.MapStyleSwitcher mapStyle={this.state.mapStyle} switchMapStyle={(style: MapStyle) => this.switchMapStyle(style)} showMapTypeDialog={this.state.showMapTypeDialog} toggleMapTypeDialog={this.toggleMapTypeDialog} />
                        <MapKey mapKeyExpanded={this.state.mapKeyExpanded} toggleMapKey={() => this.toggleMapKey()} pageLoaded={this.state.pageLoaded}
                            constrainedGSPSOn={this.state.constrainedGSPSOn} unconstrainedGSPSOn={this.state.unconstrainedGSPSOn} partiallyConstrainedGSPSOn={this.state.partiallyConstrainedGSPSOn}
                            constrainedBSPSOn={this.state.constrainedBSPSOn} unconstrainedBSPSOn={this.state.unconstrainedBSPSOn} partiallyConstrainedBSPSOn={this.state.partiallyConstrainedBSPSOn}
                            constrainedPSSSOn={this.state.constrainedPSSSOn} unconstrainedPSSSOn={this.state.unconstrainedPSSSOn} partiallyConstrainedPSSSOn={this.state.partiallyConstrainedPSSSOn}
                            demandGSPsOn={this.state.demandGSPsOn} demandBSPsOn={this.state.demandBSPsOn} demandPSSsOn={this.state.demandPSSsOn}
                            isAssSubs={isAssSubs} isAssBSPs={isAssBSPs} evChargersShown={this.state.evDetailsLoaded} showFilterFromMapKey={showFilterFromMapKey} />

                        {this.state.mapStyleLoaded ?
                            <></>
                            :
                            <>
                                <div id="mapLoadingIndicatorContainer">
                                    <IonIcon className="loadingIndicator" src="/assets/img/loading.svg"></IonIcon>
                                    <h4>Loading Map...</h4>
                                </div>
                            </>
                        }

                        <div className="evSearchButtonContainer">
                            {this.state.evDetailsLoaded
                                ?
                                <IonButton shape="round" disabled={this.state.disableEvButtons} onClick={
                                    () => {
                                        this.getEvChargersInMapArea();
                                        this.setState({ disableEvButtons: true });
                                    }
                                }>
                                    Search Area for EV Chargers
                                </IonButton>
                                :
                                <></>
                            }
                        </div>
                    </div>
                    <div className={`splitRight ${this.state.sideBar ? "open" : "closed"}`} >
                        {divCode}
                    </div>

                    <IonModal id='multiple-ev-selected' isOpen={this.state.multipleEvFeaturesClicked.length >= 2} onDidDismiss={() => this.setState({ multipleEvFeaturesClicked: [] })}>
                        <h3>Multiple EV Chargers at Site</h3>
                        <h6>Select a charger from the list below to view more details.</h6>
                        <IonList>
                            {this.state.multipleEvFeaturesClicked.map((evFeature: any, index: any) =>
                                <IonItem lines="none" key={"click" + index} onClick={() => {
                                    this.setState({ multipleEvFeaturesClicked: [] });
                                    setTimeout(() => {
                                        this.triggerSelectedEvChargerListItem(evFeature, false);
                                    }, 250);
                                }}>
                                    <IonThumbnail key={"thumbnail" + index} slot="start" className="multipleEvChargerThumb">
                                        {evFeature.properties.serviceStatus === 'IN_SERVICE' ? <img alt='available' src="/assets/img/evChargerAvailable.svg" /> : <img alt='unavailable' src="/assets/img/evChargerUnavailable.svg" />}
                                    </IonThumbnail>
                                    <div>
                                        <IonLabel key={"name" + index}>{evFeature.properties.name}</IonLabel>
                                        <IonLabel key={"network" + index}>{evFeature.properties.network}</IonLabel>
                                    </div>
                                </IonItem>
                            )}
                        </IonList>
                        <IonButton shape="round" onClick={() => this.setState({ multipleEvFeaturesClicked: [] })}>Cancel</IonButton>
                    </IonModal>


                    <IonModal id='multiple-ev-selected' isOpen={this.state.multipleFeaturesClicked.length >= 2} onDidDismiss={() => this.setState({ multipleFeaturesClicked: [] })}>
                        <h3>Multiple Assets at Site</h3>
                        <h6>Select from the list below to view more details.</h6>
                        <IonList id="gspDetailsList" className="gspDetailsList contentScroll">
                            <IonGrid key="grid2" className="dataList" color="secondary" >
                                {this.state.pageLoaded === "networkCapacity" ?
                                    this.drawMultipleDemand()
                                    :
                                    this.drawMultipleGA()
                                }
                            </IonGrid>
                        </IonList>
                        <IonButton shape="round" onClick={() => this.setState({ multipleFeaturesClicked: [] })}>Cancel</IonButton>
                    </IonModal>

                    <LicencesPage showLicences={this.state.showLicences} toggleLicences={this.toggleLicences}></LicencesPage>
                    <ContactUsPage showContactUs={this.state.showContactUs} toggleContactUs={this.toggleContactUs}></ContactUsPage>
                </IonPage>
            </IonContent>
        );
    }


    setShowModal(showModal: boolean): void {
        this.setState({ showTsandCs: showModal });
    }

    drawMultipleDemand() {
        if (this.state.multipleFeaturesClicked !== undefined && this.state.multipleFeaturesClicked.length > 0) {
            return this.state.multipleFeaturesClicked.map((genData: any, index: any) => {
                if (genData?.geometry?.type === "Point") {

                    let classificationText = genData?.properties?.MarkerType;
                    let icon = "/assets/img/gsp_demand.svg";
                    let isSubstation = false;

                    if (classificationText === "BSP") {
                        icon = "/assets/img/bulk_demand.svg";
                    } else if (classificationText === "PSS") {
                        icon = "/assets/img/substation_demand.svg";
                        isSubstation = true;
                    }

                    let showFromMapClick = true;
                    let isAssociatedBSPShowing = false;

                    let layers: string | any[] = [];
                    if (this.state.mapObj?.getLayer('ass-substation-demand-layer') !== undefined) {
                        layers = layers.concat('ass-substation-demand-layer');
                    }

                    if (this.state.mapObj?.queryRenderedFeatures({ layers }).length > 0) {
                        showFromMapClick = false;
                    }

                    let bsplayers: string | any[] = [];
                    if (this.state.mapObj?.getLayer('ass-bulk-demand-layer') !== undefined) {
                        bsplayers = bsplayers.concat('ass-bulk-demand-layer');
                    }

                    if (this.state.mapObj?.queryRenderedFeatures({ bsplayers }).length > 0) {
                        isAssociatedBSPShowing = true;
                    }

                    return (
                        <IonRow key={'row' + index} onClick={isSubstation ? () => {
                            this.setState({ multipleFeaturesClicked: [] });
                            setTimeout(() => {
                                const isFeatureInAssList = this.state.associatedSubstations?.filter((p: { properties: any; }) => p.properties.CompositeId === genData?.properties?.CompositeId);
                                if (isFeatureInAssList?.length === 0) {
                                    this.clearAssociatedAssets();
                                }
                                this.showSubstationDetails(genData, showFromMapClick);
                            }, 250);
                        }
                            : () => {
                                this.setState({ multipleFeaturesClicked: [] });
                                setTimeout(() => {
                                    const isFeatureInAssList = this.state.associatedBSPs?.filter((p: { properties: any; }) => p.properties.CompositeId === genData?.properties?.CompositeId);
                                    if (isFeatureInAssList?.length === 0) {
                                        if (genData?.properties?.CompositeId !== this.state.featureClicked?.properties?.CompositeId) {
                                            this.clearAssociatedAssets();
                                        }
                                    }
                                    this.showDemandOrAssBSP(genData, isAssociatedBSPShowing);
                                }, 250);
                            }} >
                            <IonCol size='30px' key={index + 'col1'}>
                                <IonRow>
                                    <IonIcon id="GSPIcon" src={icon} />
                                </IonRow>
                            </IonCol>
                            <IonCol key={index + 'col2'} className="dataListItemText">
                                <div>
                                    <b id="GSPName">{genData.properties.Name?.toUpperCase()}</b>
                                </div>
                                <div>
                                    <b>Transmission Status:</b> {' ' + genData.properties.TransmissionConstraintsDisplayText}
                                </div>
                                <div>
                                    <b>Distribution Status:</b> {' ' + genData.properties.DistributionConstraintsDisplayText}
                                </div>
                                <div>
                                    <b>Voltage (kV):</b> {' ' + genData.properties.Voltage}
                                </div>
                                {classificationText === "BSP" ?
                                    <div>
                                        <b>Corresponding {genData?.properties?.ParentMarkerType}:</b> {' ' + genData.properties.ParentDemandGridSupplyPoint}
                                    </div>
                                    :
                                    <></>}
                            </IonCol>
                        </IonRow>
                    );
                } else {
                    // return an empty fragment for the areas to supress warning of no turn in arrow function
                    return (<React.Fragment key={"emptySummary" + index}></React.Fragment>);
                }

            });
        }
    }

    drawMultipleGA() {
        if (this.state.multipleFeaturesClicked !== undefined && this.state.multipleFeaturesClicked.length > 0) {
            return this.state.multipleFeaturesClicked.map((genData: any, index: any) => {
                if (genData?.geometry?.type === "Point") {

                    let classificationText = genData?.properties?.Classification;
                    let isSubstation = false;

                    let icon = "/assets/img/constrainedGSP.svg";
                    if (classificationText === "GSP-GREEN") {
                        icon = "/assets/img/unconstrainedGSP.svg";
                    } else if (classificationText === "GSP-AMBER") {
                        icon = "/assets/img/partiallyConstrainedGSP.svg";
                    } else if (classificationText === "BSP-GREEN") {
                        icon = "/assets/img/bulk_unconstrained.svg";
                    } else if (classificationText === "BSP-AMBER") {
                        icon = "/assets/img/bulk_partial.svg";
                    } else if (classificationText === "BSP-RED") {
                        icon = "/assets/img/bulk_constrained.svg";
                    } else if (classificationText === "PSS-GREEN") {
                        isSubstation = true;
                        icon = "/assets/img/substation_unconstrained.svg";
                    } else if (classificationText === "PSS-AMBER") {
                        isSubstation = true;
                        icon = "/assets/img/substation_partial.svg";
                    } else if (classificationText === "PSS-RED") {
                        isSubstation = true;
                        icon = "/assets/img/substation_constrained.svg";
                    }

                    let showFromMapClick = true;
                    let isAssociatedBSPShowing = false;


                    let layers: string | any[] = [];
                    if (this.state.mapObj?.getLayer('ass-substation-constrained-layer') !== undefined) {
                        layers = layers.concat('ass-substation-constrained-layer');
                    }
                    if (this.state.mapObj?.getLayer('ass-substation-unconstrained-layer') !== undefined) {
                        layers = layers.concat('ass-substation-unconstrained-layer');
                    }
                    if (this.state.mapObj?.getLayer('ass-substation-partial-constrained-layer') !== undefined) {
                        layers = layers.concat('ass-substation-partial-constrained-layer');
                    }

                    if (this.state.mapObj?.queryRenderedFeatures({ layers }).length > 0) {
                        showFromMapClick = false;
                    }

                    let layersBSP: string | any[] = [];
                    if (this.state.mapObj?.getLayer('ass-bsp-constrained-layer') !== undefined) {
                        layersBSP = layersBSP.concat('ass-bsp-constrained-layer');
                    }
                    if (this.state.mapObj?.getLayer('ass-bsp-unconstrained-layer') !== undefined) {
                        layersBSP = layersBSP.concat('ass-bsp-unconstrained-layer');
                    }
                    if (this.state.mapObj?.getLayer('ass-bsp-partial-constrained-layer') !== undefined) {
                        layersBSP = layersBSP.concat('ass-bsp-partial-constrained-layer');
                    }

                    if (this.state.mapObj?.queryRenderedFeatures({ layersBSP }).length > 0) {
                        isAssociatedBSPShowing = true;
                    }

                    return (
                        <IonRow key={'row' + index} onClick={isSubstation ? () => {
                            this.setState({ multipleFeaturesClicked: [] });
                            setTimeout(() => {
                                const isFeatureInAssList = this.state.associatedSubstations?.filter((p: { properties: any; }) => p.properties.CompositeId === genData?.properties?.CompositeId);
                                if (isFeatureInAssList?.length === 0) {
                                    this.clearAssociatedAssets();
                                }
                                this.showSubstationDetails(genData, showFromMapClick);
                            }, 250);
                        }
                            :
                            () => {
                                this.setState({ multipleFeaturesClicked: [] });
                                setTimeout(() => {
                                    const isFeatureInAssList = this.state.associatedBSPs?.filter((p: { properties: any; }) => p.properties.CompositeId === genData?.properties?.CompositeId);
                                    if (isFeatureInAssList?.length === 0) {
                                        if (genData?.properties?.CompositeId !== this.state.featureClicked?.properties?.CompositeId) {
                                            this.clearAssociatedAssets();
                                        }
                                    }
                                    this.showGAOrAssBSP(genData, isAssociatedBSPShowing);
                                }, 250);
                            }

                        } >                    <IonCol size='30px' key={index + 'col1'}>
                                <IonRow>
                                    <IonIcon id="GSPIcon" src={icon} />
                                </IonRow>
                            </IonCol>
                            <IonCol key={index + 'col2'} className="dataListItemText">
                                <div>
                                    <b id="GSPName">{genData.properties.Name?.toUpperCase()}</b>
                                </div>
                                <div>
                                    <b>Upstream Status:</b> {' ' + genData.properties.UpstreamStatusDisplayText}
                                </div>
                                <div>
                                    <b>Downstream Status:</b> {' ' + genData.properties.DownstreamStatusDisplayText}
                                </div>
                                <div className="multiLine">
                                    <b>Break Fault Level vs Rating (kA):</b> {' ' + genData.properties.BreakFaultLevelRating}
                                </div>
                                {/* <div>
                        <b>Consortia Count:</b> {' ' + genData.properties.ConsortiaCount}
                      </div> */}
                                {classificationText.startsWith("BSP") ?
                                    <div>
                                        <b>Corresponding {genData?.properties?.ParentMarkerType}:</b> {' ' + genData.properties.ParentGenerationGridSupplyPoint}
                                    </div>
                                    :
                                    <></>}
                            </IonCol>
                        </IonRow>
                    );
                } else {
                    // return an empty fragment for the areas to supress warning of no turn in arrow function
                    return (<React.Fragment key={"emptySummary" + index}></React.Fragment>);
                }

            });
        }
    }

}

export default withIonLifeCycle(MapBoxContainer);
