import React, { useEffect } from 'react';
import { IonButton, IonCol, IonGrid, IonIcon, IonItem, IonItemDivider, IonLabel, IonList, IonRow } from '@ionic/react';
import './FilterPage.css';
import { arrowBackSharp, caretDown, caretForward, closeSharp, mapOutline, mapSharp } from 'ionicons/icons';
import MediaQuery from 'react-responsive';
import { DemandList, Feature, QuotedJobs } from './demandJSONStructure';
import { GenerationCollection } from './generationJSONStructure';
import { logScreenView } from '../helpers/firebase';
// import { isCollectindemandnalytics } from '../helpers/httpHelper';

type DemandDetailsProps = {
  feature: any;
  showDetails: any;
  showSideBar: any;
  zoomToMapReturnBounds: any;
  handleTouchStart: any;
  handleTouchMove: any;
  handleTouchEnd: any;
  associatedSubstations: any;
  showSubstations: any;
  clearAssAssets: any;
  showSubstationDetails: any;
  associatedBSPs: any
  showBSPDetails: any;
  showAssociatedBSPs: any;
  parentGSP: any;
  showParentDetails: any;
  showCorrespondingGSP: any;
  shouldShowCorrespondingLink: any;
}

const stateOfToggles = {
  info: true,
  contactInfo: false,
  quotedJobs: false,
  contractedDemand: false,
  substations: true,
  assBsps: true
}

function drawSubstations(fs: Feature[], showSubstationDetails: any, showSubstations: any) {

  if (fs !== undefined) {

    if (fs?.length > 1) {
      fs?.sort((a, b) => a?.properties.Name.localeCompare(b?.properties.Name));
    }

    return fs?.map((genData, index) => {
      if (genData?.geometry?.type === "Point") {

        let icon = "/assets/img/substation_demand.svg";

        return (
          <IonRow key={'row' + index} onClick={() => { showSubstations(); showSubstationDetails(genData, false); }}>
            <IonCol size='30px' key={index + 'col1'}>
              <IonRow>
                <IonIcon id="GSPIcon" src={icon} />
              </IonRow>
            </IonCol>
            <IonCol key={index + 'col2'} className="dataListItemText">
              <div>
                <b id="GSPName">{genData.properties.Name}</b>
              </div>
              <div>
                <b>Transmission Status:</b> {' ' + genData.properties.TransmissionConstraintsDisplayText}
              </div>
              <div>
                <b>Distribution Status:</b> {' ' + genData.properties.DistributionConstraintsDisplayText}
              </div>
              <div>
                <b>Voltage (kV):</b> {' ' + genData.properties.Voltage}
              </div>
            </IonCol>
          </IonRow>
        );
      } else {
        // return an empty fragment for the areas to supress warning of no turn in arrow function
        return (<React.Fragment key={"emptySummary" + index}></React.Fragment>);
      }

    });
  }
}


function drawBSPS(fs: Feature[], showBSPDetails: any, showBSPs: any) {

  if (fs !== undefined) {

    if (fs?.length > 1) {
      fs?.sort((a, b) => a?.properties.Name.localeCompare(b?.properties.Name));
    }

    return fs?.map((genData, index) => {
      if (genData?.geometry?.type === "Point") {

        let icon = "/assets/img/bulk_demand.svg";

        return (
          <IonRow key={'row' + index} onClick={() => { showBSPs(); showBSPDetails(genData); }}>
            <IonCol size='30px' key={index + 'col1'}>
              <IonRow>
                <IonIcon id="GSPIcon" src={icon} />
              </IonRow>
            </IonCol>
            <IonCol key={index + 'col2'} className="dataListItemText">
              <div>
                <b id="GSPName">{genData.properties.Name}</b>
              </div>
              <div>
                <b>Transmission Status:</b> {' ' + genData.properties.TransmissionConstraintsDisplayText}
              </div>
              <div>
                <b>Distribution Status:</b> {' ' + genData.properties.DistributionConstraintsDisplayText}
              </div>
              <div>
                <b>Voltage (kV):</b> {' ' + genData.properties.Voltage}
              </div>
            </IonCol>
          </IonRow>
        );
      } else {
        // return an empty fragment for the areas to supress warning of no turn in arrow function
        return (<React.Fragment key={"emptySummary" + index}></React.Fragment>);
      }

    });
  }
}


class DemandDetails extends React.Component<DemandDetailsProps>{
  // useEffect(() => {
  //    if(isCollectindemandnalytics)logScreenView("ptw_outage_filters", "FilterPage_PTW");
  // }, []);

  public state: any;

  constructor(props: any) {
    super(props);
    this.state = {
      demandToggles: {
        info: true,
        contactInfo: false,
        quotedJobs: false,
        contractedDemand: false,
        substations: true,
        assBsps: true
      }
    }
  }

  componentDidMount() {
    logScreenView("odp_nC_details", "NC_Details_ODP");
  }

  closeDemandDetails = () => {
    this.props.zoomToMapReturnBounds();
    this.props.showDetails();
    this.props.clearAssAssets(); //should this be in mapboxcontainer?
  }

  showCorrespoindingGSP = () => {
    let thisItem = this.props.feature.properties;
    this.props.showCorrespondingGSP(thisItem?.ParentMarker, true);
  }

  render() {

    let demandFeature: any = this.props.feature;

    let demandColl = demandFeature?.properties;
    const demandLat = demandFeature?.geometry?.coordinates[1].toFixed(4);
    const demandLong = demandFeature?.geometry?.coordinates[0].toFixed(4);

    //TODO:: Work out why this is a string!!
    let listOfDemands: DemandList[] = [];
    let listOfQuotedJobs: any = [];

    if (demandColl !== null) {

      if (demandColl.DemandList instanceof Array) {
        listOfDemands = demandColl.DemandList;
      } else {
        if(demandColl.DemandList !== undefined){
          let obj = JSON.parse(demandColl.DemandList);
          listOfDemands = obj;
        }
      }

      if (demandColl.QuotedJobs instanceof Array) {
        listOfQuotedJobs = demandColl.QuotedJobs;
      } else {
        if(demandColl.QuotedJobs !== undefined){
          let obj = JSON.parse(demandColl.QuotedJobs);
          listOfQuotedJobs = obj;
        }
      }

    }

    let classificationText = demandColl?.MarkerType;
    let icon = "/assets/img/gsp_demand.svg";
    if (classificationText === "BSP") {
      icon = "/assets/img/bulk_demand.svg";
    }

    let isGSP = true;
    if (classificationText !== null && classificationText === "BSP") {
      isGSP = false;
    }

    let transmissionColor = "/assets/img/red.svg";
    if(demandColl?.TransmissionConstraintsDisplayText == "Partially Constrained"){
      transmissionColor = "/assets/img/amber.svg"
    }else if(demandColl?.TransmissionConstraintsDisplayText == "Unconstrained"){
      transmissionColor = "/assets/img/green.svg"
    }

    let distributionColor = "/assets/img/red.svg";
    if(demandColl?.DistributionConstraintsDisplayText == "Partially Constrained"){
      distributionColor = "/assets/img/amber.svg"
    }else if(demandColl?.DistributionConstraintsDisplayText == "Unconstrained"){
      distributionColor = "/assets/img/green.svg"
    }

    return (


      <>
        <div className="header" onTouchStart={touchStartEvent => this.props.handleTouchStart(touchStartEvent)} onTouchMove={touchMoveEvent => this.props.handleTouchMove(touchMoveEvent)} onTouchEnd={() => this.props.handleTouchEnd()}>
          {this.props.parentGSP !== null ?
            <IonButton id="back" size="small" color="tertiary" fill="outline" shape="round" onClick={() => this.props.showParentDetails(this.props.parentGSP)}>
              <IonIcon md={arrowBackSharp} />
              Back
            </IonButton>
            :
            <IonButton id="back" size="small" color="tertiary" fill="outline" shape="round" onClick={this.closeDemandDetails}>
              <IonIcon md={arrowBackSharp} />
              List
            </IonButton>
          }
          <MediaQuery minWidth={640}>
            {(matches: any) =>
              matches
                ?
                <IonButton id="close" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showSideBar}>
                  Close
                  <IonIcon md={closeSharp} />
                </IonButton>
                :
                <IonButton id="close" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showSideBar}>
                  Map
                  <IonIcon style={{ 'marginLeft': 8 }} md={mapSharp} />
                </IonButton>
            }
          </MediaQuery>

          <div className="detailsIconWithButtons">
            <IonIcon id="detailsheader" src={icon} />
          </div>
          <h3 className='detailsTitle'>{demandColl?.Name?.toUpperCase()}</h3>
          <IonGrid key="demandDetailsGrid" id="detailsheaderGrid" color="secondary" >
            <IonRow key="detailsRow1">
              <IonCol key='col1row1' className="detailsHeaderLeftColext">
                Location (Lat, Long)
              </IonCol>
              <div className="textSeperator highOpacity"></div>
              <IonCol key='col2row1' className="detailsHeaderRightColText">
                {demandLat},{demandLong}
              </IonCol>
            </IonRow>
            <IonRow key="detailsRow2">
              <IonCol key='col1row2' className="detailsHeaderLeftColext">
                Minimum Load (MW)
              </IonCol>
              <div className="textSeperator highOpacity"></div>
              <IonCol key='col2row2' className="detailsHeaderRightColText">
              {parseFloat(demandColl?.MinimumLoad).toFixed(2)}
              </IonCol>
            </IonRow>
            <IonRow key="detailsRow3">
              <IonCol key='col1row3' className="detailsHeaderLeftColext">
                Maximum Load (MW)
              </IonCol>
              <div className="textSeperator highOpacity"></div>
              <IonCol key='col2row3' className="detailsHeaderRightColText">
              {parseFloat(demandColl?.MaximumLoad).toFixed(2)}
              </IonCol>
            </IonRow>
            <IonRow key="detailsRow4">
              <IonCol key='col1row4' className="detailsHeaderLeftColext">
                Transfer Nameplate Rating (MVA)
              </IonCol>
              <div className="textSeperator highOpacity"></div>
              <IonCol key='col2row4' className="detailsHeaderRightColText">
                {demandColl?.NameplateRating}
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        <div className="content" id="gspDetailsContent">
          <IonList id="gspDetailsList" className="gspDetailsList contentScroll">
            <IonItemDivider onClick={() => {
              stateOfToggles.info = !this.state.demandToggles.info;
              this.setState({ demandToggles: stateOfToggles });
            }}>
              {!this.state.demandToggles.info && <IonIcon md={caretForward} />}
              {this.state.demandToggles.info && <IonIcon md={caretDown} color="primary" />}
              {isGSP ? <IonLabel id="gspDetailsItem">Grid Supply Point Information</IonLabel> : <IonLabel id="gspDetailsItem">Bulk Supply Point (BSP) Information</IonLabel>}
            </IonItemDivider>
            {this.state.demandToggles.info &&
              <>
                <IonItem color="secondary">
                  <IonGrid className="gspDetailsGrid">
                    <IonCol key='gspCol' className="gspDetailsItemText">
                      <div>
                        <b>Transmission Status:</b> 
                        <div id="siteClassification">
                        <IonIcon id="iconClassification" src={transmissionColor}></IonIcon>
                        <IonLabel id="labelClassification">{' ' + demandColl?.TransmissionConstraintsDisplayText}</IonLabel>
                        </div>
                      </div>
                      <div>
                        <b>Distribution Status:</b>
                        <div id="siteClassification">
                        <IonIcon id="iconClassification" src={distributionColor}></IonIcon>
                        <IonLabel id="labelClassification">{' ' + demandColl?.DistributionConstraintsDisplayText}</IonLabel>
                        </div>
                      </div>
                      <div>
                        <b>Voltage (kV):</b> {' ' + demandColl?.Voltage}
                      </div>
                      {((demandColl?.ShowTransmissionWorksData === "true" && demandColl?.TransmissionWorks !== "") || (demandColl?.TransmissionWorks !== "" && demandColl?.TransmissionWorks !== "undefined")) &&
                        <div>
                          <b>Transmission Works:</b> {' ' + demandColl?.TransmissionWorks}
                        </div>}
                      {((demandColl?.ShowTransmissionReinforcementCompletionDate === "true") || (demandColl?.TransmissionReinforcementCompletionDate !== "" && demandColl?.TransmissionReinforcementCompletionDate !== "undefined")) &&
                        <div>
                          <b>Transmission Reinforcement Completion Date:</b> {' ' + demandColl?.TransmissionReinforcementCompletionDate}
                        </div>}
                      {((demandColl?.ShowDistributionWorksData === "true" && demandColl?.DistributionReinforcementWorks !== "") || (demandColl?.DistributionReinforcementWorks !== "" && demandColl?.DistributionReinforcementWorks !== "undefined")) &&
                        <div>
                          <b>Distribution Works:</b> {' ' + demandColl?.DistributionReinforcementWorks}
                        </div>}
                      {((demandColl?.ShowDistributionReinforcementCompletionDate === "true") || (demandColl?.DistributionReinforcementCompletionDate !== "" && demandColl?.DistributionReinforcementCompletionDate !== "undefined")) &&
                        <div>
                          <b>Distribution Reinforcement Completion Date:</b> {' ' + demandColl?.DistributionReinforcementCompletionDate}
                        </div>}
                      {demandColl?.ParentDemandGridSupplyPoint !== "null" && demandColl?.ParentDemandGridSupplyPoint !== "undefined" &&
                        <div className="correspondingGSP"> 
                          <b>Corresponding {demandColl?.ParentMarkerType}:</b> 
                          {this.props.shouldShowCorrespondingLink ?
                            <a onClick={this.showCorrespoindingGSP}>
                              {' ' + demandColl?.ParentDemandGridSupplyPoint?.toUpperCase()}
                              </a>
                              :
                              <>
                              {' ' + demandColl?.ParentDemandGridSupplyPoint?.toUpperCase()}
                              </>
  }
                        </div>}
                    </IonCol>
                  </IonGrid>
                </IonItem>
              </>
            }

            {isGSP &&
              <>
                {(listOfQuotedJobs?.length !== 0 || demandColl?.ShowQuotedJobData === "true") ?
                  <>
                    <IonItemDivider onClick={() => {
                      stateOfToggles.quotedJobs = !this.state.demandToggles.quotedJobs;
                      this.setState({ demandToggles: stateOfToggles });
                    }}>
                      {!this.state.demandToggles.quotedJobs && <IonIcon md={caretForward} />}
                      {this.state.demandToggles.quotedJobs && <IonIcon md={caretDown} color="primary" />}
                      <IonLabel id="gspDetailsItem">Quoted Jobs</IonLabel>
                    </IonItemDivider>
                    {this.state.demandToggles.quotedJobs &&
                      <>
                        <IonItem color="secondary">
                          <IonGrid className="gspDetailsGrid">
                            <IonCol key='quotedJobsCol2' className="gspDetailsItemText">
                              {listOfQuotedJobs?.map((elem: any, index: any) => {
                                return (
                                  <div key={"quotedJobs" + index} className="listOfGens">
                                    <div key={"pNameQJ" + index}>
                                      <b>Project Name:</b> <div className="textSeperator"></div> {' ' + elem?.ProjectName}
                                    </div>
                                    <div key={"techQJ" + index}>
                                      <b>Technology Type:</b> <div className="textSeperator"></div> {' ' + elem?.TechnologyType}
                                    </div>
                                    <div key={"capacityQJ" + index}>
                                      <b>Contracted Capacity (MVA):</b> <div className="textSeperator"></div> {' ' + elem?.ContractedCapacity}
                                    </div>{
                                      ((elem?.ShowQuotationExpiryDate === "true") || (elem?.QuotationExpiryDate !== "" && elem?.QuotationExpiryDate !== "undefined")) &&
                                      <div key={"expiryQJ" + index}>
                                        <b>Quotation Expiry Date:</b> <div className="textSeperator"></div> {' ' + elem?.QuotationExpiryDate}
                                      </div>
                                    }
                                    <div key={"contestableQJ" + index}>
                                      <b>Contestable:</b> <div className="textSeperator"></div> {' ' + elem?.Contestable}
                                    </div>
                                    <div key={"noncontestableQJ" + index}>
                                      <b>Non-contestable:</b> <div className="textSeperator"></div> {' ' + elem?.NonContestable}
                                    </div>
                                    {((elem?.ShowReinforcement === "true") || (elem?.Reinforcement !== "" && elem?.Reinforcement !== "undefined")) &&
                                      <div key={"reinforcement" + index}>
                                        <b>Reinforcement:</b> <div className="textSeperator"></div> {' ' + elem?.Reinforcement}
                                      </div>
                                    }
                                  </div>
                                )
                              })}
                            </IonCol>
                          </IonGrid>
                        </IonItem>
                      </>
                    }
                  </>
                  :
                  <></>
                }

                {(listOfDemands?.length > 0 || demandColl?.ShowContractedDemandData === "true") &&
                  <>
                    <IonItemDivider onClick={() => {
                      stateOfToggles.contractedDemand = !this.state.demandToggles.contractedDemand;
                      this.setState({ demandToggles: stateOfToggles });
                    }}>
                      {!this.state.demandToggles.contractedDemand && <IonIcon md={caretForward} />}
                      {this.state.demandToggles.contractedDemand && <IonIcon md={caretDown} color="primary" />}
                      <IonLabel id="gspDetailsItem">Contracted Demand</IonLabel>
                    </IonItemDivider>
                    {this.state.demandToggles.contractedDemand &&
                      <>
                        <IonItem color="secondary">
                          <IonGrid className="gspDetailsGrid">
                            <IonCol key='demandsCol2' className="gspDetailsItemText">
                              {listOfDemands?.map((elem: any, index: any) => {
                                return (
                                  <div key={"listDemands" + index} className="listOfGens">
                                    <div key={"pNameD" + index}>
                                      <b>Project Name:</b> <div className="textSeperator"></div> {' ' + elem?.ProjectName}
                                    </div>
                                    <div key={"techD" + index}>
                                      <b>Status:</b> <div className="textSeperator"></div> {' ' + elem?.Status}
                                    </div>
                                    <div key={"voltageD" + index}>
                                      <b>Voltage (kV):</b> <div className="textSeperator"></div> {' ' + elem?.Voltage}
                                    </div>
                                    <div key={"connectionD" + index}>
                                      <b>Connection Date:</b> <div className="textSeperator"></div> {' ' + elem?.ConnectionDate}
                                    </div>
                                    <div key={"capacityD" + index}>
                                      <b>Contracted Capacity:</b> <div className="textSeperator"></div> {' ' + elem?.ContractedCapacity}
                                    </div>
                                  </div>
                                )
                              })}
                            </IonCol>
                          </IonGrid>
                        </IonItem>
                      </>
                    }
                  </>
                }

              </>
            }
            
            {((demandColl?.ContactData?.Name !== undefined && demandColl?.ContactData?.Name !== "undefined" && demandColl?.ContactData?.Name !== "") ||
            (demandColl?.ContactData?.Title !== undefined && demandColl?.ContactData?.Title !== "undefined" && demandColl?.ContactData?.Title !== "") ||
            (demandColl?.ContactData?.Email !== undefined && demandColl?.ContactData?.Email !== "undefined" && demandColl?.ContactData?.Email !== "") ||
            (demandColl?.ContactData?.Tel !== undefined && demandColl?.ContactData?.Tel !== "undefined" && demandColl?.ContactData?.Tel !== ""))
            &&
            <>
            <IonItemDivider onClick={() => {
              stateOfToggles.contactInfo = !this.state.demandToggles.contactInfo;
              this.setState({ demandToggles: stateOfToggles });
            }}>
              {!this.state.demandToggles.contactInfo && <IonIcon md={caretForward} />}
              {this.state.demandToggles.contactInfo && <IonIcon md={caretDown} color="primary" />}
              <IonLabel id="gspDetailsItem">Contact Information</IonLabel>
            </IonItemDivider>
            {this.state.demandToggles.contactInfo &&
              <>
                <IonItem color="secondary">
                  <IonGrid className="gspDetailsGrid">
                    <IonCol key='gspCol' className="gspDetailsItemText">
                      {(demandColl?.ContactData?.Name !== undefined && demandColl?.ContactData?.Name !== "undefined" && demandColl?.ContactData?.Name !== "") &&
                        <div>
                          <b>Contact:</b> {' ' + demandColl?.ContactData?.Name}
                        </div>
                      }
                      {(demandColl?.ContactData?.Title !== undefined && demandColl?.ContactData?.Title !== "undefined" && demandColl?.ContactData?.Title !== "") &&
                        <div>
                          <b>Title:</b> {' ' + demandColl?.ContactData?.Title}
                        </div>
                      }
                      {(demandColl?.ContactData?.Email !== undefined && demandColl?.ContactData?.Email !== "undefined" && demandColl?.ContactData?.Email !== "") &&
                        <div>
                          <b>Email:</b> {' ' + demandColl?.ContactData?.Email}
                        </div>
                      }
                      {(demandColl?.ContactData?.Tel !== undefined && demandColl?.ContactData?.Tel !== "undefined" && demandColl?.ContactData?.Tel !== "") &&
                        <div>
                          <b>Tel:</b> {' ' + demandColl?.ContactData?.Tel}
                        </div>
                      }
                    </IonCol>
                  </IonGrid>
                </IonItem>
              </>
            }
            </>
           
            }

            {isGSP && this.props.associatedBSPs?.length !== 0 &&
              <>
                <IonItemDivider onClick={() => {
                  stateOfToggles.assBsps = !this.state.demandToggles.assBsps;
                  this.setState({ demandToggles: stateOfToggles });
                }}>
                  {!this.state.demandToggles.assBsps && <IonIcon md={caretForward} />}
                  {this.state.demandToggles.assBsps && <IonIcon md={caretDown} color="primary" />}
                  <IonLabel id="gspDetailsItem">Bulk Supply Points</IonLabel>
                </IonItemDivider>
                {this.state.demandToggles.assBsps &&
                  <>
                    <IonItem color="secondary">
                      <IonGrid>
                        <IonRow class="ion-justify-content-center">
                          <IonButton shape="round" className="sidebarButtonFixedWidth" size="small" onClick={() => this.props.showAssociatedBSPs()}>
                            <IonIcon slot="end" icon={mapOutline} />
                            View BSPs on Map
                          </IonButton>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                    <IonGrid key="grid2" className="dataList" color="secondary" >
                      {drawBSPS(this.props.associatedBSPs, this.props.showBSPDetails, this.props.showAssociatedBSPs)}
                    </IonGrid>
                  </>
                }
              </>
            }

            {this.props.associatedSubstations?.length !== 0 &&
              <>
                <IonItemDivider onClick={() => {
                  stateOfToggles.substations = !this.state.demandToggles.substations;
                  this.setState({ demandToggles: stateOfToggles });
                }}>
                  {!this.state.demandToggles.substations && <IonIcon md={caretForward} />}
                  {this.state.demandToggles.substations && <IonIcon md={caretDown} color="primary" />}
                  <IonLabel id="gspDetailsItem">Substations</IonLabel>
                </IonItemDivider>
                {this.state.demandToggles.substations &&
                  <>
                    <IonItem color="secondary">
                      <IonGrid>
                        <IonRow class="ion-justify-content-center">
                          <IonButton shape="round" className="sidebarButtonFixedWidth" size="small" onClick={() => this.props.showSubstations()}>
                            <IonIcon slot="end" icon={mapOutline} />
                            View Substations on Map
                          </IonButton>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                    <IonGrid key="grid2" className="dataList" color="secondary" >
                      {drawSubstations(this.props.associatedSubstations, this.props.showSubstationDetails, this.props.showSubstations)}
                    </IonGrid>
                  </>
                }
              </>
            }


          </IonList>
        </div>
      </>
    );
  };
}

export default DemandDetails;