import React from 'react';
import { IonButton, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonRow, isPlatform } from '@ionic/react';
import './FilterPage.css';
import { arrowBackSharp, closeSharp, documentSharp, downloadSharp, mapSharp } from 'ionicons/icons';
import MediaQuery from 'react-responsive';
import { saveAs } from "file-saver";
import { logFirebaseEvent, logScreenView } from '../helpers/firebase';
import { domain } from '../helpers/httpHelper';


type DownloadProps = {
  pageLoaded: any;
  showFilter: any;
  showSidebar: any;
  handleTouchStart: any;
  handleTouchMove: any;
  handleTouchEnd: any;
}

type DownloadState = {
  demandDownloads: any[];
  generationDownloads: any[];
}

function download(json: any, pageLoaded: any) {
  var isIos = isPlatform('ios');
  var isAndroid = isPlatform('android');

  if (isIos || isAndroid) {
    //dont save on mobile
    alert("In order to download this file, please access this site on a computer");
  } else {
    if (pageLoaded === "generationAvailability") {
      logFirebaseEvent("user_interaction", { downloaded_GA_file: "" + json.name });
    } else {
      logFirebaseEvent("user_interaction", { downloaded_NC_file: "" + json.name });
    }

    saveAs(
      json.url,
      json.name + "." + json.type
    );
  }

}

function fetchDownloadableJSON(pageLoaded: any, demandDownloads: any[], generationDownloads: any[]) {

  var listOfDownloads: any[] = [];

  //change the fetch URL based on this
  if (pageLoaded === "generationAvailability") {
    listOfDownloads = generationDownloads;
  } else {
    listOfDownloads = demandDownloads;
  }

  return listOfDownloads?.map((fileDownload, index) => {

    let icon = <IonIcon src="/assets/img/fileTypes/other.svg" slot='start' size='large' />
    if (fileDownload.type === "xlsx" || fileDownload.type === "xls") {
      icon = <IonIcon src="/assets/img/fileTypes/xls.svg" slot='start' size='large' />
    } else if (fileDownload.type === "kml" || fileDownload.type === "kmz") {
      icon = <IonIcon src="/assets/img/fileTypes/kml.svg" slot='start' size='large' />
    } else if (fileDownload.type === "pdf") {
      icon = <IonIcon src="/assets/img/fileTypes/pdf.svg" slot='start' size='large' />
    } else if (fileDownload.type === "doc" || fileDownload.type === "docx") {
      icon = <IonIcon src="/assets/img/fileTypes/doc.svg" slot='start' size='large' />
    } else if (fileDownload.type === "zip" || fileDownload.type === "7z") {
      icon = <IonIcon src="/assets/img/fileTypes/zip.svg" slot='start' size='large' />
    } else if (fileDownload.type === "json") {
      icon = <IonIcon src="/assets/img/fileTypes/json.svg" slot='start' size='large' />
    }

    let fileTypeDesc = "Other"
    if (fileDownload.type === "xlsx" || fileDownload.type === "xls") {
      fileTypeDesc = "Excel"
    } else if (fileDownload.type === "kml" || fileDownload.type === "kmz") {
      fileTypeDesc = "Google Earth"
    } else if (fileDownload.type === "pdf") {
      fileTypeDesc = "PDF"
    } else if (fileDownload.type === "doc" || fileDownload.type === "docx") {
      fileTypeDesc = "Word"
    } else if (fileDownload.type === "zip" || fileDownload.type === "7z") {
      fileTypeDesc = "Archive"
    } else if (fileDownload.type === "json") {
      fileTypeDesc = "JSON"
    }

    return (
      <IonItem  key={'item' + index} onClick={() => { download(fileDownload, pageLoaded); }}>
        {icon}
        <IonLabel text-wrap>
          <h2>{fileDownload.name}</h2>
          <p>Format: {fileTypeDesc}</p>
        </IonLabel>
      </IonItem>
    );

  });
}


class Downloads extends React.Component<DownloadProps, DownloadState>{

  constructor(props: any) {
    super(props);
    this.state = {
      demandDownloads: [],
      generationDownloads: []
    };
  }

  componentDidMount() {

    if (this.props.pageLoaded === "generationAvailability") {
      logScreenView("odp_gA_downloads", "DownloadPage_GA_ODP");
    } else {
      logScreenView("odp_nc_downloads", "DownloadPage_NC_ODP");
    }

    var getDownloadsData = async () => {
      // const demandData = await fetch("https://ssen-odp.s3.eu-west-1.amazonaws.com/latest-demand-files.json");
      // const generationData = await fetch("https://ssen-odp.s3.eu-west-1.amazonaws.com/latest-generation-files.json");

      const demandData = await fetch(domain + "/latest-demand-files.json");
      const generationData = await fetch(domain + "/latest-generation-files.json");

      const demandDownloads = await demandData.json();
      const generationDownloads = await generationData.json();

      this.setState({ demandDownloads: demandDownloads });
      this.setState({ generationDownloads: generationDownloads });
    }
    getDownloadsData();

  }

  render() {
    return (
      <>
        <div className="header" onTouchStart={touchStartEvent => this.props.handleTouchStart(touchStartEvent)} onTouchMove={touchMoveEvent => this.props.handleTouchMove(touchMoveEvent)} onTouchEnd={() => this.props.handleTouchEnd()}>
          <IonButton id="back" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showFilter}>
            <IonIcon md={arrowBackSharp} />
            Back
          </IonButton>
          <MediaQuery minWidth={640}>
            {(matches: any) =>
              matches
                ?
                <IonButton id="close" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showSidebar}>
                  Close
                  <IonIcon md={closeSharp} />
                </IonButton>
                :
                <IonButton id="close" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showSidebar}>
                  Map
                  <IonIcon style={{ 'marginLeft': 8 }} md={mapSharp} />
                </IonButton>
            }
          </MediaQuery>

          <div className="filterIconWithButtons">
            <IonIcon id="filterheader" md={downloadSharp} />
          </div>
          {this.props.pageLoaded === "generationAvailability" ?
            <h3 className='filtertitle'>Generation Availability Downloads</h3>
            :
            <h3 className='filtertitle'>Network Capacity Downloads</h3>
          }

        </div>

        <div className="contentScroll">
          <IonList className='downloadList'>
            {fetchDownloadableJSON(this.props.pageLoaded, this.state.demandDownloads, this.state.generationDownloads)}
          </IonList>
        </div>
      </>
    );
  };
}

export default Downloads;


