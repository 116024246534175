/**
 * Generates a GeoJSON FeatureCollection
 * @param {JSON} evChargerData
 * @return {results} GeoJSON FeatureCollection
 */
const fetchEvData = evChargerData => {

  const evChargerFL = [];

  var listOfEvChargers = evChargerData;
  if (listOfEvChargers !== undefined) {
    for (var i = 0; i < listOfEvChargers.length; i++) {
      var evCharger = listOfEvChargers[i];
      evChargerFL.push({
        type: 'Feature',
        geometry: evCharger.geojson,
        properties: evCharger,
      });
    }
  }

  return Promise.resolve({
    evChargers: {
      type: 'FeatureCollection',
      features: evChargerFL,
    }
  });
};

export default fetchEvData;
