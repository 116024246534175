import React, { useEffect } from 'react';
import { IonButton, IonIcon, IonItem, IonLabel, IonList, IonToggle, isPlatform } from '@ionic/react';
import './FilterPage.css';
import { arrowBackSharp, closeSharp, funnel, mapSharp } from 'ionicons/icons';
import MediaQuery from 'react-responsive';
import { logScreenView } from '../helpers/firebase';
// import { isCollectingAnalytics } from '../helpers/httpHelper';

type FilterProps = {
  pageLoaded: any;
  constrainedGSPS: any;
  constrainedGSPSOn: boolean;
  unconstrainedGSPS: any;
  unconstrainedGSPSOn: boolean;
  partiallyConstrainedGSPS: any;
  partiallyConstrainedGSPSOn: boolean;
  bulkConstrained: any;
  bulkConstrainedOn: boolean;
  bulkUnconstrained: any;
  bulkUnconstrainedOn: boolean;
  bulkPartiallyConstrained: any;
  bulkPartiallyConstrainedOn: boolean;
  substationConstrained: any;
  substationConstrainedOn: boolean;
  substationUnconstrained: any;
  substationUnconstrainedOn: boolean;
  substationPartiallyConstrained: any;
  substationPartiallyConstrainedOn: boolean;
  showFilter: any;
  showSidebar: any;
  handleTouchStart: any;
  handleTouchMove: any;
  handleTouchEnd: any;
  demandGSPs: any;
  demandGSPsOn: boolean;
  demandBSPs: any;
  demandBSPsOn: boolean;
  demandPSSs: any;
  demandPSSsOn: boolean;
}

class FilterPage extends React.Component<FilterProps>{
  // useEffect(() => {
  //    if(isCollectingAnalytics)logScreenView("ptw_outage_filters", "FilterPage_PTW");
  // }, []);

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    if (this.props.pageLoaded === "generationAvailability") {
      logScreenView("odp_gA_filter", "FilterPage_GA_ODP");
    } else {
      logScreenView("odp_nc_filter", "FilterPage_NC_ODP");
    }
  }

  render() {
    return (
      <>
        <div className="header" onTouchStart={touchStartEvent => this.props.handleTouchStart(touchStartEvent)} onTouchMove={touchMoveEvent => this.props.handleTouchMove(touchMoveEvent)} onTouchEnd={() => this.props.handleTouchEnd()}>
          <IonButton id="back" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showFilter}>
            <IonIcon md={arrowBackSharp} />
            Back
          </IonButton>
          <MediaQuery minWidth={640}>
            {(matches: any) =>
              matches
                ?
                <IonButton id="close" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showSidebar}>
                  Close
                  <IonIcon md={closeSharp} />
                </IonButton>
                :
                <IonButton id="close" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showSidebar}>
                  Map
                  <IonIcon style={{ 'marginLeft': 8 }} md={mapSharp} />
                </IonButton>
            }
          </MediaQuery>

          <div className="filterIconWithButtons">
            <IonIcon id="filterheader" md={funnel} />
          </div>
          {this.props.pageLoaded === "generationAvailability" ?
            <h3 className='filtertitle'>Filter Generation Availability</h3>
            :
            <h3 className='filtertitle'>Filter Network Capacity</h3>
          }
        </div>

        {this.props.pageLoaded === "generationAvailability" ?
          <div className="filtercontent contentScroll">
            <div id="filterList">
              <div id="dataFilterItem" className="shadedItem" >
                <IonIcon src="/assets/img/unconstrainedGSP.svg" />
                <IonLabel id="filterLabel">Unconstrained GSP</IonLabel>
                <IonToggle id="filterTog" checked={this.props.unconstrainedGSPSOn} onIonChange={this.props.unconstrainedGSPS} />
              </div>
              <div id="dataFilterItem" className="shadedItem" >
                <IonIcon src="/assets/img/partiallyConstrainedGSP.svg" />
                <IonLabel id="filterLabel">Partially Constrained GSP</IonLabel>
                <IonToggle id="filterTog" checked={this.props.partiallyConstrainedGSPSOn} onIonChange={this.props.partiallyConstrainedGSPS} />
              </div>
              <div id="dataFilterItem" className="shadedItem" >
                <IonIcon src="/assets/img/constrainedGSP.svg" />
                <IonLabel id="filterLabel">Constrained GSP</IonLabel>
                <IonToggle id="filterTog" checked={this.props.constrainedGSPSOn} onIonChange={this.props.constrainedGSPS} />
              </div>

              <div id="dataFilterItem" className="shadedItem" >
                <IonIcon src="/assets/img/bulk_unconstrained.svg" />
                <IonLabel id="filterLabel">Unconstrained BSP</IonLabel>
                <IonToggle id="filterTog" checked={this.props.bulkUnconstrainedOn} onIonChange={this.props.bulkUnconstrained} />
              </div>
              <div id="dataFilterItem" className="shadedItem" >
                <IonIcon src="/assets/img/bulk_partial.svg" />
                <IonLabel id="filterLabel">Partially Constrained BSP</IonLabel>
                <IonToggle id="filterTog" checked={this.props.bulkPartiallyConstrainedOn} onIonChange={this.props.bulkPartiallyConstrained} />
              </div>
              <div id="dataFilterItem" className="shadedItem" >
                <IonIcon src="/assets/img/bulk_constrained.svg" />
                <IonLabel id="filterLabel">Constrained BSP</IonLabel>
                <IonToggle id="filterTog" checked={this.props.bulkConstrainedOn} onIonChange={this.props.bulkConstrained} />
              </div>

              <div id="dataFilterItem" className="shadedItem" >
                <IonIcon src="/assets/img/substation_unconstrained.svg" />
                <IonLabel id="filterLabel">Unconstrained Substation</IonLabel>
                <IonToggle id="filterTog" checked={this.props.substationUnconstrainedOn} onIonChange={this.props.substationUnconstrained} />
              </div>
              <div id="dataFilterItem" className="shadedItem" >
                <IonIcon src="/assets/img/substation_partial.svg" />
                <IonLabel id="filterLabel">Partially Constrained Substation</IonLabel>
                <IonToggle id="filterTog" checked={this.props.substationPartiallyConstrainedOn} onIonChange={this.props.substationPartiallyConstrained} />
              </div>
              <div id="dataFilterItem" className="shadedItem" >
                <IonIcon src="/assets/img/substation_constrained.svg" />
                <IonLabel id="filterLabel">Constrained Substation</IonLabel>
                <IonToggle id="filterTog" checked={this.props.substationConstrainedOn} onIonChange={this.props.substationConstrained} />
              </div>
            </div>
          </div>
          :
          <div className="filtercontent contentScroll">
            <div id="filterList">
              <div id="dataFilterItem" className="shadedItem" >
                <IonIcon src="/assets/img/gsp_demand.svg" />
                <IonLabel id="filterLabel">Grid Supply Points</IonLabel>
                <IonToggle id="filterTog" checked={this.props.demandGSPsOn} onIonChange={this.props.demandGSPs} />
              </div>
              <div id="dataFilterItem" className="shadedItem" >
                <IonIcon src="/assets/img/bulk_demand.svg" />
                <IonLabel id="filterLabel">Bulk Supply Points</IonLabel>
                <IonToggle id="filterTog" checked={this.props.demandBSPsOn} onIonChange={this.props.demandBSPs} />
              </div>
              <div id="dataFilterItem" className="shadedItem" >
                <IonIcon src="/assets/img/substation_demand.svg" />
                <IonLabel id="filterLabel">Substations</IonLabel>
                <IonToggle id="filterTog" checked={this.props.demandPSSsOn} onIonChange={this.props.demandPSSs} />
              </div>
            </div>
          </div>
        }
      </>
    );
  };
}

export default FilterPage;