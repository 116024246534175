import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from "firebase/analytics";
import { AppPreferences } from '../preferences/preferences';

const firebaseConfig = {
    apiKey: "AIzaSyCnxm_IYOyQCL098QnDEPiwb9KssXtRSIc",
    authDomain: "open-data-portal-4b830.firebaseapp.com",
    projectId: "open-data-portal-4b830",
    storageBucket: "open-data-portal-4b830.appspot.com",
    messagingSenderId: "1060881475863",
    appId: "1:1060881475863:web:88cc6195288c3a5ab92f52",
    measurementId: "G-J6PFR82ZWY"
  };

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export function logFirebaseEvent(name, parameters) {
  if (window.gaCookiesEnabled && AppPreferences.getAppPreferences().preferences.devPrefs.general.buildConfig.collectAnalytics) {
      //web  
        logEvent(analytics, name, parameters);
  }
}

export function logScreenView(name, screen) {
  if (window.gaCookiesEnabled && AppPreferences.getAppPreferences().preferences.devPrefs.general.buildConfig.collectAnalytics) {
      //web  
      logEvent(analytics, "screen_view", {
        page_name: name,
        firebase_screen: screen,
        firebase_screen_class: screen
      });
    } 
    else {
        console.log("Analytics Event : " + name);
    }
}
