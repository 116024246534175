export interface Coordinate {
    longitude: number;
    latitude: number;
}

export interface Geometry {
    type: string;
    coordinates: Coordinate[];
}

export interface Feature {
    type: string;
    geometry: Geometry;
    properties: Properties;
}

export interface Generators{
    Status: string;
    TechnologyType: string;
    ProjectName: string;
    Voltage: string;
    ShowConnectionDate: boolean;
    ConnectionDate: string;
    Id: number;
    ContractedCapacity: string;
}

export interface QuotedJobs{
    TechnologyType: string;
    ProjectName: string;
    Reinforcement: string;
    QuotationExpiryDate: string;
    ShowQuotationExpiryDate: boolean;
    Id: number;
    ContractedCapacity: string;
    Contestable: string;
    ShowReinforcement: boolean;
    NonContestable: string;
}

export interface Properties {
    ShowDistributionWorks: string,
    ParentMarker: string,
    ShowDistributionReinforcementCompletionDate: string,
    ShowReversePowerflowCapability: string,
    // ConsortiaCount: number,
    SiteStatus: string,
    QuotedJobs: QuotedJobs[],
    CompositeId: string,
    DownstreamStatusDescription: string,
    MinimumLoad: string,
    ParentName: string,
    BulkSupplyPoints: string,
    ParentMarkerType: string,
    Name: string,
    ShowContractedGeneratorData: boolean,
    ParentGenerationGridSupplyPoint: string,
    TransmissionWorks: string,
    UpstreamStatusDisplayText: string,
    ReversePowerflowCapability: string,
    Classification: string,
    Voltage: string,
    MaximumLoad: string,
    SoWNeeded: string,
    OtherConstraints: string,
    NameplateRating: string,
    ContractedGeneration: string,
    SumOfQuotedGeneration: string,
    SiteClassification: number,
    DownstreamStatusDisplayText: string,
    ParentId: string,
    DownstreamStatus: number,
    TransmissionReinforcementCompletionDate: string,
    UpstreamStatus: number,
    DistributionReinforcementWorks: string,
    Generators: Generators[],
    PrimarySubstations: string,
    ShowTransmissionWorks: string,
    PartOfSwan: string,
    UpstreamStatusDescription: string,
    MarkerType: string,
    ShowTransmissionReinforcementCompletionDate: string,
    BreakFaultLevelRating: string,
    DistributionReinforcementCompletionDate:string,
    Id: number,
    ShowQuotedJobData: string,
    AvailableHeadroomCapacity: string,
}

export interface FeatureGeneration {
    type: string;
    features: Feature[];
}

export interface GenerationCollection {
    constrained: FeatureGeneration;
    unconstrained: FeatureGeneration;
    partiallyConstrained: FeatureGeneration;
    bulk_constrained: FeatureGeneration;
    bulk_unconstrained: FeatureGeneration;
    bulk_partiallyConstrained: FeatureGeneration;
    substation_constrained: FeatureGeneration;
    substation_unconstrained: FeatureGeneration;
    substation_partiallyConstrained: FeatureGeneration;
}

export enum FetchState {
    IDLE,
    FETCHING,
    SUCCESS,
    FAILED,
  }