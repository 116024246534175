/**
 * Generates a GeoJSON FeatureCollection
 * @param {JSON} region
 * @return {results} GeoJSON FeatureCollection
 */

const fetchOutlineData = outlines => {
    const outlineCollection = [];

    var continentList = outlines.SubGeographicalRegions;
    var countryList = continentList[0].SubGeographicalRegions;
    var regionList = countryList[0].SubGeographicalRegions;

    if (regionList !== undefined) {
        for (var i = 0; i < regionList.length; i++) {
            var region = regionList[i];
            var features = region.GeoJSON.features;
            for (var j = 0; j < features.length; j++) {
                outlineCollection.push(features[j]);
            }
        }
    }
    return Promise.resolve({
        type: 'FeatureCollection',
        features: outlineCollection,
    });

};

export default fetchOutlineData;
