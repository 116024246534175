import { Storage } from '@ionic/storage';

const FILTER_STATE_KEY = "EV_FILTER_STATE";

export class EvFilterState {

    private filterStateJson: any;
    private storage!: Storage;

    constructor() {
        // initialise local storage object
        this.initialiseStorage();

        // initialise filterStateJson object
        this.filterStateJson = {};

        // Set default value for filterStateJson or retrieve from storage
        this.isFilterStateStored().then((filterStateStored) => {
            if (filterStateStored) {
                this.loadFilterState();
            } else {
                this.createDefaultFilterState();
            }
        })
    }

    private async initialiseStorage() {
        this.storage = new Storage();
        await this.storage.create();
    }

    // Generates the default EV filter state
    private createDefaultFilterState() {
        this.filterStateJson = {
            // displayOfflineChargers: true,
            // searchRadius: 5,
            connectorType: {
                type2: true,
                ccs: true,
                chademo: true,
                threePin: true,
                type1: true,
                other: true
            },
            chargeSpeed: {
                slow: true,
                fast: true,
                rapid: true
            },
            networks: [
                {
                    name: "Alfa Power",
                    selected: true
                },
                {
                    name: "BP Pulse",
                    selected: true
                },
                {
                    name: "ChargePlace Scotland",
                    selected: true
                },
                {
                    name: "Charge Your Car",
                    selected: true
                },
                {
                    name: "Char.gy",
                    selected: true
                },
                {
                    name: "ESB EV Solutions",
                    selected: true
                },
                {
                    name: "GeniePoint",
                    selected: true
                },
                {
                    name: "InstaVolt",
                    selected: true
                },
                {
                    name: "Ionity",
                    selected: true
                },
                {
                    name: "Osprey",
                    selected: true
                },
                {
                    name: "Pod Point",
                    selected: true
                },
                {
                    name: "Shell Recharge",
                    selected: true
                },
                {
                    name: "Source London",
                    selected: true
                },
                {
                    name: "Symphony EV",
                    selected: true
                },
                {
                    name: "Tesla",
                    selected: true
                },
                {
                    name: "Ubitricity",
                    selected: true
                },
                {
                    name: "Other Network Operators",
                    selected: true
                }
            ],
            paymentDetails: {
                freeParking: false,
                noSubscription: false,
                noPayment: false
            },
            locationDetails: {
                publicCarPark: false,
                retailCarPark: false,
                onStreet: false,
                other: false
            },
            restrictions: {
                noPhysicalRestrictions: false,
                noAccessRestrictions: false
            }
        }
        // console.log("Created the following default filter state:")
        // console.log(this.filterStateJson);
    }

    // Loads locally stored EV filter state into memory
    private async loadFilterState() {
        this.filterStateJson = await this.storage.get(FILTER_STATE_KEY);
        // console.log("Restored the following filter state from local storage:")
        // console.log(this.filterStateJson);
    }

    // Checks if EV filter state is currently stored locally
    private async isFilterStateStored() {
        let data = await this.storage.get(FILTER_STATE_KEY);
        if (data) {
            // console.log("Existing filter state found within local storage.");
            return true;
        } else {
            // console.log("No existing filter state found within local storage.");
            return false;
        }
    }

    // Saves current in memory EV filter state to local storage
    private async saveFilterState() {
        await this.storage.set(FILTER_STATE_KEY, this.filterStateJson);
        // console.log("Saved the following filter state locally:")
        // console.log(this.filterStateJson);
    }

    // Returns current in memory EV filter state
    public getFilterState() {
        return this.filterStateJson;
    }

    // Generate filter query string for EV API
    public generateFilterQueryString() {
        let query: string;
        query = "";

        let parameters = [];

        // parameters.push({
        //     "key": "rad",
        //     "value": this.filterStateJson.searchRadius
        // })

        if (this.filterStateJson.connectorType.type2) {
            parameters.push({
                "key": "connector",
                "value": "Mennekes"
            })
        }
        if (this.filterStateJson.connectorType.ccs) {
            parameters.push({
                "key": "connector",
                "value": "CCS"
            })
        }
        if (this.filterStateJson.connectorType.chademo) {
            parameters.push({
                "key": "connector",
                "value": "JEVS"
            })
        }
        if (this.filterStateJson.connectorType.threePin) {
            parameters.push({
                "key": "connector",
                "value": "BS1363"
            })
        }
        if (this.filterStateJson.connectorType.type1) {
            parameters.push({
                "key": "connector",
                "value": "SAEJ1772"
            })
        }
        if (this.filterStateJson.connectorType.other) {
            parameters.push({
                "key": "connector",
                "value": "OTHER"
            })
        }

        if (this.filterStateJson.chargeSpeed.slow) {
            parameters.push({
                "key": "chargeRate",
                "value": "SLOW"
            })
        }
        if (this.filterStateJson.chargeSpeed.fast) {
            parameters.push({
                "key": "chargeRate",
                "value": "MEDIUM"
            })
        }
        if (this.filterStateJson.chargeSpeed.rapid) {
            parameters.push({
                "key": "chargeRate",
                "value": "FAST"
            })
        }

        this.filterStateJson.networks.forEach((network: any) => {
            if (network.selected === true) {
                if (network.name === "Other Network Operators") {
                    parameters.push({
                        "key": "network",
                        "value": "OTHER"
                    })
                } else {
                    parameters.push({
                        "key": "network",
                        "value": network.name
                    })
                }
            }
        });

        if (this.filterStateJson.paymentDetails.freeParking) {
            parameters.push({
                "key": "payment",
                "value": "FREE_PARKING"
            })
        }
        if (this.filterStateJson.paymentDetails.noSubscription) {
            parameters.push({
                "key": "payment",
                "value": "NO_SUBSCRIPTION"
            })
        }
        if (this.filterStateJson.paymentDetails.noPayment) {
            parameters.push({
                "key": "payment",
                "value": "NO_PAYMENT"
            })
        }

        if (this.filterStateJson.locationDetails.publicCarPark) {
            parameters.push({
                "key": "location",
                "value": "PUBLIC CAR PARK"
            })
        }
        if (this.filterStateJson.locationDetails.retailCarPark) {
            parameters.push({
                "key": "location",
                "value": "RETAIL CAR PARK"
            })
        }
        if (this.filterStateJson.locationDetails.onStreet) {
            parameters.push({
                "key": "location",
                "value": "ON-STREET"
            })
        }
        if (this.filterStateJson.locationDetails.other) {
            parameters.push({
                "key": "location",
                "value": "OTHER"
            })
        }

        if (this.filterStateJson.restrictions.noPhysicalRestrictions) {
            parameters.push({
                "key": "restriction",
                "value": "PHYSICAL_RESTRICTIONS"
            })
        }
        if (this.filterStateJson.restrictions.noAccessRestrictions) {
            parameters.push({
                "key": "restriction",
                "value": "ACCESS_RESTRICTIONS"
            })
        }

        parameters.forEach((element, i) => {
            query += element.key + "=" + element.value;

            if (i !== parameters.length - 1) {
                query += "&";
            }
        });

        return query;
    }

    // public setDisplayOfflineChargers(state: boolean) {
    //     this.filterStateJson.displayOfflineChargers = state;
    //     this.saveFilterState();
    // }

    // public setSearchRadius(radius: any) {
    //     this.filterStateJson.searchRadius = radius;
    //     this.saveFilterState();
    // }

    public setType2(state: boolean) {
        this.filterStateJson.connectorType.type2 = state;
        this.saveFilterState();
    }

    public setCCS(state: boolean) {
        this.filterStateJson.connectorType.ccs = state;
        this.saveFilterState();
    }

    public setChademo(state: boolean) {
        this.filterStateJson.connectorType.chademo = state;
        this.saveFilterState();
    }

    public setThreePin(state: boolean) {
        this.filterStateJson.connectorType.threePin = state;
        this.saveFilterState();
    }

    public setType1(state: boolean) {
        this.filterStateJson.connectorType.type1 = state;
        this.saveFilterState();
    }

    public setConnectorOther(state: boolean) {
        this.filterStateJson.connectorType.other = state;
        this.saveFilterState();
    }

    public setSlow(state: boolean) {
        this.filterStateJson.chargeSpeed.slow = state;
        this.saveFilterState();
    }

    public setFast(state: boolean) {
        this.filterStateJson.chargeSpeed.fast = state;
        this.saveFilterState();
    }

    public setRapid(state: boolean) {
        this.filterStateJson.chargeSpeed.rapid = state;
        this.saveFilterState();
    }

    public setChargeNetwork(network: any, state: boolean) {
        this.filterStateJson.networks[network].selected = state;
        this.saveFilterState();
    }

    public setFreeParking(state: boolean) {
        this.filterStateJson.paymentDetails.freeParking = state;
        this.saveFilterState();
    }

    public setNoSubscription(state: boolean) {
        this.filterStateJson.paymentDetails.noSubscription = state;
        this.saveFilterState();
    }

    public setNoPayment(state: boolean) {
        this.filterStateJson.paymentDetails.noPayment = state;
        this.saveFilterState();
    }

    public setPublicCarPark(state: boolean) {
        this.filterStateJson.locationDetails.publicCarPark = state;
        this.saveFilterState();
    }

    public setRetailCarPark(state: boolean) {
        this.filterStateJson.locationDetails.retailCarPark = state;
        this.saveFilterState();
    }

    public setOnStreet(state: boolean) {
        this.filterStateJson.locationDetails.onStreet = state;
        this.saveFilterState();
    }

    public setParkingOther(state: boolean) {
        this.filterStateJson.locationDetails.other = state;
        this.saveFilterState();
    }

    public setNoPhysicalRestrictions(state: boolean) {
        this.filterStateJson.restrictions.noPhysicalRestrictions = state;
        this.saveFilterState();
    }

    public setNoAccessRestrictions(state: boolean) {
        this.filterStateJson.restrictions.noAccessRestrictions = state;
        this.saveFilterState();
    }

}