import React, { useEffect } from 'react';
import { IonButton, IonCol, IonGrid, IonIcon, IonItem, IonItemDivider, IonLabel, IonList, IonRow, IonToggle, isPlatform } from '@ionic/react';
import './FilterPage.css';
import { arrowBackSharp, caretDown, caretForward, closeSharp, funnel, locationSharp, mapOutline, mapSharp } from 'ionicons/icons';
import MediaQuery from 'react-responsive';
import { Feature } from './generationJSONStructure';
import { logScreenView } from '../helpers/firebase';
// import { isCollectingAnalytics } from '../helpers/httpHelper';

type SubstationDetailsProps = {
  feature: any;
  showDetails: any;
  showSideBar: any;
  handleTouchStart: any;
  handleTouchMove: any;
  handleTouchEnd: any;
  showSubstationOnMap: any;
  fromMapClick: any;
  pageLoaded: any;
  showCorrespondingGSP: any;
  shouldShowCorrespondingLink: any;
}

const stateOfToggles = {
  substationInfo: true,
  additionalInfo: false,
  registerForConsortia: false,
}

class GASubstationDetails extends React.Component<SubstationDetailsProps>{

  public state: any;

  constructor(props: any) {
    super(props);
    this.state = {
      gAToggles: {
        substationInfo: true,
        additionalInfo: false,
        registerForConsortia: false,
      }
    }
  }

  closeGADetails = () => {
    this.props.showDetails();
  }

  showCorrespoindingGSP = () => {
    let thisItem = this.props.feature.properties;
    this.props.showCorrespondingGSP(thisItem?.ParentMarker, false);
  }

  componentDidMount() {
    logScreenView("odp_gA_substation_details", "GA_Substation_Details_ODP");
  }

  render() {

    let gAColl = this.props.feature.properties;
    const gALat = this.props.feature?.geometry?.coordinates[1].toFixed(4);
    const gALong = this.props.feature?.geometry?.coordinates[0].toFixed(4);


    let icon = "/assets/img/substation_demand.svg";

    if (gAColl.Classification === "PSS-GREEN") {
      icon = "/assets/img/substation_unconstrained.svg";
    } else if (gAColl.Classification === "PSS-AMBER") {
      icon = "/assets/img/substation_partial.svg";
    } else if (gAColl.Classification === "PSS-RED") {
      icon = "/assets/img/substation_constrained.svg";
    }

    let siteClassColor = "/assets/img/red.svg";
    if(gAColl?.SiteClassification == "Amber"){
      siteClassColor = "/assets/img/amber.svg"
    }else if(gAColl?.SiteClassification == "Green"){
      siteClassColor = "/assets/img/green.svg"
    }

    let upstreamColor = "/assets/img/red.svg";
    if(gAColl?.UpstreamStatusDisplayText == "Partially Constrained"){
      upstreamColor = "/assets/img/amber.svg"
    }else if(gAColl?.UpstreamStatusDisplayText == "Unconstrained"){
      upstreamColor = "/assets/img/green.svg"
    }

    let downstreamColor = "/assets/img/red.svg";
    if(gAColl?.DownstreamStatusDisplayText == "Partially Constrained"){
      downstreamColor = "/assets/img/amber.svg"
    }else if(gAColl?.DownstreamStatusDisplayText == "Unconstrained"){
      downstreamColor = "/assets/img/green.svg"
    }

    return (
      <>
        <div className="header" onTouchStart={touchStartEvent => this.props.handleTouchStart(touchStartEvent)} onTouchMove={touchMoveEvent => this.props.handleTouchMove(touchMoveEvent)} onTouchEnd={() => this.props.handleTouchEnd()}>
          <IonButton id="back" size="small" color="tertiary" fill="outline" shape="round" onClick={this.closeGADetails}>
            <IonIcon md={arrowBackSharp} />
            {this.props.fromMapClick ? "List" : "Back"}
          </IonButton>
          <MediaQuery minWidth={640}>
            {(matches: any) =>
              matches
                ?
                <IonButton id="close" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showSideBar}>
                  Close
                  <IonIcon md={closeSharp} />
                </IonButton>
                :
                <IonButton id="close" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showSideBar}>
                  Map
                  <IonIcon style={{ 'marginLeft': 8 }} md={mapSharp} />
                </IonButton>
            }
          </MediaQuery>

          <div className="detailsIconWithButtons">
            <IonIcon id="detailsheader" src={icon} />
          </div>
          <h3 className='detailsTitle'>{gAColl?.Name?.toUpperCase()}</h3>
          <IonGrid key="gADetailsGrid" id="detailsheaderGrid" color="secondary" >
            <IonRow key="detailsRow1">
              <IonCol key='col1row1' className="detailsHeaderLeftColext">
                Location (Lat, Long)
              </IonCol>
              <div className="textSeperator highOpacity"></div>
              <IonCol key='col2row1' className="detailsHeaderRightColText">
                {gALat},{gALong}
              </IonCol>
            </IonRow>
            <IonRow key="detailsRow2">
              <IonCol key='col1row2' className="detailsHeaderLeftColext">
                Minimum Load (MW)
              </IonCol>
              <div className="textSeperator highOpacity"></div>
              <IonCol key='col2row2' className="detailsHeaderRightColText">
                {parseFloat(gAColl?.MinimumLoad).toFixed(2)}
              </IonCol>
            </IonRow>
            <IonRow key="detailsRow3">
              <IonCol key='col1row3' className="detailsHeaderLeftColext">
                Maximum Load (MW)
              </IonCol>
              <div className="textSeperator highOpacity"></div>
              <IonCol key='col2row3' className="detailsHeaderRightColText">
                {parseFloat(gAColl?.MaximumLoad).toFixed(2)}
              </IonCol>
            </IonRow>
            {((gAColl?.ShowContractedGeneratorData === "true") || (gAColl?.ContractedGeneration !== "" && gAColl?.ContractedGeneration !== "undefined" && gAColl?.ContractedGeneration !== undefined)) &&
              <IonRow key="detailsRow4">
                <IonCol key='col1row4' className="detailsHeaderLeftColext">
                  Contracted Generation (MVA)
                </IonCol>
                <div className="textSeperator highOpacity"></div>
                <IonCol key='col2row4' className="detailsHeaderRightColText">
                  {parseFloat(gAColl?.ContractedGeneration).toFixed(2)}
                </IonCol>
              </IonRow>
            }
            {((gAColl?.ShowReversePowerflowCapability === "true") || (gAColl?.ReversePowerflowCapability !== "" && gAColl?.ReversePowerflowCapability !== "undefined")) &&
              <IonRow key="detailsRow5">
                <IonCol key='col1row5' className="detailsHeaderLeftColext">
                  Reverse Powerflow Capacity (%)
                </IonCol>
                <div className="textSeperator highOpacity"></div>
                <IonCol key='col2row5' className="detailsHeaderRightColText">
                  {gAColl?.ReversePowerflowCapability}
                </IonCol>
              </IonRow>
            }
            <IonRow key="detailsRow6">
              <IonCol key='col1row6' className="detailsHeaderLeftColext">
                Sum of Quoted Generation (MVA)
              </IonCol>
              <div className="textSeperator highOpacity"></div>
              <IonCol key='col2row6' className="detailsHeaderRightColText">
                {gAColl?.SumOfQuotedGeneration}
              </IonCol>
            </IonRow>
          </IonGrid>

        </div>

        <div className="content" id="gspDetailsContent">
          <IonList id="gspDetailsList" className="gspDetailsList contentScroll">

            <IonItemDivider onClick={() => {
              stateOfToggles.substationInfo = !this.state.gAToggles.substationInfo;
              this.setState({ gAToggles: stateOfToggles });
            }}>
              {!this.state.gAToggles.substationInfo && <IonIcon md={caretForward} />}
              {this.state.gAToggles.substationInfo && <IonIcon md={caretDown} color="primary" />}
              <IonLabel id="gspDetailsItem">Substation Information</IonLabel>
            </IonItemDivider>
            {this.state.gAToggles.substationInfo &&
              <>
                <IonItem key='subInfo' color="secondary">
                  <IonGrid key='subInfo2' className="gspDetailsGrid">
                    <IonCol key='subInfo3' className="gspDetailsItemText">
                    {gAColl?.SiteClassification !== "undefined" && 
                      <div>
                        <b>Site Classification:</b> 
                        <div id="siteClassification">
                        <IonIcon id="iconClassification" src={siteClassColor}></IonIcon>
                        <IonLabel id="labelClassification">{'' + gAColl?.SiteClassification}</IonLabel>
                        </div>
                      </div>
                      }
                      <div key='subInfoUS'>
                        <b>Upstream Status:</b>
                        <div id="siteClassification">
                        <IonIcon id="iconClassification" src={upstreamColor}></IonIcon>
                        <IonLabel id="labelClassification">{' ' + gAColl?.UpstreamStatusDisplayText}</IonLabel>
                        </div>
                      </div>
                      <div key='subInfoDS'>
                        <b>Downstream Status:</b> 
                        <div id="siteClassification">
                        <IonIcon id="iconClassification" src={downstreamColor}></IonIcon>
                        <IonLabel id="labelClassification">{' ' + gAColl?.DownstreamStatusDisplayText}</IonLabel>
                        </div>
                      </div>

                      <div key='subInfoTNP'>
                        <b>Transformer Nameplate Rating (MVA):</b> {' ' + gAColl?.NameplateRating}
                      </div>
                      {/* {(gAColl?.ReversePowerflowCapability !== "" && gAColl?.ReversePowerflowCapability !== "undefined") &&
                        <div key='subInfoRF'>
                          <b>Reverse Flow Capacity (%):</b> {' ' + gAColl?.ReversePowerflowCapability}
                        </div>} */}
                      {gAColl?.BreakFaultLevelRating !== "NA" &&
                        <div key='subInfoBF'>
                          <b>Break Fault level vs Rating (kA):</b> {' ' + gAColl?.BreakFaultLevelRating}
                        </div>
                      }
                      {gAColl?.ParentGenerationGridSupplyPoint !== "null" && gAColl.ParentGenerationGridSupplyPoint !== "undefined" ?
                        <div key='subInfoGSP' className="correspondingGSP">
                          <b>Corresponding {gAColl?.ParentMarkerType}:</b>
                          {this.props.shouldShowCorrespondingLink ?
                            <a onClick={this.showCorrespoindingGSP}>
                              {' ' + gAColl?.ParentGenerationGridSupplyPoint?.toUpperCase()}
                            </a>
                            :
                            <>{' ' + gAColl?.ParentGenerationGridSupplyPoint?.toUpperCase()}</>
                          }
                        </div> :
                        [gAColl?.ParentName !== "null" && gAColl?.ParentName !== "undefined" &&
                          <div key='subInfoPNGSP' className="correspondingGSP">
                            <b>Corresponding {gAColl?.ParentMarkerType}:</b>
                            {this.props.shouldShowCorrespondingLink ?
                              <a onClick={this.showCorrespoindingGSP}>
                                {' ' + gAColl?.ParentName?.toUpperCase()}
                              </a>
                              :
                              <> {' ' + gAColl?.ParentName?.toUpperCase()}</>
                            }
                          </div>
                        ]
                      }
                      {/* <div>
                        <b>Constortia Count:</b> {' ' + gAColl?.ConsortiaCount}
                      </div> */}
                    </IonCol>
                  </IonGrid>
                </IonItem>
              </>
            }


            <IonItemDivider onClick={() => {
              stateOfToggles.additionalInfo = !this.state.gAToggles.additionalInfo;
              this.setState({ gAToggles: stateOfToggles });
            }}>
              {!this.state.gAToggles.additionalInfo && <IonIcon md={caretForward} />}
              {this.state.gAToggles.additionalInfo && <IonIcon md={caretDown} color="primary" />}
              <IonLabel id="gspDetailsItem">Additional Information</IonLabel>
            </IonItemDivider>
            {this.state.gAToggles.additionalInfo &&
              <>
                <IonItem key='addInfo1' color="secondary">
                  <IonGrid key='addInfo2' className="gspDetailsGrid">
                    <IonCol key='addInfo3' className="gspDetailsItemText">
                      <div>
                        <b>Sow Needed?</b> {' ' + gAColl?.SoWNeeded}
                      </div>
                      <div>
                        <b>Part of SWAN?</b> {' ' + gAColl?.PartOfSwan}
                      </div>
                      <div>
                        <b>Other Constraints:</b> {' ' + gAColl?.OtherConstraints}
                      </div>
                      <div>
                        <b>Voltage (kV)</b> {' ' + gAColl?.Voltage}
                      </div>
                    </IonCol>
                  </IonGrid>
                </IonItem>
              </>
            }

            <IonItemDivider onClick={() => {
              stateOfToggles.registerForConsortia = !this.state.gAToggles.registerForConsortia;
              this.setState({ gAToggles: stateOfToggles });
            }}>
              {!this.state.gAToggles.registerForConsortia && <IonIcon md={caretForward} />}
              {this.state.gAToggles.registerForConsortia && <IonIcon md={caretDown} color="primary" />}
              <IonLabel id="gspDetailsItem">Registration of Consortia</IonLabel>
            </IonItemDivider>
            {this.state.gAToggles.registerForConsortia &&
              <>
                <IonItem color="secondary">
                  <IonLabel className='multiLine'>If you are interested in exploring the potential to set up a consortium to
                    enable developers to share the cost of reinforcement, please <a href="https://www.ssen.co.uk/ConsortiaRegistration/Register/" target="_blank" rel="noopener noreferrer">register</a>.</IonLabel>
                </IonItem>
              </>
            }

          </IonList>
        </div>
      </>
    );
  };
}

export default GASubstationDetails;
