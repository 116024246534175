export function addDataLayer(map, data) {
    try {
        map.addSource('region', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
        });

        map.addLayer({
            'id': 'outline',
            'type': 'fill',
            'source': 'region',
            'layout': {},
            'paint': {
                'fill-color': '#629c49', //green
                'fill-opacity': 0.35
            }
        });

        map.addLayer({
            'id': 'outline-line',
            'type': 'line',
            'source': 'region',
            'layout': {},
            'paint': {
                'line-color': '#fff',
                'line-width': 1
            }
        });
    } catch (error) {
        // console.log("Couldnt add regions");
    }

    try {
        map.addSource('generation', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
            cluster: true,
            clusterMaxZoom: 14, // Max zoom to cluster points on
            clusterRadius: 50
        });

        map.addLayer({
            id: 'generation-clusters',
            type: 'circle',
            source: 'generation',
            filter: ['has', 'point_count'],
            paint: {
                // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
                // with three steps to implement three types of circles:
                //   * Blue, 20px circles when point count is less than 100
                //   * Yellow, 30px circles when point count is between 100 and 750
                //   * Pink, 40px circles when point count is greater than or equal to 750
                'circle-color': [
                    'step',
                    ['get', 'point_count'],
                    '#1c5c99',
                    5,
                    '#145087',
                    20,
                    '#0f4170'
                ],
                'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    20,
                    5,
                    25,
                    20,
                    30
                ],
                "circle-opacity": 0.8,
                "circle-stroke-width": [
                    'step',
                    ['get', 'point_count'],
                    2,
                    5,
                    2.5
                ],
                "circle-stroke-color": "#5B8E9F",
                "circle-stroke-opacity": 1
            }
        });

        map.addLayer({
            id: 'generation-cluster-count',
            type: 'symbol',
            source: 'generation',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 14
            },
            paint: {
                "text-color": "#ffffff"
            }
        });

        map.loadImage(
            '../assets/img/bulk_constrained.png',
            function (error, bulkConstrained) {
                if (error) throw error;

                map.addImage('bulkConstrained', bulkConstrained);

                map.addLayer({
                    id: 'bulk-constrained-layer',
                    "interactive": true,
                    source: 'generation',
                    filter: ["all", ["==", "Classification", "BSP-RED"], ["!has", "point_count"]],
                    type: 'symbol',
                    layout: {
                        'icon-image': 'bulkConstrained',
                        'icon-offset': [0, -25],
                        'icon-padding': 0,
                        'icon-allow-overlap': true,
                        'icon-size': 0.8
                    },
                });
            }
        );
        map.loadImage(
            '../assets/img/bulk_unconstrained.png',
            function (error, bulkUnconstrained) {
                if (error) throw error;

                map.addImage('bulkUnconstrained', bulkUnconstrained);

                map.addLayer({
                    id: 'bulk-unconstrained-layer',
                    "interactive": true,
                    source: 'generation',
                    filter: ["all", ["==", "Classification", "BSP-GREEN"], ["!has", "point_count"]],
                    type: 'symbol',
                    layout: {
                        'icon-image': 'bulkUnconstrained',
                        'icon-offset': [0, -25],
                        'icon-padding': 0,
                        'icon-allow-overlap': true,
                        'icon-size': 0.8
                    },
                });
            }
        );
        map.loadImage(
            '../assets/img/bulk_partial.png',
            function (error, bulkPartialConstrained) {
                if (error) throw error;

                map.addImage('bulkPartialConstrained', bulkPartialConstrained);

                map.addLayer({
                    id: 'bulk-partial-constrained-layer',
                    "interactive": true,
                    source: 'generation',
                    filter: ["all", ["==", "Classification", "BSP-AMBER"], ["!has", "point_count"]],
                    type: 'symbol',
                    layout: {
                        'icon-image': 'bulkPartialConstrained',
                        'icon-offset': [0, -25],
                        'icon-padding': 0,
                        'icon-allow-overlap': true,
                        'icon-size': 0.8
                    },
                });
            }
        );

        map.loadImage(
            '../assets/img/constrained.png',
            function (error, constrained) {
                if (error) throw error;

                map.addImage('constrained', constrained);

                map.addLayer({
                    id: 'constrained-layer',
                    "interactive": true,
                    source: 'generation',
                    filter: ["all", ["==", "Classification", "GSP-RED"], ["!has", "point_count"]],
                    type: 'symbol',
                    layout: {
                        'icon-image': 'constrained',
                        'icon-offset': [0, -25],
                        'icon-padding': 0,
                        'icon-allow-overlap': true,
                        'icon-size': 0.8
                    },
                });
            }
        );
        map.loadImage(
            '../assets/img/unconstrained.png',
            function (error, unconstrained) {
                if (error) throw error;

                map.addImage('unconstrained', unconstrained);

                map.addLayer({
                    id: 'unconstrained-layer',
                    "interactive": true,
                    source: 'generation',
                    filter: ["all", ["==", "Classification", "GSP-GREEN"], ["!has", "point_count"]],
                    type: 'symbol',
                    layout: {
                        'icon-image': 'unconstrained',
                        'icon-offset': [0, -25],
                        'icon-padding': 0,
                        'icon-allow-overlap': true,
                        'icon-size': 0.8
                    },
                });
            }
        );
        map.loadImage(
            '../assets/img/partiallyConstrained.png',
            function (error, partialConstrained) {
                if (error) throw error;

                map.addImage('partialConstrained', partialConstrained);

                map.addLayer({
                    id: 'partial-constrained-layer',
                    "interactive": true,
                    source: 'generation',
                    filter: ["all", ["==", "Classification", "GSP-AMBER"], ["!has", "point_count"]],
                    type: 'symbol',
                    layout: {
                        'icon-image': 'partialConstrained',
                        'icon-offset': [0, -25],
                        'icon-padding': 0,
                        'icon-allow-overlap': true,
                        'icon-size': 0.8
                    },
                });
            }
        );

        map.loadImage(
            '../assets/img/substation_constrained.png',
            function (error, substationConstrained) {
                if (error) throw error;

                map.addImage('substationConstrained', substationConstrained);

                map.addLayer({
                    id: 'substation-constrained-layer',
                    "interactive": true,
                    source: 'generation',
                    filter: ["all", ["==", "Classification", "PSS-RED"], ["!has", "point_count"]],
                    type: 'symbol',
                    layout: {
                        'icon-image': 'substationConstrained',
                        'icon-offset': [0, -25],
                        'icon-padding': 0,
                        'icon-allow-overlap': true,
                        'icon-size': 0.8
                    },
                });
            }
        );
        map.loadImage(
            '../assets/img/substation_unconstrained.png',
            function (error, substationUnconstrained) {
                if (error) throw error;

                map.addImage('substationUnconstrained', substationUnconstrained);

                map.addLayer({
                    id: 'substation-unconstrained-layer',
                    "interactive": true,
                    source: 'generation',
                    filter: ["all", ["==", "Classification", "PSS-GREEN"], ["!has", "point_count"]],
                    type: 'symbol',
                    layout: {
                        'icon-image': 'substationUnconstrained',
                        'icon-offset': [0, -25],
                        'icon-padding': 0,
                        'icon-allow-overlap': true,
                        'icon-size': 0.8
                    },
                });
            }
        );
        map.loadImage(
            '../assets/img/substation_partial.png',
            function (error, substationPartialConstrained) {
                if (error) throw error;

                map.addImage('substationPartialConstrained', substationPartialConstrained);

                map.addLayer({
                    id: 'substation-partial-constrained-layer',
                    "interactive": true,
                    source: 'generation',
                    filter: ["all", ["==", "Classification", "PSS-AMBER"], ["!has", "point_count"]],
                    type: 'symbol',
                    layout: {
                        'icon-image': 'substationPartialConstrained',
                        'icon-offset': [0, -25],
                        'icon-padding': 0,
                        'icon-allow-overlap': true,
                        'icon-size': 0.8
                    },
                });
            }
        );



    } catch (error) {
        console.log("Couldnt add GA");
    }

    try {
        map.addSource('associatedSubstations', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
        });

        map.loadImage(
            '../assets/img/assSubstationConstrained.png',
            function (error, assSubstationConstrained) {
                if (error) throw error;

                map.addImage('assSubstationConstrained', assSubstationConstrained);

                map.addLayer({
                    id: 'ass-substation-constrained-layer',
                    "interactive": true,
                    source: 'associatedSubstations',
                    filter: ["all", ["==", "Classification", "PSS-RED"], ["!has", "point_count"]],
                    type: 'symbol',
                    layout: {
                        'icon-image': 'assSubstationConstrained',
                        'icon-offset': [0, -25],
                        'icon-padding': 0,
                        'icon-allow-overlap': true,
                        'icon-size': 0.8
                    },
                });
            }
        );
        map.loadImage(
            '../assets/img/assSubstationUnconstrained.png',
            function (error, assSubstationUnconstrained) {
                if (error) throw error;

                map.addImage('assSubstationUnconstrained', assSubstationUnconstrained);

                map.addLayer({
                    id: 'ass-substation-unconstrained-layer',
                    "interactive": true,
                    source: 'associatedSubstations',
                    filter: ["all", ["==", "Classification", "PSS-GREEN"], ["!has", "point_count"]],
                    type: 'symbol',
                    layout: {
                        'icon-image': 'assSubstationUnconstrained',
                        'icon-offset': [0, -25],
                        'icon-padding': 0,
                        'icon-allow-overlap': true,
                        'icon-size': 0.8
                    },
                });
            }
        );
        map.loadImage(
            '../assets/img/assSubstationPartiallyConstrained.png',
            function (error, assSubstationPartialConstrained) {
                if (error) throw error;

                map.addImage('assSubstationPartialConstrained', assSubstationPartialConstrained);

                map.addLayer({
                    id: 'ass-substation-partial-constrained-layer',
                    "interactive": true,
                    source: 'associatedSubstations',
                    filter: ["all", ["==", "Classification", "PSS-AMBER"], ["!has", "point_count"]],
                    type: 'symbol',
                    layout: {
                        'icon-image': 'assSubstationPartialConstrained',
                        'icon-offset': [0, -25],
                        'icon-padding': 0,
                        'icon-allow-overlap': true,
                        'icon-size': 0.8
                    },
                });
            }
        );

    } catch (error) {
        console.log("Couldnt add associated Substations");
    }

    try {
        map.addSource('associatedBSPs', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
        });

        map.loadImage(
            '../assets/img/assBulk_constrained.png',
            function (error, assBSPConstrained) {
                if (error) throw error;

                map.addImage('assBSPConstrained', assBSPConstrained);

                map.addLayer({
                    id: 'ass-bsp-constrained-layer',
                    "interactive": true,
                    source: 'associatedBSPs',
                    filter: ["all", ["==", "Classification", "BSP-RED"], ["!has", "point_count"]],
                    type: 'symbol',
                    layout: {
                        'icon-image': 'assBSPConstrained',
                        'icon-offset': [0, -25],
                        'icon-padding': 0,
                        'icon-allow-overlap': true,
                        'icon-size': 0.8
                    },
                });
            }
        );
        map.loadImage(
            '../assets/img/assBulk_unconstrained.png',
            function (error, assBSPUnconstrained) {
                if (error) throw error;

                map.addImage('assBSPUnconstrained', assBSPUnconstrained);

                map.addLayer({
                    id: 'ass-bsp-unconstrained-layer',
                    "interactive": true,
                    source: 'associatedBSPs',
                    filter: ["all", ["==", "Classification", "BSP-GREEN"], ["!has", "point_count"]],
                    type: 'symbol',
                    layout: {
                        'icon-image': 'assBSPUnconstrained',
                        'icon-offset': [0, -25],
                        'icon-padding': 0,
                        'icon-allow-overlap': true,
                        'icon-size': 0.8
                    },
                });
            }
        );
        map.loadImage(
            '../assets/img/assBulk_partiallyConstrained.png',
            function (error, assBSPPartialConstrained) {
                if (error) throw error;

                map.addImage('assBSPPartialConstrained', assBSPPartialConstrained);

                map.addLayer({
                    id: 'ass-bsp-partial-constrained-layer',
                    "interactive": true,
                    source: 'associatedBSPs',
                    filter: ["all", ["==", "Classification", "BSP-AMBER"], ["!has", "point_count"]],
                    type: 'symbol',
                    layout: {
                        'icon-image': 'assBSPPartialConstrained',
                        'icon-offset': [0, -25],
                        'icon-padding': 0,
                        'icon-allow-overlap': true,
                        'icon-size': 0.8
                    },
                });
            }
        );

    } catch (error) {
        console.log("Couldnt add associated BSPS");
    }

    try {
        map.addSource('demand', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
            cluster: true,
            clusterMaxZoom: 14, // Max zoom to cluster points on
            clusterRadius: 50
        });

        map.addLayer({
            id: 'demand-clusters',
            type: 'circle',
            source: 'demand',
            filter: ['has', 'point_count'],
            paint: {
                // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
                // with three steps to implement three types of circles:
                //   * Blue, 20px circles when point count is less than 100
                //   * Yellow, 30px circles when point count is between 100 and 750
                //   * Pink, 40px circles when point count is greater than or equal to 750
                'circle-color': [
                    'step',
                    ['get', 'point_count'],
                    '#1c5c99',
                    5,
                    '#145087',
                    20,
                    '#0f4170'
                ],
                'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    20,
                    5,
                    25,
                    20,
                    30
                ],
                "circle-opacity": 0.8,
                "circle-stroke-width": [
                    'step',
                    ['get', 'point_count'],
                    2,
                    5,
                    2.5
                ],
                "circle-stroke-color": "#5B8E9F",
                "circle-stroke-opacity": 1
            }
        });

        map.addLayer({
            id: 'demand-cluster-count',
            type: 'symbol',
            source: 'demand',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 14
            },
            paint: {
                "text-color": "#ffffff"
            }
        });

        map.loadImage(
            '../assets/img/gsp_demand.png',
            function (error, gspDemand) {
                if (error) throw error;

                map.addImage('gspDemand', gspDemand);

                map.addLayer({
                    id: 'gsp-demand-layer',
                    "interactive": true,
                    source: 'demand',
                    filter: ["all", ["==", "MarkerType", "GSP"], ["!has", "point_count"]],
                    type: 'symbol',
                    layout: {
                        'icon-image': 'gspDemand',
                        'icon-offset': [0, -25],
                        'icon-padding': 0,
                        'icon-allow-overlap': true,
                        'icon-size': 0.8
                    },
                });
            }
        );
        map.loadImage(
            '../assets/img/bulk_demand.png',
            function (error, bulkDemand) {
                if (error) throw error;

                map.addImage('bulkDemand', bulkDemand);

                map.addLayer({
                    id: 'bulk-demand-layer',
                    "interactive": true,
                    source: 'demand',
                    filter: ["all", ["==", "MarkerType", "BSP"], ["!has", "point_count"]],
                    type: 'symbol',
                    layout: {
                        'icon-image': 'bulkDemand',
                        'icon-offset': [0, -25],
                        'icon-padding': 0,
                        'icon-allow-overlap': true,
                        'icon-size': 0.8
                    },
                });
            }
        );
        map.loadImage(
            '../assets/img/substation_demand.png',
            function (error, substationDemand) {
                if (error) throw error;

                map.addImage('substationDemand', substationDemand);

                map.addLayer({
                    id: 'substation-demand-layer',
                    "interactive": true,
                    source: 'demand',
                    filter: ["all", ["==", "MarkerType", "PSS"], ["!has", "point_count"]],
                    type: 'symbol',
                    layout: {
                        'icon-image': 'substationDemand',
                        'icon-offset': [0, -25],
                        'icon-padding': 0,
                        'icon-allow-overlap': true,
                        'icon-size': 0.8
                    },
                });
            }
        );
    } catch (error) {

    }

    try {
        map.addSource('associatedDemandSubstations', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
        });

        map.loadImage(
            '../assets/img/assSubstation_demand.png',
            function (error, assSubstationDemand) {
                if (error) throw error;

                map.addImage('assSubstationDemand', assSubstationDemand);

                map.addLayer({
                    id: 'ass-substation-demand-layer',
                    "interactive": true,
                    source: 'associatedDemandSubstations',
                    filter: ["all", ["==", "MarkerType", "PSS"], ["!has", "point_count"]],
                    type: 'symbol',
                    layout: {
                        'icon-image': 'assSubstationDemand',
                        'icon-offset': [0, -25],
                        'icon-padding': 0,
                        'icon-allow-overlap': true,
                        'icon-size': 0.8
                    },
                });
            }
        );

    } catch (error) {
        // console.log("Couldnt add outages");
    }

    try {
        map.addSource('associatedDemandBSPs', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
        });

        map.loadImage(
            '../assets/img/assBulk_demand.png',
            function (error, assBulkDemand) {
                if (error) throw error;

                map.addImage('assBulkDemand', assBulkDemand);

                map.addLayer({
                    id: 'ass-bulk-demand-layer',
                    "interactive": true,
                    source: 'associatedDemandBSPs',
                    filter: ["all", ["==", "MarkerType", "BSP"], ["!has", "point_count"]],
                    type: 'symbol',
                    layout: {
                        'icon-image': 'assBulkDemand',
                        'icon-offset': [0, -25],
                        'icon-padding': 0,
                        'icon-allow-overlap': true,
                        'icon-size': 0.8
                    },
                });
            }
        );

    } catch (error) {
        // console.log("Couldnt add outages");
    }

    // EV CHARGERS
    try {
        map.addSource('evchargers', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
        });

        map.loadImage('../assets/img/evMapMarker.png', function (error, evIcon) {
            map.addImage('evicon', evIcon)
        })

        map.loadImage('../assets/img/evMapMarkerGrey.png', function (error, evIconGrey) {
            map.addImage('evicongrey', evIconGrey)
        })

        map.addLayer({
            id: 'ev-layer',
            "interactive": true,
            source: 'evchargers',
            type: 'symbol',
            layout: {
                'icon-image': [
                    'match',
                    ['get', 'serviceStatus'],
                    'IN_SERVICE',
                    'evicon',
                    'evicongrey',
                ],
                'icon-padding': 0,
                'icon-allow-overlap': true,
                'icon-size': 0.8
            },
        });


    } catch (error) {
        console.log("Couldnt add ev chargers");
    }

    try {

        map.addSource('correspondingGSP', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
        });
        //lines to corresponding GSP
   

        map.addLayer({
            id: 'corresponding-GSP-2',
            type: 'line',
            source: 'correspondingGSP',
            layout: {
                "line-cap": "butt"
              },
              paint: {
                // "line-color": "#003E66",
                // "line-color": "#5B8E9F",
                "line-color": "white",
                // "line-width": 5,
                "line-width": 4,
              }
        });

        map.addLayer({
            id: 'corresponding-GSP',
            type: 'line',
            source: 'correspondingGSP',
            layout: {
                "line-cap": "square"
              },
              paint: {
                // "line-color": "white",
                // "line-color": "#A677A6",
                // "line-color" : "#EBAC00",
                // "line-color" : "#629C49",
                // "line-color": "#003E66",
                "line-color": "#5B8E9F",
                // "line-width": 2.5,
                "line-width": 2,
              }
        });

       
    } catch (error) {

    }
}