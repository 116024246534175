import React, { useEffect } from 'react';
import { IonButton, IonCol, IonGrid, IonIcon, IonItem, IonItemDivider, IonLabel, IonList, IonRow, IonToggle, isPlatform } from '@ionic/react';
import './FilterPage.css';
import { arrowBackSharp, caretDown, caretForward, closeSharp, funnel, locationSharp, mapOutline, mapSharp } from 'ionicons/icons';
import MediaQuery from 'react-responsive';
import { Feature } from './generationJSONStructure';
import { logScreenView } from '../helpers/firebase';
// import { isCollectingAnalytics } from '../helpers/httpHelper';

type DemandSubstationDetailsProps = {
  feature: any;
  showDetails: any;
  showSideBar: any;
  handleTouchStart: any;
  handleTouchMove: any;
  handleTouchEnd: any;
  showSubstationOnMap: any;
  fromMapClick: any;
  pageLoaded: any;
  showCorrespondingGSP: any;
  shouldShowCorrespondingLink: any;
}

const stateOfToggles = {
  substationInfo: true,
  contactInfo: false,
}


class DemandSubstationDetails extends React.Component<DemandSubstationDetailsProps>{
  // useEffect(() => {
  //    if(isCollectingAnalytics)logScreenView("ptw_outage_filters", "FilterPage_PTW");
  // }, []);

  public state: any;

  constructor(props: any) {
    super(props);
    this.state = {
      demandToggles: {
        substationInfo: true,
        contactInfo: false,
      }
    }
  }

  componentDidMount() {
    logScreenView("odp_nC_substation_details", "NC_Substation_Details_ODP");
  }

  closeGADetails = () => {
    this.props.showDetails();
  }

  showCorrespoindingGSP = () => {
    let thisItem = this.props.feature.properties;
    this.props.showCorrespondingGSP(thisItem?.ParentMarker, false);
  }

  render() {

    let icon = "/assets/img/substation_demand.svg";

    let thisItem = this.props.feature.properties;
    const demandLat = this.props.feature?.geometry?.coordinates[1].toFixed(4);
    const demandLng = this.props.feature?.geometry?.coordinates[0].toFixed(4);

    let transmissionColor = "/assets/img/red.svg";
    if (thisItem?.TransmissionConstraintsDisplayText == "Partially Constrained") {
      transmissionColor = "/assets/img/amber.svg"
    } else if (thisItem?.TransmissionConstraintsDisplayText == "Unconstrained") {
      transmissionColor = "/assets/img/green.svg"
    }

    let distributionColor = "/assets/img/red.svg";
    if (thisItem?.DistributionConstraintsDisplayText == "Partially Constrained") {
      distributionColor = "/assets/img/amber.svg"
    } else if (thisItem?.DistributionConstraintsDisplayText == "Unconstrained") {
      distributionColor = "/assets/img/green.svg"
    }

    return (
      <>
        <div className="header" onTouchStart={touchStartEvent => this.props.handleTouchStart(touchStartEvent)} onTouchMove={touchMoveEvent => this.props.handleTouchMove(touchMoveEvent)} onTouchEnd={() => this.props.handleTouchEnd()}>
          <IonButton id="back" size="small" color="tertiary" fill="outline" shape="round" onClick={this.closeGADetails}>
            <IonIcon md={arrowBackSharp} />
            {this.props.fromMapClick ? "List" : "Back"}
          </IonButton>
          <MediaQuery minWidth={640}>
            {(matches: any) =>
              matches
                ?
                <IonButton id="close" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showSideBar}>
                  Close
                  <IonIcon md={closeSharp} />
                </IonButton>
                :
                <IonButton id="close" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showSideBar}>
                  Map
                  <IonIcon style={{ 'marginLeft': 8 }} md={mapSharp} />
                </IonButton>
            }
          </MediaQuery>

          <div className="detailsIconWithButtons">
            <IonIcon id="detailsheader" src={icon} />
          </div>

          <h3 className='detailsTitle'>{thisItem?.Name?.toUpperCase()}</h3>
          <IonGrid key="demandDetailsGrid" id="detailsheaderGrid" color="secondary" >
            <IonRow key="detailsRow1">
              <IonCol key='col1row1' className="detailsHeaderLeftColext">
                Location (Lat, Long)
              </IonCol>
              <div className="textSeperator highOpacity"></div>
              <IonCol key='col2row1' className="detailsHeaderRightColText">
                {demandLat},{demandLng}
              </IonCol>
            </IonRow>
            <IonRow key="detailsRow2">
              <IonCol key='col1row2' className="detailsHeaderLeftColext">
                Minimum Load (MW)
              </IonCol>
              <div className="textSeperator highOpacity"></div>
              <IonCol key='col2row2' className="detailsHeaderRightColText">
                {parseFloat(thisItem?.MinimumLoad).toFixed(2)}
              </IonCol>
            </IonRow>
            <IonRow key="detailsRow3">
              <IonCol key='col1row3' className="detailsHeaderLeftColext">
                Maximum Load (MW)
              </IonCol>
              <div className="textSeperator highOpacity"></div>
              <IonCol key='col2row3' className="detailsHeaderRightColText">
                {parseFloat(thisItem?.MaximumLoad).toFixed(2)}
              </IonCol>
            </IonRow>
            <IonRow key="detailsRow4">
              <IonCol key='col1row4' className="detailsHeaderLeftColext">
                Transfer Nameplate Rating (MVA)
              </IonCol>
              <div className="textSeperator highOpacity"></div>
              <IonCol key='col2row4' className="detailsHeaderRightColText">
                {thisItem?.NameplateRating}
              </IonCol>
            </IonRow>
          </IonGrid>


        </div>
        <div className="content" id="gspDetailsContent">
          <IonList id="gspDetailsList" className="gspDetailsList contentScroll">
            <IonItemDivider onClick={() => {
              stateOfToggles.substationInfo = !this.state.demandToggles.substationInfo;
              this.setState({ demandToggles: stateOfToggles });
            }}>
              {!this.state.demandToggles.substationInfo && <IonIcon md={caretForward} />}
              {this.state.demandToggles.substationInfo && <IonIcon md={caretDown} color="primary" />}
              <IonLabel id="gspDetailsItem">Substation Information</IonLabel>
            </IonItemDivider>
            {this.state.demandToggles.substationInfo &&
              <>
                <IonItem key='subDInfo' color="secondary">
                  <IonGrid key='subDInfo2' className="gspDetailsGrid">
                    <IonCol key='subDInfo3' className="gspDetailsItemText">
                      <div key='subInfoTS'>
                        <b>Transmission Status:</b>
                        <div id="siteClassification">
                          <IonIcon id="iconClassification" src={transmissionColor}></IonIcon>
                          <IonLabel id="labelClassification">{' ' + thisItem?.TransmissionConstraintsDisplayText}</IonLabel>
                        </div>
                      </div>
                      <div key='subInfoDS'>
                        <b>Distribution Status:</b>
                        <div id="siteClassification">
                          <IonIcon id="iconClassification" src={distributionColor}></IonIcon>
                          <IonLabel id="labelClassification">{' ' + thisItem?.DistributionConstraintsDisplayText}</IonLabel>
                        </div>
                      </div>
                      <div key='subInfoV'>
                        <b>Voltage (kV):</b> {' ' + thisItem?.Voltage}
                      </div>
                      {thisItem?.ShowTransmissionWorksData === "true" && thisItem?.TransmissionWorks !== "" &&
                        <div key='subInfoTW'>
                          <b>Transmission Works:</b> {' ' + thisItem?.TransmissionWorks}
                        </div>}
                      {thisItem?.TransmissionReinforcementCompletionDate !== "" &&
                        <div key='subInfoTR'>
                          <b>Transmission Reinforcement Completion Date:</b> {' ' + thisItem?.TransmissionReinforcementCompletionDate}
                        </div>}
                      {thisItem?.ShowDistributionWorksData === "true" && thisItem?.DistributionReinforcementWorks !== "" &&
                        <div key='subInfoDW'>
                          <b>Distribution Works:</b> {' ' + thisItem?.DistributionReinforcementWorks}
                        </div>}
                      {thisItem?.DistributionReinforcementCompletionDate !== "" && thisItem?.DistributionReinforcementCompletionDate !== "undefined" &&
                        <div key='subInfoDR'>
                          <b>Distribution Reinforcement Completion Date:</b> {' ' + thisItem?.DistributionReinforcementCompletionDate}
                        </div>}
                      {thisItem?.ParentDemandGridSupplyPoint !== "null" && thisItem?.ParentDemandGridSupplyPoint !== "undefined" ?
                        <div key='subDInfoGSP' className="correspondingGSP">
                          <b>Corresponding {thisItem?.ParentMarkerType}:</b>
                          {this.props.shouldShowCorrespondingLink ?
                            <a onClick={this.showCorrespoindingGSP}>
                              {' ' + thisItem?.ParentDemandGridSupplyPoint?.toUpperCase()}
                            </a>
                            :
                            <>
                              {' ' + thisItem?.ParentDemandGridSupplyPoint?.toUpperCase()}
                            </>
                          }
                        </div> :
                        [
                          thisItem?.ParentName !== "null" && thisItem?.ParentName !== "undefined" &&
                          <div key='subDInfoPNGSP' className="correspondingGSP">
                            <b>Corresponding {thisItem?.ParentMarkerType}:</b>
                            {this.props.shouldShowCorrespondingLink ?
                              <a onClick={this.showCorrespoindingGSP}>
                                {' ' + thisItem?.ParentName?.toUpperCase()}
                              </a>
                              :
                              <>
                                {' ' + thisItem?.ParentName?.toUpperCase()}
                              </>
                            }
                          </div>
                        ]
                      }
                    </IonCol>
                  </IonGrid>
                </IonItem>
              </>
            }

            {((thisItem?.ContactData?.Name !== undefined && thisItem?.ContactData?.Name !== "undefined" && thisItem?.ContactData?.Name !== "") ||
              (thisItem?.ContactData?.Title !== undefined && thisItem?.ContactData?.Title !== "undefined" && thisItem?.ContactData?.Title !== "") ||
              (thisItem?.ContactData?.Email !== undefined && thisItem?.ContactData?.Email !== "undefined" && thisItem?.ContactData?.Email !== "") ||
              (thisItem?.ContactData?.Tel !== undefined && thisItem?.ContactData?.Tel !== "undefined" && thisItem?.ContactData?.Tel !== ""))
              &&
              <>
                <IonItemDivider onClick={() => {
                  stateOfToggles.contactInfo = !this.state.demandToggles.contactInfo;
                  this.setState({ demandToggles: stateOfToggles });
                }}>
                  {!this.state.demandToggles.contactInfo && <IonIcon md={caretForward} />}
                  {this.state.demandToggles.contactInfo && <IonIcon md={caretDown} color="primary" />}
                  <IonLabel id="gspDetailsItem">Contact Information</IonLabel>
                </IonItemDivider>
                {this.state.demandToggles.contactInfo &&
                  <>
                    <IonItem color="secondary">
                      <IonGrid className="gspDetailsGrid">
                        <IonCol key='gspCol' className="gspDetailsItemText">
                          {(thisItem?.ContactData?.Name !== undefined && thisItem?.ContactData?.Name !== "undefined" && thisItem?.ContactData?.Name !== "") &&
                            <div>
                              <b>Contact:</b> {' ' + thisItem?.ContactData?.Name}
                            </div>
                          }
                          {(thisItem?.ContactData?.Title !== undefined && thisItem?.ContactData?.Title !== "undefined" && thisItem?.ContactData?.Title !== "") &&
                            <div>
                              <b>Title:</b> {' ' + thisItem?.ContactData?.Title}
                            </div>
                          }
                          {(thisItem?.ContactData?.Email !== undefined && thisItem?.ContactData?.Email !== "undefined" && thisItem?.ContactData?.Email !== "") &&
                            <div>
                              <b>Email:</b> {' ' + thisItem?.ContactData?.Email}
                            </div>
                          }
                          {(thisItem?.ContactData?.Tel !== undefined && thisItem?.ContactData?.Tel !== "undefined" && thisItem?.ContactData?.Tel !== "") &&
                            <div>
                              <b>Tel:</b> {' ' + thisItem?.ContactData?.Tel}
                            </div>
                          }
                        </IonCol>
                      </IonGrid>
                    </IonItem>
                  </>
                }
              </>
            }

          </IonList>
        </div>
      </>
    );
  };
}

export default DemandSubstationDetails;