import React from 'react';
import { IonButton, IonIcon, IonItem, IonItemDivider, IonLabel, IonList } from '@ionic/react';
import './FilterPage.css';
import { arrowBackSharp, caretDown, caretForward, closeSharp, mapSharp } from 'ionicons/icons';
import MediaQuery from 'react-responsive';
import { logScreenView } from '../helpers/firebase';


type AcronymProps = {
    pageLoaded: any;
    showFilter: any;
    showSidebar: any;
    handleTouchStart: any;
    handleTouchMove: any;
    handleTouchEnd: any;
}

class Acronyms extends React.Component<AcronymProps>{

    public state: any;

    constructor(props: any) {
        super(props);
        this.state = {
            acronymToggles: {
                connectionDate: false,
                contact: false,
                contractedCapacity: false,
                faultLevel: false,
                location: false,
                maxLoad: false,
                minLoad: false,
                quotedJob: false,
                quotedGenerators: false,
                gARstatus: false,
                reversePowerflow: false,
                state: false,
                technologyType: false,
                transformerNameplate: false,
                transmissionReinforcement: false,
                transmissionWorks: false,
                voltage: false
            },
        }

    }

    componentDidMount() {
        logScreenView("odp_acronyms", "AcronymPage_ODP");
    }

    render() {

        return (
            <>
                <div className="header" onTouchStart={touchStartEvent => this.props.handleTouchStart(touchStartEvent)} onTouchMove={touchMoveEvent => this.props.handleTouchMove(touchMoveEvent)} onTouchEnd={() => this.props.handleTouchEnd()}>
                    <IonButton id="back" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showFilter}>
                        <IonIcon md={arrowBackSharp} />
                        Back
                    </IonButton>
                    <MediaQuery minWidth={640}>
                        {(matches: any) =>
                            matches
                                ?
                                <IonButton id="close" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showSidebar}>
                                    Close
                                    <IonIcon md={closeSharp} />
                                </IonButton>
                                :
                                <IonButton id="close" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showSidebar}>
                                    Map
                                    <IonIcon style={{ 'marginLeft': 8 }} md={mapSharp} />
                                </IonButton>
                        }
                    </MediaQuery>

                    <div className="titleAcronymWithButtons">
                        <IonLabel id="header">&rdquo;</IonLabel>
                    </div>
                    <h3 className='titleAcronym'>Acronyms</h3>
                </div>

                <div className="content" id="acronymContent">
                    <IonList id="acronymList" className="acronymList contentScroll">
                        <IonItemDivider onClick={() => {
                            this.setState({ acronymToggles: { connectionDate: !this.state.acronymToggles.connectionDate } });
                        }}>
                            {!this.state.acronymToggles.connectionDate && <IonIcon md={caretForward} />}
                            {this.state.acronymToggles.connectionDate && <IonIcon md={caretDown} color="primary" />}
                            <IonLabel id="acronymItem">Connection Date</IonLabel>
                        </IonItemDivider>
                        {this.state.acronymToggles.connectionDate &&
                            <>
                                <IonItem color="secondary">
                                    <IonLabel className='multiLine'>Date the project is due to connect to the network.</IonLabel>
                                </IonItem>
                            </>
                        }

                        <IonItemDivider onClick={() => {
                            this.setState({ acronymToggles: { contact: !this.state.acronymToggles.contact } });
                        }}>
                            {!this.state.acronymToggles.contact && <IonIcon md={caretForward} />}
                            {this.state.acronymToggles.contact && <IonIcon md={caretDown} color="primary" />}
                            <IonLabel id="acronymItem">Contact</IonLabel>
                        </IonItemDivider>
                        {this.state.acronymToggles.contact &&
                            <>
                                <IonItem color="secondary">
                                    <IonLabel className='multiLine'>Local Customer Connection Manager who is available to answer any questions about getting connected</IonLabel>
                                </IonItem>
                            </>
                        }

                        <IonItemDivider onClick={() => {
                            this.setState({ acronymToggles: { contractedCapacity: !this.state.acronymToggles.contractedCapacity } });
                        }}>
                            {!this.state.acronymToggles.contractedCapacity && <IonIcon md={caretForward} />}
                            {this.state.acronymToggles.contractedCapacity && <IonIcon md={caretDown} color="primary" />}
                            <IonLabel id="acronymItem">Contracted Capacity (MVA)</IonLabel>
                        </IonItemDivider>
                        {this.state.acronymToggles.contractedCapacity &&
                            <>
                                <IonItem color="secondary">
                                    <IonLabel className='multiLine'>The amount of capacity the project is contractually allowed to distribute onto the network.</IonLabel>
                                </IonItem>
                            </>
                        }


                        <IonItemDivider onClick={() => {
                            this.setState({ acronymToggles: { faultLevel: !this.state.acronymToggles.faultLevel } });
                        }}>
                            {!this.state.acronymToggles.faultLevel && <IonIcon md={caretForward} />}
                            {this.state.acronymToggles.faultLevel && <IonIcon md={caretDown} color="primary" />}
                            <IonLabel id="acronymItem">Fault Level (kA)</IonLabel>
                        </IonItemDivider>
                        {this.state.acronymToggles.faultLevel &&
                            <>
                                <IonItem color="secondary">
                                    <IonLabel className='multiLine'>This is the measure of the level of energy supplied to a fault on the network. Network plant is rated to withstand a given amount of Fault Level. Distributed Generation contributes energy during fault conditions in addition to the existing Fault level and hence increases it.</IonLabel>
                                </IonItem>
                            </>
                        }


                        <IonItemDivider onClick={() => {
                            this.setState({ acronymToggles: { location: !this.state.acronymToggles.location } });
                        }}>
                            {!this.state.acronymToggles.location && <IonIcon md={caretForward} />}
                            {this.state.acronymToggles.location && <IonIcon md={caretDown} color="primary" />}
                            <IonLabel id="acronymItem">Location (Lat, Long)</IonLabel>
                        </IonItemDivider>
                        {this.state.acronymToggles.location &&
                            <>
                                <IonItem color="secondary">
                                    <IonLabel className='multiLine'>Co-ordinates of the location of the GSP.</IonLabel>
                                </IonItem>
                            </>
                        }


                        <IonItemDivider onClick={() => {
                            this.setState({ acronymToggles: { maxLoad: !this.state.acronymToggles.maxLoad } });
                        }}>
                            {!this.state.acronymToggles.maxLoad && <IonIcon md={caretForward} />}
                            {this.state.acronymToggles.maxLoad && <IonIcon md={caretDown} color="primary" />}
                            <IonLabel id="acronymItem">Maximum Load (MW)</IonLabel>
                        </IonItemDivider>
                        {this.state.acronymToggles.maxLoad &&
                            <>
                                <IonItem color="secondary">
                                    <IonLabel className='multiLine'>The Maximum Load value is the maximum demand on the primary substation after deducting the existing distributed generation.</IonLabel>
                                </IonItem>
                            </>
                        }


                        <IonItemDivider onClick={() => {
                            this.setState({ acronymToggles: { minLoad: !this.state.acronymToggles.minLoad } });
                        }}>
                            {!this.state.acronymToggles.minLoad && <IonIcon md={caretForward} />}
                            {this.state.acronymToggles.minLoad && <IonIcon md={caretDown} color="primary" />}
                            <IonLabel id="acronymItem">Minimum Load (MW)</IonLabel>
                        </IonItemDivider>
                        {this.state.acronymToggles.minLoad &&
                            <>
                                <IonItem color="secondary">
                                    <IonLabel className='multiLine'>The Minimum Load value is the minimum demand left on the primary substation after deducting the existing distributed generation. A negative minimum value indicates the existing amount of reverse power flow already flowing through the primary transformers.</IonLabel>
                                </IonItem>
                            </>
                        }


                        <IonItemDivider onClick={() => {
                            this.setState({ acronymToggles: { quotedJob: !this.state.acronymToggles.quotedJob } });
                        }}>
                            {!this.state.acronymToggles.quotedJob && <IonIcon md={caretForward} />}
                            {this.state.acronymToggles.quotedJob && <IonIcon md={caretDown} color="primary" />}
                            <IonLabel id="acronymItem">Quoted Job</IonLabel>
                        </IonItemDivider>
                        {this.state.acronymToggles.quotedJob &&
                            <>
                                <IonItem color="secondary">
                                    <IonLabel className='multiLine'>All projects currently quoted but not yet accepted.</IonLabel>
                                </IonItem>
                            </>
                        }


                        <IonItemDivider onClick={() => {
                            this.setState({ acronymToggles: { quotedGenerators: !this.state.acronymToggles.quotedGenerators } });
                        }}>
                            {!this.state.acronymToggles.quotedGenerators && <IonIcon md={caretForward} />}
                            {this.state.acronymToggles.quotedGenerators && <IonIcon md={caretDown} color="primary" />}
                            <IonLabel id="acronymItem">Quoted Generators</IonLabel>
                        </IonItemDivider>
                        {this.state.acronymToggles.quotedGenerators &&
                            <>
                                <IonItem color="secondary">
                                    <IonLabel className='multiLine'>All projects currently quoted but not yet accepted.</IonLabel>
                                </IonItem>
                            </>
                        }


                        <IonItemDivider onClick={() => {
                            this.setState({ acronymToggles: { gARstatus: !this.state.acronymToggles.gARstatus } });
                        }}>
                            {!this.state.acronymToggles.gARstatus && <IonIcon md={caretForward} />}
                            {this.state.acronymToggles.gARstatus && <IonIcon md={caretDown} color="primary" />}
                            <IonLabel id="acronymItem">Green/Amber/Red Status</IonLabel>
                        </IonItemDivider>
                        {this.state.acronymToggles.gARstatus &&
                            <>
                                <IonItem color="secondary">
                                    <IonLabel className='multiLine'>Green status shows areas that are unconstrained, Amber status shows areas that are partially constrained, Red status shows areas that are constrained.</IonLabel>
                                </IonItem>
                            </>
                        }


                        <IonItemDivider onClick={() => {
                            this.setState({ acronymToggles: { reversePowerflow: !this.state.acronymToggles.reversePowerflow } });
                        }}>
                            {!this.state.acronymToggles.reversePowerflow && <IonIcon md={caretForward} />}
                            {this.state.acronymToggles.reversePowerflow && <IonIcon md={caretDown} color="primary" />}
                            <IonLabel id="acronymItem">Reverse Powerflow Capacity</IonLabel>
                        </IonItemDivider>
                        {this.state.acronymToggles.reversePowerflow &&
                            <>
                                <IonItem color="secondary">
                                    <IonLabel className='multiLine'>When generation exceeds the local demand on a primary substation, the excess power carries through the primary transformers to the upstream HV/EHV network for use elsewhere on the network. However depending on the condition of the assets and tap changer capability; the actual level of reverse power flow may be restricted to as low 0% of the transformer ratings. If the reverse power flow capability of a primary substation is 0% then the maximum distributed generation which can be connected to the primary substation is only the local demand served by the primary; as the additional power cannot be transferred to the upstream HV/EHV network.</IonLabel>
                                </IonItem>
                            </>
                        }

                        <IonItemDivider onClick={() => {
                            this.setState({ acronymToggles: { state: !this.state.acronymToggles.state } });
                        }}>
                            {!this.state.acronymToggles.state && <IonIcon md={caretForward} />}
                            {this.state.acronymToggles.state && <IonIcon md={caretDown} color="primary" />}
                            <IonLabel id="acronymItem">State</IonLabel>
                        </IonItemDivider>
                        {this.state.acronymToggles.state &&
                            <>
                                <IonItem color="secondary">
                                    <IonLabel className='multiLine'>Describes if the project is connected to the network or if the project is contractually due to connect but has not yet been completed.</IonLabel>
                                </IonItem>
                            </>
                        }

                        <IonItemDivider onClick={() => {
                            this.setState({ acronymToggles: { technologyType: !this.state.acronymToggles.technologyType } });
                        }}>
                            {!this.state.acronymToggles.technologyType && <IonIcon md={caretForward} />}
                            {this.state.acronymToggles.technologyType && <IonIcon md={caretDown} color="primary" />}
                            <IonLabel id="acronymItem">Technology Type</IonLabel>
                        </IonItemDivider>
                        {this.state.acronymToggles.technologyType &&
                            <>
                                <IonItem color="secondary">
                                    <IonLabel className='multiLine'>Name of the connected/contracted project.</IonLabel>
                                </IonItem>
                            </>
                        }

                        <IonItemDivider onClick={() => {
                            this.setState({ acronymToggles: { transformerNameplate: !this.state.acronymToggles.transformerNameplate } });
                        }}>
                            {!this.state.acronymToggles.transformerNameplate && <IonIcon md={caretForward} />}
                            {this.state.acronymToggles.transformerNameplate && <IonIcon md={caretDown} color="primary" />}
                            <IonLabel id="acronymItem">Transformer Nameplate Rating (MVA)</IonLabel>
                        </IonItemDivider>
                        {this.state.acronymToggles.transformerNameplate &&
                            <>
                                <IonItem color="secondary">
                                    <IonLabel className='multiLine'>The Transformer Nameplate Rating is found on the Primary substation and provides three pieces of important information. Firstly, the kVA rating displays the capacity of the transformer. Primary and secondary voltages provide the output voltage with a given input voltage. Lastly, the winding orientation describes how the windings are inter-connected.</IonLabel>
                                </IonItem>
                            </>
                        }

                        <IonItemDivider onClick={() => {
                            this.setState({ acronymToggles: { transmissionReinforcement: !this.state.acronymToggles.transmissionReinforcement } });
                        }}>
                            {!this.state.acronymToggles.transmissionReinforcement && <IonIcon md={caretForward} />}
                            {this.state.acronymToggles.transmissionReinforcement && <IonIcon md={caretDown} color="primary" />}
                            <IonLabel id="acronymItem">Transmission Reinforcement Completion Date</IonLabel>
                        </IonItemDivider>
                        {this.state.acronymToggles.transmissionReinforcement &&
                            <>
                                <IonItem color="secondary">
                                    <IonLabel className='multiLine'>Date that Transmission Reinforcement works are due to be complete.</IonLabel>
                                </IonItem>
                            </>
                        }

                        <IonItemDivider onClick={() => {
                            this.setState({ acronymToggles: { transmissionWorks: !this.state.acronymToggles.transmissionWorks } });
                        }}>
                            {!this.state.acronymToggles.transmissionWorks && <IonIcon md={caretForward} />}
                            {this.state.acronymToggles.transmissionWorks && <IonIcon md={caretDown} color="primary" />}
                            <IonLabel id="acronymItem">Transmission Works</IonLabel>
                        </IonItemDivider>
                        {this.state.acronymToggles.transmissionWorks &&
                            <>
                                <IonItem color="secondary">
                                    <IonLabel className='multiLine'>Details of Transmission construction works that the energisation of the project depends on for connection.</IonLabel>
                                </IonItem>
                            </>
                        }


                        <IonItemDivider onClick={() => {
                            this.setState({ acronymToggles: { voltage: !this.state.acronymToggles.voltage } });
                        }}>
                            {!this.state.acronymToggles.voltage && <IonIcon md={caretForward} />}
                            {this.state.acronymToggles.voltage && <IonIcon md={caretDown} color="primary" />}
                            <IonLabel id="acronymItem">Voltage (kV)</IonLabel>
                        </IonItemDivider>
                        {this.state.acronymToggles.voltage &&
                            <>
                                <IonItem color="secondary">
                                    <IonLabel className='multiLine'>
                                        <ul>
                                            <li>LV – Low Voltage - 230-400volt</li>
                                            <li>HV – High Voltage - 11,000volts</li>
                                            <li>EHV – Extra high Voltage - 33,000volts(SHEPD territory)</li>
                                        </ul>
                                    </IonLabel>
                                </IonItem>
                            </>
                        }

                    </IonList>

                </div>
            </>
        );
    };
}

export default Acronyms;