import { Capacitor } from '@capacitor/core';
import { IonButton, IonCol, IonGrid, IonIcon, IonItem, IonItemDivider, IonLabel, IonList, IonRange, IonRow, IonToast, IonToggle, isPlatform } from '@ionic/react';
import { alertCircleOutline, arrowBackSharp, batteryChargingOutline, cardOutline, caretForwardCircleOutline, carOutline, carSportOutline, cashOutline, chevronForwardCircleOutline, closeSharp, funnel, helpOutline, locationOutline, mapOutline, mapSharp, navigateCircleOutline, navigateOutline, playForwardCircleOutline, settingsOutline, walletOutline } from 'ionicons/icons';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import React from 'react';
import MediaQuery from 'react-responsive';
import { EvFilterState } from '../helpers/EvFilterState';
import { logScreenView } from '../helpers/firebase';

import './Sidebar.css';

interface IEvListProps {
    selectedEvFeature: MapboxGeoJSONFeature;
    clearSelectedEvFeature: any;
    showDetails: any;
    showSideBar: any;
    evFilterState: EvFilterState;
    getEvChargersInMapArea: any;
    evChargerFC: any;
    clearEvChargers: any;
    triggerSelectedEvChargerListItem: any;
    userLocation: any;
    handleTouchStart: any;
    handleTouchMove: any;
    handleTouchEnd: any;
}

interface IEvListState {
    evFilterLoaded?: any;
    evFilterCategoryToggles: any;
    retrievedEvChargerData: boolean;
    evFilterStateChange: boolean;
}

class EvList extends React.Component<IEvListProps, IEvListState> {

    private evFilterState: EvFilterState;

    constructor(props: any) {
        super(props);
        this.evFilterState = this.props.evFilterState;
        this.state = {
            evFilterLoaded: false,
            evFilterCategoryToggles: {
                general: false,
                searchRadius: false,
                connectorType: false,
                chargeSpeed: false,
                chargeNetworks: false,
                paymentPreferences: false,
                locationPreferences: false,
                restrictions: false
            },
            retrievedEvChargerData: false,
            evFilterStateChange: false
        };

    }

    // shouldComponentUpdate(nextProps: any, nextState: any) {
    //     if (this.props.selectedEvFeature !== nextProps.selectedEvFeature) {
    //         return true;
    //     }
    //     return false;
    // }

    componentDidMount() {
        logScreenView("odp_ev_list", "EvList_ODP");
        this.retrieveEvChargerFC();
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        window.requestAnimationFrame(() => {
            if (this.props.selectedEvFeature !== null) {
                setTimeout(() => {
                    let selectedEvChargerProperties = this.props.selectedEvFeature.properties;
                    if (selectedEvChargerProperties !== null) {
                        if (prevProps.evFeature == null || selectedEvChargerProperties.id !== prevProps.evFeature.properties.id) {
                            this.scrollTo(selectedEvChargerProperties.id, true);
                        }
                    }
                }, 50);
            } else if (prevProps.selectedEvFeature !== null) {
                let prevSelectedEvChargerProperties = prevProps.selectedEvFeature.properties;
                if (prevSelectedEvChargerProperties !== null) {
                    this.scrollTo(prevSelectedEvChargerProperties.id, false);
                }
            }
        })
    }

    componentWillUnmount() {
        this.props.clearEvChargers();
    }

    private async retrieveEvChargerFC() {
        let evChargerFC = await this.props.getEvChargersInMapArea();
        if (evChargerFC !== undefined) {
            this.setState(
                {
                    retrievedEvChargerData: true
                }
            );
        } else {
            this.setState(
                {
                    retrievedEvChargerData: true
                }
            );
        }
    }

    private updateEvFilters = () => {
        this.setState({ evFilterStateChange: true })
        this.showEvFilter();
    }

    private showEvFilter = () => {
        if (this.state.evFilterLoaded) {
            this.props.clearSelectedEvFeature();
            this.setState({ retrievedEvChargerData: false });
            this.retrieveEvChargerFC();
            logScreenView("odp_ev_list", "EVList_ODP");
        } else {
            logScreenView("odp_ev_filters", "EvFilters_ODP");
        }

        this.setState({ evFilterLoaded: !this.state.evFilterLoaded });
        //this.forceUpdate();
    }

    private showFilterCategory(category: any) {
        category = !category;
        //this.forceUpdate();
    }

    private scrollTo(listItemId: string, smooth: boolean) {

        let clipSupported = true;
        if (!CSS.supports("overflow", "clip")) {
            clipSupported = false;
            smooth = false;
        }

        let evListItem = document.getElementById(listItemId)
        if (evListItem !== null) {
            smooth ? evListItem.scrollIntoView({ behavior: 'smooth' }) : evListItem.scrollIntoView({ behavior: 'auto' });

            if (!clipSupported) {
                let mainPage = document.getElementById('main');
                mainPage!.scrollTop = 0;
            }
        }
    }

    render() {
        let currentEvFilterState = this.evFilterState.getFilterState();

        var isNative = Capacitor.isNativePlatform();
        var isIos = isPlatform('ios');

        if (this.state.evFilterLoaded) {
            return <>
                <div className="header">
                    <IonButton id="back" size="small" color="tertiary" fill="outline" shape="round" onClick={this.updateEvFilters}>
                        <IonIcon md={arrowBackSharp} /> Back
                    </IonButton>

                    <div className={isNative && isIos ? "ios-iconWithButtons" : "iconWithButtons"}>
                        <IonIcon id="header" md={carSportOutline} />
                    </div>

                    <h3 className='title'>EV Charger Filtering</h3>
                    <h4 className='subtitle'>Please click '<IonIcon id="backArrow" md={arrowBackSharp} />BACK' to apply any filter changes</h4>

                </div>

                <div className="content" id="evFilterContent">
                    <IonList id="evFilteringList" className="evFilteringList contentScroll">
                        <IonItemDivider onClick={() => {
                            this.setState({ evFilterCategoryToggles: { connectorType: !this.state.evFilterCategoryToggles.connectorType } });
                            // this.state.evFilterCategoryToggles.connectorType = !this.state.evFilterCategoryToggles.connectorType;
                            //this.forceUpdate();
                        }}>
                            <IonIcon src="/assets/img/connectors/default.svg" />
                            <IonLabel>Connector Type Preferences</IonLabel>
                        </IonItemDivider>
                        {this.state.evFilterCategoryToggles.connectorType &&
                            <>
                                <IonItem color="secondary">
                                    <IonIcon src="/assets/img/connectors/type2.svg" />
                                    <IonLabel>Type 2</IonLabel>
                                    <IonToggle value="connector-type-two" checked={currentEvFilterState.connectorType.type2} slot="end" color="primary"
                                        onIonChange={event => this.evFilterState.setType2(event.detail.checked)}
                                    />
                                </IonItem>
                                <IonItem color="secondary">
                                    <IonIcon src="/assets/img/connectors/ccs.svg" />
                                    <IonLabel>CCS</IonLabel>
                                    <IonToggle value="connector-ccs" checked={currentEvFilterState.connectorType.ccs} slot="end" color="primary"
                                        onIonChange={event => this.evFilterState.setCCS(event.detail.checked)}
                                    />
                                </IonItem>
                                <IonItem color="secondary">
                                    <IonIcon src="/assets/img/connectors/chademo.svg" />
                                    <IonLabel>CHAdeMO</IonLabel>
                                    <IonToggle value="connector-chademo" checked={currentEvFilterState.connectorType.chademo} slot="end" color="primary"
                                        onIonChange={event => this.evFilterState.setChademo(event.detail.checked)}
                                    />
                                </IonItem>
                                <IonItem color="secondary">
                                    <IonIcon src="/assets/img/connectors/3-pin.svg" />
                                    <IonLabel>3 Pin Socket</IonLabel>
                                    <IonToggle value="connector-three-pin" checked={currentEvFilterState.connectorType.threePin} slot="end" color="primary"
                                        onIonChange={event => this.evFilterState.setThreePin(event.detail.checked)}
                                    />
                                </IonItem>
                                <IonItem color="secondary">
                                    <IonIcon src="/assets/img/connectors/type1.svg" />
                                    <IonLabel>Type 1</IonLabel>
                                    <IonToggle value="connector-type-one" checked={currentEvFilterState.connectorType.type1} slot="end" color="primary"
                                        onIonChange={event => this.evFilterState.setType1(event.detail.checked)}
                                    />
                                </IonItem>
                                <IonItem color="secondary">
                                    <IonIcon src="/assets/img/connectors/other.svg" />
                                    <IonLabel>Other</IonLabel>
                                    <IonToggle value="connector-other" checked={currentEvFilterState.connectorType.other} slot="end" color="primary"
                                        onIonChange={event => this.evFilterState.setConnectorOther(event.detail.checked)}
                                    />
                                </IonItem>
                            </>
                        }
                        <IonItemDivider onClick={() => {
                            this.setState({ evFilterCategoryToggles: { chargeSpeed: !this.state.evFilterCategoryToggles.chargeSpeed } });
                            // this.state.evFilterCategoryToggles.chargeSpeed = !this.state.evFilterCategoryToggles.chargeSpeed;
                            //this.forceUpdate();
                        }}>
                            <IonIcon md={batteryChargingOutline} />
                            <IonLabel>Charge Speed Preferences</IonLabel>
                        </IonItemDivider>
                        {this.state.evFilterCategoryToggles.chargeSpeed &&
                            <>
                                <IonItem color="secondary">
                                    <IonIcon md={chevronForwardCircleOutline} />
                                    <IonLabel>Slow</IonLabel>
                                    <IonToggle value="charge-speed-slow" checked={currentEvFilterState.chargeSpeed.slow} slot="end" color="primary"
                                        onIonChange={event => this.evFilterState.setSlow(event.detail.checked)}
                                    />
                                </IonItem>
                                <IonItem color="secondary">
                                    <IonIcon md={caretForwardCircleOutline} />
                                    <IonLabel>Fast</IonLabel>
                                    <IonToggle value="charge-speed-fast" checked={currentEvFilterState.chargeSpeed.fast} slot="end" color="primary"
                                        onIonChange={event => this.evFilterState.setFast(event.detail.checked)}
                                    />
                                </IonItem>
                                <IonItem color="secondary">
                                    <IonIcon md={playForwardCircleOutline} />
                                    <IonLabel>Rapid</IonLabel>
                                    <IonToggle value="charge-speed-rapid" checked={currentEvFilterState.chargeSpeed.rapid} slot="end" color="primary"
                                        onIonChange={event => this.evFilterState.setRapid(event.detail.checked)}
                                    />
                                </IonItem>
                            </>
                        }
                        <IonItemDivider onClick={() => {
                            this.setState({ evFilterCategoryToggles: { chargeNetworks: !this.state.evFilterCategoryToggles.chargeNetworks } });
                            // this.state.evFilterCategoryToggles.chargeNetworks = !this.state.evFilterCategoryToggles.chargeNetworks;
                            //this.forceUpdate();
                        }}>
                            <IonIcon src="/assets/img/evStation.svg" />
                            <IonLabel>Charge Networks</IonLabel>
                        </IonItemDivider>
                        {this.state.evFilterCategoryToggles.chargeNetworks &&
                            <>
                                {this.evFilterState.getFilterState().networks.map((network: any, id: any) =>
                                    <IonItem key={id} id={id} color="secondary">
                                        <IonLabel>{network.name}</IonLabel>
                                        <IonToggle value={id} checked={network.selected} slot="end" color="primary"
                                            onIonChange={event => this.evFilterState.setChargeNetwork(event.detail.value, event.detail.checked)}
                                        />
                                    </IonItem>
                                )}
                            </>
                        }
                        <IonItemDivider onClick={() => {
                            this.setState({ evFilterCategoryToggles: { paymentPreferences: !this.state.evFilterCategoryToggles.paymentPreferences } });
                            // this.state.evFilterCategoryToggles.paymentPreferences = !this.state.evFilterCategoryToggles.paymentPreferences;
                            //this.forceUpdate();
                        }}>
                            <IonIcon md={walletOutline} />
                            <IonLabel>Payment Preferences</IonLabel>
                        </IonItemDivider>
                        {this.state.evFilterCategoryToggles.paymentPreferences &&
                            <>
                                <IonItem color="secondary">
                                    <IonIcon src="/assets/img/parking.svg" />
                                    <IonLabel>Free Parking</IonLabel>
                                    <IonToggle value="free-parking" checked={currentEvFilterState.paymentDetails.freeParking} slot="end" color="primary"
                                        onIonChange={event => this.evFilterState.setFreeParking(event.detail.checked)}
                                    />
                                </IonItem>
                                <IonItem color="secondary">
                                    <IonIcon md={cardOutline} />
                                    <IonLabel>No Subscription Required</IonLabel>
                                    <IonToggle value="no-subscription" checked={currentEvFilterState.paymentDetails.noSubscription} slot="end" color="primary"
                                        onIonChange={event => this.evFilterState.setNoSubscription(event.detail.checked)}
                                    />
                                </IonItem>
                                <IonItem color="secondary">
                                    <IonIcon md={cashOutline} />
                                    <IonLabel>Free to Use</IonLabel>
                                    <IonToggle value="no-payment" checked={currentEvFilterState.paymentDetails.noPayment} slot="end" color="primary"
                                        onIonChange={event => this.evFilterState.setNoPayment(event.detail.checked)}
                                    />
                                </IonItem>
                            </>
                        }
                        <IonItemDivider onClick={() => {
                            this.setState({ evFilterCategoryToggles: { locationPreferences: !this.state.evFilterCategoryToggles.locationPreferences } });
                            // this.state.evFilterCategoryToggles.locationPreferences = !this.state.evFilterCategoryToggles.locationPreferences;
                            //this.forceUpdate();
                        }}>
                            <IonIcon md={locationOutline} />
                            <IonLabel>Location Preferences</IonLabel>
                        </IonItemDivider>
                        {this.state.evFilterCategoryToggles.locationPreferences &&
                            <>
                                <IonItem color="secondary">
                                    <IonIcon md={carOutline} />
                                    <IonLabel>Public Car Park</IonLabel>
                                    <IonToggle value="public-car-park" checked={currentEvFilterState.locationDetails.publicCarPark} slot="end" color="primary"
                                        onIonChange={event => this.evFilterState.setPublicCarPark(event.detail.checked)}
                                    />
                                </IonItem>
                                <IonItem color="secondary">
                                    <IonIcon src="/assets/img/retail-car-park.svg" />
                                    <IonLabel>Retail Car Park</IonLabel>
                                    <IonToggle value="retail-car-park" checked={currentEvFilterState.locationDetails.retailCarPark} slot="end" color="primary"
                                        onIonChange={event => this.evFilterState.setRetailCarPark(event.detail.checked)}
                                    />
                                </IonItem>
                                <IonItem color="secondary">
                                    <IonIcon src="/assets/img/parking.svg" />
                                    <IonLabel>On Street Parking</IonLabel>
                                    <IonToggle value="parking-on-street" checked={currentEvFilterState.locationDetails.onStreet} slot="end" color="primary"
                                        onIonChange={event => this.evFilterState.setOnStreet(event.detail.checked)}
                                    />
                                </IonItem>
                                <IonItem color="secondary">
                                    <IonIcon md={helpOutline} />
                                    <IonLabel>Other</IonLabel>
                                    <IonToggle value="parking-other" checked={currentEvFilterState.locationDetails.other} slot="end" color="primary"
                                        onIonChange={event => this.evFilterState.setParkingOther(event.detail.checked)}
                                    />
                                </IonItem>
                            </>
                        }
                        <IonItemDivider onClick={() => {
                            this.setState({ evFilterCategoryToggles: { restrictions: !this.state.evFilterCategoryToggles.restrictions } });
                            // this.state.evFilterCategoryToggles.restrictions = !this.state.evFilterCategoryToggles.restrictions;
                            //this.forceUpdate();
                        }}>
                            <IonIcon md={alertCircleOutline} />
                            <IonLabel>Restrictions</IonLabel>
                        </IonItemDivider>
                        {this.state.evFilterCategoryToggles.restrictions &&
                            <>
                                <IonItem color="secondary">
                                    <IonIcon src="/assets/img/restricted.svg" />
                                    <IonLabel>No Physical Restrictions</IonLabel>
                                    <IonToggle value="no-physical-restrictions" checked={currentEvFilterState.restrictions.noPhysicalRestrictions} slot="end" color="primary"
                                        onIonChange={event => this.evFilterState.setNoPhysicalRestrictions(event.detail.checked)}
                                    />
                                </IonItem>
                                <IonItem color="secondary">
                                    <IonIcon src="/assets/img/restricted.svg" />
                                    <IonLabel>No Access Restrictions</IonLabel>
                                    <IonToggle value="no-access-restrictions" checked={currentEvFilterState.restrictions.noAccessRestrictions} slot="end" color="primary"
                                        onIonChange={event => this.evFilterState.setNoAccessRestrictions(event.detail.checked)}
                                    />
                                </IonItem>
                            </>
                        }
                    </IonList>
                    <IonButton id="applyFilters" size="default" color="primary" fill="solid" shape="round" onClick={this.updateEvFilters}>
                        Apply Filters
                    </IonButton>
                </div>
            </>;
        } else {
            return <>
                <div className="header" onTouchStart={touchStartEvent => this.props.handleTouchStart(touchStartEvent)} onTouchMove={touchMoveEvent => this.props.handleTouchMove(touchMoveEvent)} onTouchEnd={() => this.props.handleTouchEnd()}>
                    <IonButton id="back" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showDetails}>
                        <IonIcon md={arrowBackSharp} /> Back
                    </IonButton>
                    <MediaQuery minWidth={640}>
                        {(matches: any) =>
                            matches
                                ?
                                <IonButton id="close" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showSideBar}>
                                    Close
                                    <IonIcon md={closeSharp} />
                                </IonButton>
                                :
                                <IonButton id="close" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showSideBar}>
                                    Map
                                    <IonIcon style={{ 'marginLeft': 8 }} md={mapSharp} />
                                </IonButton>
                        }
                    </MediaQuery>

                    <div className={isNative && isIos ? "ios-iconWithButtons" : "iconWithButtons"}>
                        <IonIcon id="header" md={carSportOutline} />
                    </div>

                    <h3 className='title'>EV Charging</h3>

                    <IonGrid id="headerGrid">
                        <IonRow>
                            <IonCol id="titleColWithLine">Display Limit</IonCol>
                            <IonCol id="titleCol">Nearby Chargers</IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol id="line" className="refCode">250</IonCol>
                            <IonCol id="noLine">{this.props.evChargerFC !== null && this.props.evChargerFC.evChargers.features.length}</IonCol>
                        </IonRow>
                    </IonGrid>
                </div>

                <div className="content">
                    <div className="listHeader">
                        <h5 className="h5">Nearby EV Chargers:</h5>
                        <div className="evbuttons">
                            <IonButton size="small" fill="outline" color="tertiary" shape="round" onClick={this.showEvFilter}>
                                Filter
                                <IonIcon slot="end" md={funnel} />
                            </IonButton>
                        </div>
                    </div>
                    <IonList id="evList" className="evList contentScroll">
                        {this.renderEvDataConditionalStates()}
                    </IonList>
                    <div className="evFooter">
                        <small>EV charge point locator <a target="_blank" rel="noopener noreferrer" href="https://www.ssen.co.uk/ev/terms"><b>Terms & Conditions</b></a>.</small>
                    </div>
                </div>
                <IonToast
                    isOpen={this.state.evFilterStateChange}
                    onDidDismiss={() => this.setState({ evFilterStateChange: false })}
                    // header="EV Filters Applied"
                    message="EV filter settings saved & applied."
                    duration={5000}
                    cssClass='sidebarToast'
                    buttons={[
                        {
                            text: 'Close',
                            role: 'cancel',
                            handler: () => {
                                this.setState({ evFilterStateChange: false })
                            }
                        }
                    ]}
                />
            </>;
        }
    }

    private renderEvDataConditionalStates = () => {
        if (this.state.retrievedEvChargerData && this.props.evChargerFC !== null && this.props.evChargerFC.evChargers.features.length > 0) {
            return <>
                {
                    this.props.evChargerFC.evChargers.features.map(this.renderEvListItem)
                }
            </>
        } else if (!this.state.retrievedEvChargerData) {
            return <>
                <div className="loadingContainer">
                    <IonIcon id="loading" src="/assets/img/loading.svg"></IonIcon>
                    <IonLabel>Fetching EV Chargers...</IonLabel>
                </div>
            </>
        } else {
            return <>
                <div className="loadingContainer">
                    <IonIcon src="/assets/img/listEmpty.svg"></IonIcon>
                    <IonLabel>No EV Chargers to Display</IonLabel>
                    <IonLabel>Try adjusting your filters...</IonLabel>
                    <br />
                    <IonButton size="small" fill="outline" shape="round" onClick={this.showEvFilter}>
                        Adjust Filters
                    </IonButton>
                </div>
            </>
        }
    }

    private renderEvListItem = (evCharger: any, id: any) => {
        return (
            this.props.selectedEvFeature !== null && this.props.selectedEvFeature.properties && evCharger.properties.id === this.props.selectedEvFeature.properties.id ?
                <EvListItem key={id} evCharger={evCharger} selected={true} triggerSelectedEvChargerListItem={(feature: any, allowDeselect: boolean) => this.props.triggerSelectedEvChargerListItem(feature, allowDeselect)} userLocation={this.props.userLocation} showSideBar={() => this.props.showSideBar()} />
                :
                <EvListItem key={id} evCharger={evCharger} selected={false} triggerSelectedEvChargerListItem={(feature: any, allowDeselect: boolean) => this.props.triggerSelectedEvChargerListItem(feature, allowDeselect)} userLocation={this.props.userLocation} showSideBar={() => this.props.showSideBar()} />
        )
    }

}

// EV LIST ITEM COMPONENT

interface IEvListItemProps {
    evCharger: any;
    selected: boolean;
    triggerSelectedEvChargerListItem: any;
    userLocation: any;
    showSideBar: any;
}

interface IEvListItemState {
    itemExpanded: any;
}

class EvListItem extends React.Component<IEvListItemProps, IEvListItemState> {

    constructor(props: any) {
        super(props);
        this.state = {
            itemExpanded: false
        };
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.selected !== prevProps.selected) {
            if (this.props.selected) {
                this.setState({ itemExpanded: true });
            } else {
                this.setState({ itemExpanded: false });
            }
        }
    }

    private triggerSelectedListItem = () => {
        this.props.triggerSelectedEvChargerListItem(this.props.evCharger, true);
    }

    private stopPropagation = (e: any) => {
        e.stopPropagation();
    }

    private showSideBar = (e: any) => {
        e.stopPropagation();
        this.props.showSideBar();
    }

    private calcDistFromUser = () => {
        let lat1 = this.props.evCharger.properties.location.latitude;
        let lon1 = this.props.evCharger.properties.location.longitude;
        let lat2 = this.props.userLocation?.lat;
        let lon2 = this.props.userLocation?.lng;

        if(lat2 === undefined && lon2 === undefined){
            return "-";
        }

        var R = 6371; // Radius of the earth in km
        var dLat = this.degToRad(lat2 - lat1);
        var dLon = this.degToRad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.degToRad(lat1)) * Math.cos(this.degToRad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
            ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        d = d / 1.609; // Distance in miles
        return +d.toFixed(1) + " mi";
    }

    private degToRad = (deg: any) => {
        return deg * (Math.PI / 180)
    }

    private renderPayment = () => {
        let paymentText = this.props.evCharger.properties.paymentDetails;
        let paymentBool = this.props.evCharger.properties.paymentRequired;
        if (paymentText !== undefined && paymentText !== null && paymentText !== "0" && paymentText !== "1") {
            return <>
                <IonLabel class="medium ion-text-wrap">{this.props.evCharger.properties.paymentDetails}</IonLabel>
            </>
        } else if (paymentBool || paymentText === "1") {
            return <>
                <IonLabel class="medium">Yes</IonLabel>
            </>
        } else {
            return <>
                <IonLabel class="medium">None</IonLabel>
            </>
        }
    }

    private renderSubscription = () => {
        let subscriptionText = this.props.evCharger.properties.subscriptionDetails;
        let subscriptionBool = this.props.evCharger.properties.subscriptionRequired;
        if (subscriptionText !== undefined && subscriptionText !== null && subscriptionText !== "0" && subscriptionText !== "1") {
            return <>
                <IonLabel class="medium ion-text-wrap">{subscriptionText}</IonLabel>
            </>
        } else if (subscriptionBool || subscriptionText === "1") {
            return <>
                <IonLabel class="medium">Yes</IonLabel>
            </>
        } else {
            return <>
                <IonLabel class="medium">None</IonLabel>
            </>
        }
    }

    private renderParkingFee = () => {
        let parkingFeeText = this.props.evCharger.properties.parkingFeeDetails;
        let parkingFeeBool = this.props.evCharger.properties.parkingFee;
        if (parkingFeeText !== undefined && parkingFeeText !== null && parkingFeeText !== "0" && parkingFeeText !== "1") {
            return <>
                <IonLabel class="medium ion-text-wrap">{parkingFeeText}</IonLabel>
            </>
        } else if (parkingFeeBool || parkingFeeText === "1") {
            return <>
                <IonLabel class="medium">Parking fee in place</IonLabel>
            </>
        } else {
            return <>
                <IonLabel class="medium">None</IonLabel>
            </>
        }
    }

    private renderConnectors = () => {
        let connectors = this.props.evCharger.properties.Connectors;

        let typeSpeedCombinations: any = [];

        connectors.forEach((connector: any) => {
            let connectorTypeDesc;
            let connectorTypeImg;

            switch (connector.typeDescription) {
                case "Type 2 Mennekes (IEC62196)":
                    connectorTypeDesc = "Type 2"
                    connectorTypeImg = "type2.svg"
                    break;
                case "CCS Type 2 Combo (IEC62196)":
                    connectorTypeDesc = "CCS"
                    connectorTypeImg = "ccs.svg"
                    break;
                case "Type 2 Combo (IEC62196) DC":
                    connectorTypeDesc = "CCS"
                    connectorTypeImg = "ccs.svg"
                    break;
                case "JEVS G105 (CHAdeMO) DC":
                    connectorTypeDesc = "CHAdeMO"
                    connectorTypeImg = "chademo.svg"
                    break;
                case "3-pin Type G (BS1363)":
                    connectorTypeDesc = "Three Pin Plug"
                    connectorTypeImg = "3-pin.svg"
                    break;
                case "Type 1 SAEJ1772 (IEC 62196)":
                    connectorTypeDesc = "Type 1"
                    connectorTypeImg = "type1.svg"
                    break;
                default:
                    connectorTypeDesc = "Other"
                    connectorTypeImg = "other.svg"
                    break;
            }

            let newCombination = true;
            let currentTypeSpeedCombination = { 'type': connector.type, 'typeDesc': connectorTypeDesc, 'typeImg': connectorTypeImg, 'speed': connector.powerOutput, 'count': 1 };

            typeSpeedCombinations.forEach((typeSpeedCombination: any) => {
                if (typeSpeedCombination.type === currentTypeSpeedCombination.type && typeSpeedCombination.speed === currentTypeSpeedCombination.speed) {
                    typeSpeedCombination.count++;
                    newCombination = false;
                    return;
                }
            });

            if (newCombination) {
                typeSpeedCombinations.push(currentTypeSpeedCombination);
            }

        });

        return <>
            {typeSpeedCombinations.map((typeSpeedCombination: any, id: any) =>
                <div key={id} className="connector">
                    <IonIcon src={"/assets/img/connectors/" + typeSpeedCombination.typeImg} />
                    <IonLabel class="medium">{typeSpeedCombination.count + " x " + typeSpeedCombination.typeDesc + " (" + typeSpeedCombination.speed + "kW)"}</IonLabel>
                </div>
            )}
        </>

    }

    private renderAccessRestrictions = () => {
        let accessRestrictionText = this.props.evCharger.properties.accessRestrictionDetails;
        let accessRestrictionBool = this.props.evCharger.properties.accessRestriction;
        if (accessRestrictionText !== undefined && accessRestrictionText !== null && accessRestrictionText !== "0" && accessRestrictionText !== "1") {
            return <>
                <IonLabel class="medium ion-text-wrap">{accessRestrictionText}</IonLabel>
            </>
        } else if (accessRestrictionBool || accessRestrictionText === "1") {
            return <>
                <IonLabel class="medium">Yes</IonLabel>
            </>
        } else {
            return <>
                <IonLabel class="medium">None</IonLabel>
            </>
        }
    }

    private renderPhysicalRestrictions = () => {
        let physicalRestrictionText = this.props.evCharger.properties.physicalRestrictionDetails;
        let physicalRestrictionBool = this.props.evCharger.properties.physicalRestriction;
        if (physicalRestrictionText !== undefined && physicalRestrictionText !== null && physicalRestrictionText !== "0" && physicalRestrictionText !== "1") {
            return <>
                <IonLabel class="medium ion-text-wrap">{this.props.evCharger.properties.physicalRestrictionDetails}</IonLabel>
            </>
        } else if (physicalRestrictionBool || physicalRestrictionText === "1") {
            return <>
                <IonLabel class="medium">Yes</IonLabel>
            </>
        } else {
            return <>
                <IonLabel class="medium">None</IonLabel>
            </>
        }
    }

    render() {
        return <>
            <IonItem id={this.props.evCharger.properties.id} className={this.state.itemExpanded ? "selected" : ""} onClick={this.triggerSelectedListItem} lines="none">
                <IonGrid>
                    <IonRow>
                        <IonCol size="2.3" id="availabilityCol">
                            {this.props.evCharger.properties.serviceStatus === "IN_SERVICE" ?
                                <>
                                    <IonIcon src="/assets/img/evChargerAvailable.svg" />
                                    <IonLabel>Available</IonLabel>
                                </>
                                :
                                <>
                                    <IonIcon src="/assets/img/evChargerUnavailable.svg" />
                                    <IonLabel>Unavailable</IonLabel>
                                </>
                            }
                        </IonCol>
                        <IonCol size="8" id="chargerDetailsCol">
                            <IonLabel id='chargerName'>{this.props.evCharger.properties.name}</IonLabel>
                            <IonLabel id='chargerNetwork'>{this.props.evCharger.properties.network}</IonLabel>
                        </IonCol>
                        <IonCol size="1.7" id="distanceCol">
                            <div>
                                <IonIcon src="/assets/img/distance.svg" />
                                <IonLabel>Distance</IonLabel>
                                <IonLabel>{this.calcDistFromUser()}</IonLabel>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow className={"expandedSection contained " + (this.state.itemExpanded ? "expanded" : "collapsed")}>
                        {this.state.itemExpanded &&
                            <IonCol>
                                <div className="contained">
                                    <IonLabel class="title">Payment</IonLabel>
                                    <IonLabel class="large">Payment: </IonLabel>
                                    {this.renderPayment()}
                                    <IonLabel class="large">Subscription: </IonLabel>
                                    {this.renderSubscription()}
                                    <IonLabel class="large">Parking Fee: </IonLabel>
                                    {this.renderParkingFee()}
                                </div>
                                <div className="contained">
                                    <IonLabel class="title">Connectors</IonLabel>
                                    {this.renderConnectors()}
                                </div>
                                <div className="contained">
                                    <IonLabel class="title">Restrictions</IonLabel>
                                    <IonLabel class="large">Access Restrictions: </IonLabel>
                                    {this.renderAccessRestrictions()}
                                    <IonLabel class="large">Physical Restrictions: </IonLabel>
                                    {this.renderPhysicalRestrictions()}
                                    <IonLabel class="large">Location: </IonLabel>
                                    <IonLabel class="medium">{this.props.evCharger.properties.location.type}</IonLabel>
                                </div>
                                <div className="contained">
                                    <IonLabel class="title">Source</IonLabel>
                                    <IonLabel class="large">Device Owner:</IonLabel>
                                    <IonLabel class="medium ion-text-wrap">{this.props.evCharger.properties.Controller.name}</IonLabel>
                                    <IonLabel class="large">Owner Telephone:</IonLabel>
                                    <IonLabel class="medium" onClick={this.stopPropagation}>
                                        <a href={"tel:" + this.props.evCharger.properties.Controller.telephone}>{this.props.evCharger.properties.Controller.telephone}</a>
                                    </IonLabel>
                                    <IonLabel class="large">Owner Website:</IonLabel>
                                    <IonLabel class="medium ion-text-wrap" onClick={this.stopPropagation}>
                                        <a target="_blank" rel="noopener noreferrer" href={this.props.evCharger.properties.Controller.website}>{this.props.evCharger.properties.Controller.website}</a>
                                    </IonLabel>
                                    <IonLabel class="large">Attribution:</IonLabel>
                                    <IonLabel class="medium ion-text-wrap">{this.props.evCharger.properties.attribution}</IonLabel>
                                    <IonLabel class="large">Data Provider:</IonLabel>
                                    <IonLabel class="medium ion-text-wrap">National Charge Point Registry</IonLabel>

                                </div>
                                <IonButton
                                    className="evChargerButton"
                                    shape="round"
                                    href={"https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=" + this.props.evCharger.properties.location.latitude + "," + this.props.evCharger.properties.location.longitude}
                                    target="_blank" rel="noopener noreferrer"
                                    onClick={this.stopPropagation}
                                >
                                    <IonIcon slot="start" icon={navigateOutline} />
                                    Navigate to Charger
                                </IonButton>
                                <IonButton shape="round" className="evChargerButton hideOnDesktop" target="_blank" rel="noopener noreferrer" onClick={this.showSideBar}>
                                    <IonIcon slot="start" icon={mapOutline} />
                                    View charger on Map
                                </IonButton>
                            </IonCol>
                        }
                    </IonRow>
                </IonGrid>
            </IonItem>
        </>;
    }
}

export default EvList;
