import { IonButton, IonIcon, IonGrid, IonRow, IonCol, IonSegment, IonSegmentButton, IonLabel, IonList, IonItemDivider, IonToggle } from '@ionic/react';
import { arrowBackSharp, caretDown, caretForward, closeSharp, cloudOfflineOutline, flash, funnel, helpCircleSharp, mapSharp, menuSharp, swapVerticalSharp } from 'ionicons/icons';
import React from 'react';
import MediaQuery from 'react-responsive';
import { logScreenView } from '../helpers/firebase';
import { DemandCollection, Feature } from './demandJSONStructure';
import { FetchState } from './generationJSONStructure';
import './Sidebar.css';

type NetworkCapacityProps = {
  demandJSON: any;
  showSidebar: any;
  showFilter: any;
  sortByTime: any;
  fetchState: any;
  sortAtoZ: any;
  showNetworkCapacity: any;
  showGenerationAvailability: any;
  handleTouchStart: any;
  handleTouchMove: any;
  handleTouchEnd: any;
  showDemandDetails: any;
  showSubstationDetails: any;
  northNCOn: any;
  filterNCNorth: any;
  southNCOn: any;
  filterNCSouth: any;
  nCToggles: any;
  setNCToggles: any;
  toggleLicences: any;
  toggleContactUs: any;
}

function displayGSPData(fC: DemandCollection, sortAtoZ: boolean, showDetails: any, showSubstationDetails: any, region: any) {

  if (fC !== undefined) {
    var allGSP: Feature[] = fC.gsp.features;

    let gsps: Feature[] = [];
    if (region === "south") {
      gsps = allGSP.filter((p: { properties: any; }) => p.properties.Area === "England");
    } else {
      gsps = allGSP.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
    }

    let sortedGSP: Feature[] = [];
    if (sortAtoZ) {
      sortedGSP = gsps.sort((a, b) => a?.properties?.Name.localeCompare(b?.properties?.Name));
    } else {
      sortedGSP = gsps.sort((a, b) => b?.properties?.Name.localeCompare(a?.properties?.Name));
    }

    return (
      drawTable(sortedGSP, showDetails, showSubstationDetails, "grid supply points")
    );
  }
}

function displayBSPData(fC: DemandCollection, sortAtoZ: boolean, showDetails: any, showSubstationDetails: any, region: any) {

  if (fC !== undefined) {
    var allBSP: Feature[] = fC.bsp.features;

    let bsps: Feature[] = [];
    if (region === "south") {
      bsps = allBSP.filter((p: { properties: any; }) => p.properties.Area === "England");
    } else {
      bsps = allBSP.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
    }

    let sortedBSP: Feature[] = [];
    if (sortAtoZ) {
      sortedBSP = bsps.sort((a, b) => a?.properties?.Name.localeCompare(b?.properties?.Name));
    } else {
      sortedBSP = bsps.sort((a, b) => b?.properties?.Name.localeCompare(a?.properties?.Name));
    }

    return (
      drawTable(sortedBSP, showDetails, showSubstationDetails, "bulk supply points")
    );
  }
}

function displaySubstationData(fC: DemandCollection, sortAtoZ: boolean, showDetails: any, showSubstationDetails: any, region: any) {

  if (fC !== undefined) {
    var allPSS: Feature[] = fC.pss.features;

    let pss: Feature[] = [];
    if (region === "south") {
      pss = allPSS.filter((p: { properties: any; }) => p.properties.Area === "England");
    } else {
      pss = allPSS.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
    }

    let sortedPSS: Feature[] = [];
    if (sortAtoZ) {
      sortedPSS = pss.sort((a, b) => a?.properties?.Name.localeCompare(b?.properties?.Name));
    } else {
      sortedPSS = pss.sort((a, b) => b?.properties?.Name.localeCompare(a?.properties?.Name));
    }

    return (
      drawTable(sortedPSS, showDetails, showSubstationDetails, "substations")
    );
  }
}

function drawTable(fs: Feature[], showDetails: any, showSubstationDetails: any, typeText: any) {

  if (fs !== undefined && fs.length > 0) {
    return fs.map((genData, index) => {
      if (genData?.geometry?.type === "Point") {

        let classificationText = genData?.properties?.MarkerType;
        let icon = "/assets/img/gsp_demand.svg";
        let isSubstation = false;

        if (classificationText === "BSP") {
          icon = "/assets/img/bulk_demand.svg";
        } else if (classificationText === "PSS") {
          icon = "/assets/img/substation_demand.svg";
          isSubstation = true;
        }

        return (
          <IonRow key={'row' + index} onClick={isSubstation ? () => showSubstationDetails(genData, true) : () => showDetails(genData)} >
            <IonCol size='30px' key={index + 'col1'}>
              <IonRow>
                <IonIcon id="GSPIcon" src={icon} />
              </IonRow>
            </IonCol>
            <IonCol key={index + 'col2'} className="dataListItemText">
              <div>
                <b id="GSPName">{genData.properties.Name?.toUpperCase()}</b>
              </div>
              <div>
                <b>Transmission Status:</b> {' ' + genData.properties.TransmissionConstraintsDisplayText}
              </div>
              <div>
                <b>Distribution Status:</b> {' ' + genData.properties.DistributionConstraintsDisplayText}
              </div>
              <div>
                <b>Voltage (kV):</b> {' ' + genData.properties.Voltage}
              </div>
              {classificationText === "BSP" ?
                <div>
                  <b>Corresponding {genData?.properties?.ParentMarkerType}:</b> {' ' + genData.properties.ParentDemandGridSupplyPoint}
                </div>
                :
                <></>}
            </IonCol>
          </IonRow>
        );
      } else {
        // return an empty fragment for the areas to supress warning of no turn in arrow function
        return (<React.Fragment key={"emptySummary" + index}></React.Fragment>);
      }

    });
  } else {
    return (
      <h2 id="noData">Please adjust filters to view {typeText}</h2>
    );
  }
}

function dataLoading() {
  return (<IonList color="secondary" id='info' className="dataUnavailable" >
    <IonIcon id="loading" src="/assets/img/loading.svg"></IonIcon>
    <h4>Retrieving Data</h4>
    <p>Please Wait...</p>
  </IonList>);
}

function dataUnavailable() {
  return (<IonList color="secondary" id='info' className="dataUnavailable" >
    <IonIcon id="offline" src={cloudOfflineOutline}></IonIcon>
    <h4>Data unavailable</h4>
    <p>The data server is currently unavailable, please try again later.</p>
  </IonList>);
}

function noDataToShow() {
  return (<IonList color="secondary" id='info' className="dataUnavailable" >
    <IonIcon id="offline" src={flash}></IonIcon>
    <h4>No Data to Show</h4>
    <p>Please try adjusting your filter settings</p>
  </IonList>);
}


class NetworkCapacity extends React.Component<NetworkCapacityProps> {

  public state: any;

  constructor(props: any) {
    super(props);
    this.state = {
      nCToggles: this.props.nCToggles
    }
  }

  private codeSnip: any;

  chooseDisplay(e: any) {
    e.stopPropagation();
    let type: any = e.detail.value;
    if (e.detail.value == "networkCapacity") {
      this.props.showNetworkCapacity();
    } else {
      this.props.showGenerationAvailability(e);
    }

  }

  componentDidMount() {
    logScreenView("odp_nC_summary", "NC_Summary_ODP");
  }

  // disabled={this.props.fetchState !== FetchState.SUCCESS}

  render() {

    let demandCol: DemandCollection = this.props.demandJSON as DemandCollection;

    let totalGSPS = 0;
    let totalBSPS = 0;
    let totalPSS = 0;

    if (this.props.fetchState === FetchState.IDLE || this.props.fetchState === FetchState.FETCHING) {
      this.codeSnip = dataLoading();
    } else if (this.props.fetchState === FetchState.FAILED) {
      this.codeSnip = dataUnavailable();
    } else if (this.props.fetchState === FetchState.SUCCESS) {
      if (demandCol !== undefined) {

        totalGSPS = demandCol.gsp.features.length;
        totalBSPS = demandCol.bsp.features.length;
        totalPSS = demandCol.pss.features.length;

        let totalGSPsToShow = totalGSPS + totalBSPS + totalPSS;

        if (totalGSPsToShow === 0) {
          this.codeSnip = noDataToShow();
        } else {
          this.codeSnip = null;
        }
      } else {
        this.codeSnip = noDataToShow();
      }
    }

    return (<>
      <div className="header" onTouchStart={touchStartEvent => this.props.handleTouchStart(touchStartEvent)} onTouchMove={touchMoveEvent => this.props.handleTouchMove(touchMoveEvent)} onTouchEnd={() => this.props.handleTouchEnd()}>
        <MediaQuery maxWidth={640}>
          <IonButton id="backHidden" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showSidebar}>
            <IonIcon md={arrowBackSharp} /> Back
          </IonButton>
        </MediaQuery>

        <MediaQuery minWidth={640}>
          {(matches: any) =>
            matches
              ?
              <IonButton id="close" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showSidebar}>
                Close
                <IonIcon md={closeSharp} />
              </IonButton>
              :
              <IonButton id="close" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showSidebar}>
                Map
                <IonIcon style={{ 'marginLeft': 8 }} md={mapSharp} />
              </IonButton>
          }
        </MediaQuery>

        <div className="icon">
          <IonIcon id="header" md={menuSharp} />
        </div>

        <h3 className='title'>Network Capacity</h3>
        <IonGrid id="headerGrid" key="grid1">
          <IonRow key="1row1">
            <IonCol id="titleColWithLine" key="1column1">
              <b>Grid Supply Points</b>
            </IonCol>
            <IonCol id="titleColWithLine" key="1column2">
              <b>Bulk Supply Points</b>
            </IonCol>
            <IonCol id="titleCol" key="1column3">
              <b>Substations</b>
            </IonCol>
          </IonRow>

          <IonRow key="1row2">
            <IonCol id="line" key="2column1">
              {totalGSPS > 0 ? totalGSPS : '-'}
            </IonCol>
            <IonCol id="line" key="2column2">
              {totalBSPS > 0 ? totalBSPS : '-'}
            </IonCol>
            <IonCol id="noline" key="2column3">
              {totalPSS > 0 ? totalPSS : '-'}
            </IonCol>
          </IonRow>
        </IonGrid>

      </div>

      <div className="content">
        <div className="generationListHeader">

          <IonSegment mode="md" onIonChange={(e) => this.chooseDisplay(e)} value="networkCapacity">
            <IonSegmentButton mode="md" value="generationAvailability" >
              <IonLabel id="segmented">Generation Availability</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton mode="md" value="networkCapacity">
              <IonLabel id="segmented">Network Capacity</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          {/* <h5 className="h5" id="gspTitle">Grid Supply Points:</h5> */}
          <div className="filterSortbuttons">
            <IonButton size="small" color="tertiary" fill="outline" shape="round" onClick={() => window.open('https://www.ssen.co.uk/globalassets/library/connections---useful-documents/connection-flowcharts-and-guides/generation-availiability-and-network-capacity-user-guide-.pdf', '_blank')} >
              Need Help
              <IonIcon slot="end" md={helpCircleSharp} style={{ 'paddingBottom': 2 }} />
            </IonButton>
            <IonButton size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showFilter} disabled={this.props.fetchState !== FetchState.SUCCESS} >
              Filter
              <IonIcon slot="end" md={funnel} />
            </IonButton>
            <IonButton id="sort-by" color="tertiary" size="small" fill="outline" shape="round" onClick={this.props.sortByTime} disabled={this.props.fetchState !== FetchState.SUCCESS}>
              {this.props.sortAtoZ ? "A to Z" : "Z to A"}
              <IonIcon slot="end" md={swapVerticalSharp} />
            </IonButton>
          </div>
        </div>

        {this.codeSnip !== null ?
          this.codeSnip
          :
          <IonList id="gspDetailsList" className="gspDetailsList contentScroll">
            <IonGrid key="grid2" className="dataList" color="secondary" >

              <div id="areaFilterItem" className="shadedItem" >
                <IonLabel id="gspDetailsItem">Scotland</IonLabel>
                <IonToggle id="filterTog" checked={this.props.northNCOn} onIonChange={this.props.filterNCNorth} />
              </div>

              {this.props.northNCOn &&
                <>
                  <IonItemDivider onClick={() => {
                    this.state.nCToggles.gspNorth = !this.state.nCToggles.gspNorth;
                    this.props.setNCToggles(this.state.nCToggles);
                  }}>
                    {!this.state.nCToggles.gspNorth && <IonIcon md={caretForward} />}
                    {this.state.nCToggles.gspNorth && <IonIcon md={caretDown} color="primary" />}
                    <IonLabel id="gspDetailsItem">Grid Supply Points</IonLabel>
                  </IonItemDivider>
                  {this.state.nCToggles.gspNorth &&
                    <>
                      {displayGSPData(demandCol, this.props.sortAtoZ, this.props.showDemandDetails, this.props.showSubstationDetails, "north")}
                    </>
                  }
                  {/* <IonItemDivider onClick={() => {
                stateOfToggles.bspNorth = !this.state.gAToggles.bspNorth;
                this.setState({ gAToggles: stateOfToggles });
              }}>
                {!this.state.gAToggles.bspNorth && <IonIcon md={caretForward} />}
                {this.state.gAToggles.bspNorth && <IonIcon md={caretDown} color="primary" />}
                <IonLabel id="gspDetailsItem">Bulk Supply Points</IonLabel>
              </IonItemDivider>
              {this.state.gAToggles.bspNorth &&
                <>
                  {displayBSPData(demandCol, this.props.sortAtoZ, this.props.showDemandDetails, this.props.showSubstationDetails)}
                </>
              } */}
                  <IonItemDivider onClick={() => {
                    this.state.nCToggles.pssNorth = !this.state.nCToggles.pssNorth;
                    this.props.setNCToggles(this.state.nCToggles);
                  }}>
                    {!this.state.nCToggles.pssNorth && <IonIcon md={caretForward} />}
                    {this.state.nCToggles.pssNorth && <IonIcon md={caretDown} color="primary" />}
                    <IonLabel id="gspDetailsItem">Substations</IonLabel>
                  </IonItemDivider>
                  {this.state.nCToggles.pssNorth &&
                    <>
                      {displaySubstationData(demandCol, this.props.sortAtoZ, this.props.showDemandDetails, this.props.showSubstationDetails, "north")}
                    </>
                  }

                </>
              }

              <div id="areaFilterItem" className="shadedItem" >
                <IonLabel id="gspDetailsItem">England</IonLabel>
                <IonToggle id="filterTog" checked={this.props.southNCOn} onIonChange={this.props.filterNCSouth} />
              </div>

              {this.props.southNCOn &&
                <>
                  <IonItemDivider onClick={() => {
                    this.state.nCToggles.gspSouth = !this.state.nCToggles.gspSouth;
                    this.props.setNCToggles(this.state.nCToggles);
                  }}>
                    {!this.state.nCToggles.gspSouth && <IonIcon md={caretForward} />}
                    {this.state.nCToggles.gspSouth && <IonIcon md={caretDown} color="primary" />}
                    <IonLabel id="gspDetailsItem">Grid Supply Points</IonLabel>
                  </IonItemDivider>
                  {this.state.nCToggles.gspSouth &&
                    <>
                      {displayGSPData(demandCol, this.props.sortAtoZ, this.props.showDemandDetails, this.props.showSubstationDetails, "south")}
                    </>
                  }
                  <IonItemDivider onClick={() => {
                    this.state.nCToggles.bspSouth = !this.state.nCToggles.bspSouth;
                    this.props.setNCToggles(this.state.nCToggles);
                  }}>
                    {!this.state.nCToggles.bspSouth && <IonIcon md={caretForward} />}
                    {this.state.nCToggles.bspSouth && <IonIcon md={caretDown} color="primary" />}
                    <IonLabel id="gspDetailsItem">Bulk Supply Points</IonLabel>
                  </IonItemDivider>
                  {this.state.nCToggles.bspSouth &&
                    <>
                      {displayBSPData(demandCol, this.props.sortAtoZ, this.props.showDemandDetails, this.props.showSubstationDetails, "south")}
                    </>
                  }
                  <IonItemDivider onClick={() => {
                    this.state.nCToggles.pssSouth = !this.state.nCToggles.pssSouth;
                    this.props.setNCToggles(this.state.nCToggles);
                  }}>
                    {!this.state.nCToggles.pssSouth && <IonIcon md={caretForward} />}
                    {this.state.nCToggles.pssSouth && <IonIcon md={caretDown} color="primary" />}
                    <IonLabel id="gspDetailsItem">Substations</IonLabel>
                  </IonItemDivider>
                  {this.state.nCToggles.pssSouth &&
                    <>
                      {displaySubstationData(demandCol, this.props.sortAtoZ, this.props.showDemandDetails, this.props.showSubstationDetails, "south")}
                    </>
                  }

                </>
              }

            </IonGrid>
          </IonList>
        }

        <div className="policyFooter">
          <small><a target="_blank" rel="noopener noreferrer" href="https://www.ssen.co.uk/PrivacyNotice"><b>Privacy Policy</b></a> | <a target="_blank" rel="noopener noreferrer" href="https://www.ssen.co.uk/Cookies"><b>Cookie Notice</b></a> | <button className="linkButton" onClick={() => { this.props.toggleLicences(true); }}><b>Licences</b></button> | <button className="linkButton" onClick={() => { this.props.toggleContactUs(true); }}><b>Contact Us</b></button></small>
        </div>
      </div>
    </>);
  }
}

export default NetworkCapacity;