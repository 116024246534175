import { AppPreferences } from "../preferences/preferences";

/////////////////////// MASTER DOMAIN /////////////////////////////

  export var domain: string;

  if (AppPreferences.getAppPreferences().preferences.devPrefs.general.buildConfig!.production) {
    // LIVE SERVER //
    domain = AppPreferences.getAppPreferences().preferences.devPrefs.general.serverEndpoints!.production!.domain as string;
    AppPreferences.getAppPreferences().preferences.devPrefs.general.buildConfig!.collectAnalytics = true;
  } else {
    // TESTING SERVER //
    domain = AppPreferences.getAppPreferences().preferences.devPrefs.general.serverEndpoints!.development!.domain as string;
  }

///////////////////////////////////////////////////////////////////

export var generationURL = domain + "/generation-generated.json";
export var demandURL = domain + "/demand-generated.json";
export var infoURL = domain + "/ssen-areas.json";

export interface initialHandshakeResponse {
  UUID: string;
  processingTime: number;
  serverProtocolVersion: {message:string, title:string};
  status :string;
}

export interface getLocationResponse {
  UUID: string;
  processingTime: number;
  serverProtocolVersion: {message:string, title:string};
  status :string;
  latitude:number;
  longitude:number;
  locationStatus:string;
  network:string;
}

export interface verifyLocationResponse {
  UUID: string;
  processingTime: number;
  serverProtocolVersion: {message:string, title:string};
  status :string;
  userReferenceCode:string;
}

export interface geoLookupResponse {
  features: [];
}

export interface submitReportResponse {
  UUID: string;
  processingTime: number;
  serverProtocolVersion: {message:string, title:string};
  status :string;
  commitTime:string;
}

export interface dataResponse {
  data:string; //TODO: Jazz this up
}

export interface evChargerResponse {
  chargers:string; //TODO: Jazz this up
}

export interface HttpResponse<T> extends Response {
  parsedBody?: T;
}

export async function httpWithLastModified<T>(request: RequestInfo, lastModified: string, timeout: number): Promise<HttpResponse<T>> {

  const headers = new Headers();
  headers.set('If-Modified-Since', lastModified);

  const response: HttpResponse<T> = await fetchWithOptions(request, {
    method: 'GET',
    timeout: timeout,
    headers: headers
  });

  return response;
}

export async function http<T>(request: RequestInfo, timeout: number): Promise<HttpResponse<T>> {

  const response: HttpResponse<T> = await fetchWithOptions(request, {timeout: timeout});
  
  return response;
}

export async function get<T>(
  path: string,
  args: RequestInit = { method: "GET" , headers: {
    },
  },
  timeout: number
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args), timeout);
};

export function createParamaters(details:any){
  var formBody:any = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    // console.log("Form Body: " + formBody);
    return formBody
}

export async function post<T>(
  path: string,
  body: any,
  args: RequestInit = { 
    method: 'POST',
    headers: {"Content-Type": "application/x-www-form-urlencoded"},
    body: createParamaters(body)
  },
  timeout: number
): Promise<HttpResponse<T>>  {
  // console.log("JSON At Request:", JSON.stringify(body));
  return await http<T>(new Request(path, args), timeout);
};

export async function put<T>(
  path: string,
  body: any,
  args: RequestInit = { method: "PUT", body: JSON.stringify(body) },
  timeout: number
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args), timeout);
};

async function fetchWithOptions(resource:any, options:any) {
  
  let response: any;

  const controller = new AbortController();
  const timeoutInterval = setTimeout(() => controller.abort(), options.timeout);

  await fetch(resource, {
    ...options,
    signal: controller.signal  
  }).then((serverResponse: any) => {
    if (serverResponse.status >= 400) {
      throw new Error(serverResponse.statusText);
    } else if (serverResponse.status === 304){
      response = serverResponse;
      return {};
    } else {
      response = serverResponse;
      return serverResponse.json();
    }
  }).then((responseBody) => {
    response.parsedBody = responseBody;
  }).finally(() => {
    clearInterval(timeoutInterval);
  });

  return response;
}
