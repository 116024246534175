import React, { useEffect } from 'react';
import { IonButton, IonIcon, IonImg, IonModal } from '@ionic/react';
import { logScreenView } from '../helpers/firebase';
import { callSharp, closeCircle, mailSharp, mailUnread } from 'ionicons/icons';

type contactUsProps = {
    showContactUs: boolean;
    toggleContactUs: any;
}

const ContactUsPage: React.FC<contactUsProps> = ({ showContactUs, toggleContactUs }) => {

    useEffect(() => {
        if (showContactUs) {
            logScreenView("odp_contactUs", "ContactUs_ODP");
        }
    }, [showContactUs]);

    return (
        <>
            <IonModal isOpen={showContactUs} id='licencesModal' animated={true} onDidDismiss={() => toggleContactUs(false)}>
                <IonIcon src={closeCircle} className='modalCloseButton' onClick={() => toggleContactUs(false)}></IonIcon>
                <h3 className='modalTitle'>Contact Us</h3>
                <div id="licencesContainer">
                    <div>
                        <h4>Have any further quesitons?</h4>
                    </div>
                    <div>

                    <h2 id="subHeading" className="textCentered">Contact us by email</h2>
                        <IonButton id="contact-us-button" href="mailto:ssenwebsite@sse.com" target="_blank" rel="noopener noreferrer" shape='round'>
                            <IonIcon src={mailSharp} id="contactUsImage"></IonIcon>
                            ssenwebsite@sse.com
                        </IonButton>

                    </div>
                </div>
            </IonModal>
        </>
    );
};

export default React.memo(ContactUsPage);