import React, { useEffect } from 'react';
import { IonIcon, IonModal } from '@ionic/react';
import { logScreenView } from '../helpers/firebase';
import { closeCircle, earthOutline } from 'ionicons/icons';
import { AppPreferences } from '../preferences/preferences';

type licencesProps = {
   showLicences: boolean;
   toggleLicences: any;
}

const LicencesPage: React.FC<licencesProps> = ({ showLicences, toggleLicences }) => {

   useEffect(() => {
      if (showLicences) {
         logScreenView("odp_licences", "Licences_ODP");
      }
   }, [showLicences]);

   return (
      <>
         <IonModal isOpen={showLicences} id='licencesModal' animated={true} onDidDismiss={() => toggleLicences(false)}>
            <IonIcon src={closeCircle} className='modalCloseButton' onClick={() => toggleLicences(false)}></IonIcon>
            <h3 className='modalTitle'>Licences and Attributions</h3>
            <p id="versionNo">
               v{AppPreferences.getAppPreferences().preferences.devPrefs.general.buildConfig!.versionNo} {AppPreferences.getAppPreferences().preferences.devPrefs.general.buildConfig.buildNo !== "{BUILD_NO}" ? " (Build " + AppPreferences.getAppPreferences().preferences.devPrefs.general.buildConfig.buildNo + ")" : ""}</p>
            <div id="licencesContainer">
               <div>
                  <h4>Developed by</h4>
                  <img height="50px" alt="Open Grid Systems Logo" src="/assets/img/OGS-Logo-Light-Large.png"></img>
               </div>
               <div>
                  <h4>Third Party Licences and Attributions</h4>
                  {licencesJSON.map((elem: any, index: number) => {
                     return (
                        <div className='licenceContainer' id={'licence-' + index} key={'licence-' + index}>
                           <div className='licenceDetails'>
                              <div className='licenceTitle'>
                                 <h5>{elem.name}</h5>
                              </div>
                           </div>
                           <div className='extendedLicenceDetails'>
                              <div className='licenceEntry'>
                                 <span>Author</span>
                                 <span>{elem.author !== undefined ? elem.author : "-"}</span>
                              </div>
                              <div className='licenceEntry'>
                                 <span>Version</span>
                                 <span>{elem.installedVersion}</span>
                              </div>
                              <div className='licenceEntry'>
                                 <span>Licence</span>
                                 <span>{elem.licenseType}</span>
                              </div>
                              <div className='licenceEntry'>
                                 <span>Link</span>
                                 <span><a rel="noopener noreferrer" target="_blank" href={elem.link}><IonIcon md={earthOutline}></IonIcon></a></span>
                              </div>
                           </div>
                        </div>
                     )
                  })}
               </div>
            </div>
         </IonModal>
      </>
   );
};

var licencesJSON = [
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@capacitor/app",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git+https://github.com/ionic-team/capacitor-plugins.git",
      "remoteVersion": "1.0.5",
      "installedVersion": "1.0.5",
      "definedVersion": "1.0.5",
      "author": "Ionic Team"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@capacitor/core",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git+https://github.com/ionic-team/capacitor.git",
      "remoteVersion": "3.6.0",
      "installedVersion": "3.4.0",
      "definedVersion": "^3.4.0",
      "author": "Ionic Team"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@capacitor/haptics",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git+https://github.com/ionic-team/capacitor-plugins.git",
      "remoteVersion": "1.1.2",
      "installedVersion": "1.1.2",
      "definedVersion": "1.1.2",
      "author": "Ionic Team"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@capacitor/keyboard",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git+https://github.com/ionic-team/capacitor-plugins.git",
      "remoteVersion": "1.1.2",
      "installedVersion": "1.1.2",
      "definedVersion": "1.1.2",
      "author": "Ionic Team"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@capacitor/status-bar",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git+https://github.com/ionic-team/capacitor-plugins.git",
      "remoteVersion": "1.0.5",
      "installedVersion": "1.0.5",
      "definedVersion": "1.0.5",
      "author": "Ionic Team"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@ionic/pwa-elements",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git+https://github.com/ionic-team/ionic-pwa-elements.git",
      "remoteVersion": "3.1.1",
      "installedVersion": "3.1.1",
      "definedVersion": "^3.1.1",
      "author": "Ionic Team"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@ionic/react",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git+https://github.com/ionic-team/ionic.git",
      "remoteVersion": "5.9.4",
      "installedVersion": "5.9.3",
      "definedVersion": "^5.9.3",
      "author": "Ionic Team"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@ionic/react-router",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git+https://github.com/ionic-team/ionic.git",
      "remoteVersion": "5.9.4",
      "installedVersion": "5.9.3",
      "definedVersion": "^5.9.3",
      "author": "Ionic Team"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@ionic/storage",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git+https://github.com/ionic-team/ionic-storage.git",
      "remoteVersion": "3.0.6",
      "installedVersion": "3.0.6",
      "definedVersion": "^3.0.6",
      "author": "Ionic Team"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@mapbox/mapbox-gl-geocoder",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "ISC",
      "link": "git+ssh://git@github.com/mapbox/mapbox-gl-geocoder.git",
      "remoteVersion": "4.7.4",
      "installedVersion": "4.7.4",
      "definedVersion": "^4.7.0",
      "author": "Mapbox"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@testing-library/jest-dom",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git+https://github.com/testing-library/jest-dom.git",
      "remoteVersion": "5.16.4",
      "installedVersion": "5.16.1",
      "definedVersion": "^5.16.1",
      "author": "Ernesto Garcia"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@testing-library/react",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git+https://github.com/testing-library/react-testing-library.git",
      "remoteVersion": "11.2.7",
      "installedVersion": "11.2.7",
      "definedVersion": "^11.2.5",
      "author": "Kent C. Dodds"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@testing-library/user-event",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git+https://github.com/testing-library/user-event.git",
      "remoteVersion": "12.8.3",
      "installedVersion": "12.8.3",
      "definedVersion": "^12.6.3",
      "author": "Giorgio Polvara"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@turf/turf",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git://github.com/Turfjs/turf.git",
      "remoteVersion": "6.5.0",
      "installedVersion": "6.5.0",
      "definedVersion": "^6.5.0",
      "author": "Turf Authors"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@types/jest",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "https://github.com/DefinitelyTyped/DefinitelyTyped.git",
      "remoteVersion": "26.0.24",
      "installedVersion": "26.0.24",
      "definedVersion": "^26.0.20",
      "author": "-"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@types/lodash",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "https://github.com/DefinitelyTyped/DefinitelyTyped.git",
      "remoteVersion": "4.14.182",
      "installedVersion": "4.14.178",
      "definedVersion": "^4.14.178",
      "author": "-"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@types/node",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "https://github.com/DefinitelyTyped/DefinitelyTyped.git",
      "remoteVersion": "12.20.55",
      "installedVersion": "12.20.42",
      "definedVersion": "^12.20.42",
      "author": "-"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@types/react",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "https://github.com/DefinitelyTyped/DefinitelyTyped.git",
      "remoteVersion": "16.14.28",
      "installedVersion": "16.14.21",
      "definedVersion": "^16.14.21",
      "author": "-"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@types/react-dom",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "https://github.com/DefinitelyTyped/DefinitelyTyped.git",
      "remoteVersion": "16.9.16",
      "installedVersion": "16.9.14",
      "definedVersion": "^16.9.10",
      "author": "-"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@types/react-router",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "https://github.com/DefinitelyTyped/DefinitelyTyped.git",
      "remoteVersion": "5.1.18",
      "installedVersion": "5.1.18",
      "definedVersion": "^5.1.18",
      "author": "-"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@types/react-router-dom",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "https://github.com/DefinitelyTyped/DefinitelyTyped.git",
      "remoteVersion": "5.3.3",
      "installedVersion": "5.3.3",
      "definedVersion": "^5.3.3",
      "author": "-"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "file-saver",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git+https://github.com/eligrey/FileSaver.js.git",
      "remoteVersion": "2.0.5",
      "installedVersion": "2.0.5",
      "definedVersion": "^2.0.5",
      "author": "Eli Grey"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "firebase",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "Apache-2.0",
      "link": "git+https://github.com/firebase/firebase-js-sdk.git",
      "remoteVersion": "9.8.3",
      "installedVersion": "9.6.4",
      "definedVersion": "^9.6.4",
      "author": "Firebase"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "ionicons",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git+https://github.com/ionic-team/ionicons.git",
      "remoteVersion": "5.5.4",
      "installedVersion": "5.5.4",
      "definedVersion": "^5.5.4",
      "author": "Ben Sperry"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "lodash",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git+https://github.com/lodash/lodash.git",
      "remoteVersion": "4.17.21",
      "installedVersion": "4.17.21",
      "definedVersion": "^4.17.21",
      "author": "John-David Dalton"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "mapbox-gl",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "SEE LICENSE IN LICENSE.txt",
      "link": "git://github.com/mapbox/mapbox-gl-js.git",
      "remoteVersion": "2.9.1",
      "installedVersion": "2.6.1",
      "definedVersion": "^2.6.1",
      "author": "-"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "react",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git+https://github.com/facebook/react.git",
      "remoteVersion": "17.0.2",
      "installedVersion": "17.0.2",
      "definedVersion": "^17.0.1",
      "author": "-"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "react-dom",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git+https://github.com/facebook/react.git",
      "remoteVersion": "17.0.2",
      "installedVersion": "17.0.2",
      "definedVersion": "^17.0.1",
      "author": "-"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "react-responsive",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git://github.com/contra/react-responsive.git",
      "remoteVersion": "8.2.0",
      "installedVersion": "8.2.0",
      "definedVersion": "*",
      "author": "Contra"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "react-router",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git+https://github.com/remix-run/react-router.git",
      "remoteVersion": "5.3.3",
      "installedVersion": "5.2.1",
      "definedVersion": "^5.2.0",
      "author": "Remix Software"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "react-router-dom",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git+https://github.com/remix-run/react-router.git",
      "remoteVersion": "5.3.3",
      "installedVersion": "5.3.0",
      "definedVersion": "^5.2.0",
      "author": "Remix Software"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "react-tooltip",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git+https://github.com/wwayne/react-tooltip.git",
      "remoteVersion": "4.2.21",
      "installedVersion": "4.2.21",
      "definedVersion": "^4.2.21",
      "author": "wwayne"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "typescript",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "Apache-2.0",
      "link": "git+https://github.com/Microsoft/TypeScript.git",
      "remoteVersion": "4.7.4",
      "installedVersion": "4.5.5",
      "definedVersion": "^4.5.0",
      "author": "Microsoft Corp."
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "web-vitals",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "Apache-2.0",
      "link": "git+https://github.com/GoogleChrome/web-vitals.git",
      "remoteVersion": "0.2.4",
      "installedVersion": "0.2.4",
      "definedVersion": "^0.2.4",
      "author": "Philip Walton"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@capacitor/cli",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git+https://github.com/ionic-team/capacitor.git",
      "remoteVersion": "3.6.0",
      "installedVersion": "3.4.0",
      "definedVersion": "^3.4.0",
      "author": "Ionic Team"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@types/file-saver",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "https://github.com/DefinitelyTyped/DefinitelyTyped.git",
      "remoteVersion": "2.0.5",
      "installedVersion": "2.0.5",
      "definedVersion": "^2.0.5",
      "author": "-"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@types/mapbox__mapbox-gl-geocoder",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "https://github.com/DefinitelyTyped/DefinitelyTyped.git",
      "remoteVersion": "4.7.2",
      "installedVersion": "4.7.2",
      "definedVersion": "^4.7.2",
      "author": "-"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@types/mapbox-gl",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "https://github.com/DefinitelyTyped/DefinitelyTyped.git",
      "remoteVersion": "1.13.3",
      "installedVersion": "1.13.2",
      "definedVersion": "^1.12.5",
      "author": "-"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "@types/react-responsive",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "https://github.com/DefinitelyTyped/DefinitelyTyped.git",
      "remoteVersion": "8.0.5",
      "installedVersion": "8.0.5",
      "definedVersion": "^8.0.5",
      "author": "-"
   },
   {
      "department": "kessler",
      "relatedTo": "stuff",
      "name": "react-scripts",
      "licensePeriod": "perpetual",
      "material": "material",
      "licenseType": "MIT",
      "link": "git+https://github.com/facebook/create-react-app.git",
      "remoteVersion": "4.0.2",
      "installedVersion": "4.0.2",
      "definedVersion": "4.0.2",
      "author": "-"
   }
]

export default React.memo(LicencesPage);