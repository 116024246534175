/**
 * Generates a GeoJSON FeatureCollection
 * @param {JSON} demandDatas
 * @return {results} GeoJSON FeatureCollection
 */
const fetchDemandData = demandData => {

    const gsp = [];
    const bsp = [];
    const pss = [];

    var listOfDemand = demandData; //.demand;
    if (listOfDemand !== undefined) {
        for (var i = 0; i < listOfDemand.length; i++) {
            const id = i;
            var demData = listOfDemand[i];

            if (demData.MarkerType === "GSP") {
                gsp.push(createGEOJSON(demData));
            } else if (demData.MarkerType === "BSP") {
                bsp.push(createGEOJSON(demData));
            } else if (demData.MarkerType === "PSS") {
                pss.push(createGEOJSON(demData));
            } 
        }
    }

    return Promise.resolve({
        gsp: {
            type: 'FeatureCollection',
            features: gsp,
        }, bsp: {
            type: 'FeatureCollection',
            features: bsp,
        }, pss: {
            type: 'FeatureCollection',
            features: pss,
        },
    });
};

const createGEOJSON = (demData) => {

    const demandList = demData.DemandList;
    const listOfDemands = [];
    if (demandList !== undefined) {
        for (var i = 0; i < demandList.length; i++) {
            const id = i;
            var demandData = demandList[i];
            var demandObj = {
                Status: `${demandData?.Status}`,
                ProjectName: `${demandData?.ProjectName}`,
                Voltage: `${demandData?.Voltage}`,
                ConnectionDate: `${demandData?.ConnectionDate}`,
                Id: `${demandData?.Id}`,
                ContractedCapacity: `${demandData?.ContractedCapacity}`,
            }
            listOfDemands.push(demandObj);
        }
    }

    const quotedJobs = demData.QuotedJobs;
    const listOfQuotedJobs = [];
    if (quotedJobs !== undefined) {
      for (var j = 0; j < quotedJobs.length; j++) {
        const id = j;
        var quotedJobData = quotedJobs[j];
        var quotedJobObj = {
            TechnologyType: `${quotedJobData?.TechnologyType}`,
          ProjectName: `${quotedJobData?.ProjectName}`,
          Reinforcement: `${quotedJobData?.Reinforcement}`,
          QuotationExpiryDate: `${quotedJobData?.QuotationExpiryDate}`,
          ShowQuotationExpiryDate: `${quotedJobData?.ShowQuotationExpiryDate}`,
          Id: `${quotedJobData?.Id}`,
          ContractedCapacity:`${quotedJobData?.ContractedCapacity}`,
          Contestable: `${quotedJobData?.Contestable}`,
          ShowReinforcement: `${quotedJobData?.ShowReinforcement}`,
          NonContestable: `${quotedJobData?.NonContestable}`,
        }

        listOfQuotedJobs.push(quotedJobObj);
      }
    }

      let classificationText = demData?.MarkerType;
      let icon = "/assets/img/gsp_demand.svg";
      if (classificationText === "BSP") {
        icon = "/assets/img/bulk_demand.svg";
      } else if (classificationText === "PSS") {
        icon = "/assets/img/substation_demand.svg";
      } 

      const place_name = '<img src='+icon+' height=15></img>' + `  ${demData?.Name?.toUpperCase()}`;

    return ({
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [demData?.Location?.Longitude, demData?.Location?.Latitude],
        },
        place_name: place_name,
        place_type: "SourceData",
        properties: {
            DataSource: "Demand",
            ParentMarker: `${demData?.ParentMarker}`,
            QuotedJobs: listOfQuotedJobs,
            CompositeId: `${demData?.CompositeId}`,
            MinimumLoad: `${demData?.MinimumLoad}`,
            ParentName: `${demData?.ParentName}`,
            ShowTransmissionWorksData: `${demData?.ShowTransmissionWorksData}`,
            BulkSupplyPoints: `${demData?.BulkSupplyPoints}`,
            ParentMarkerType: `${demData?.ParentMarkerType}`,
            Name: `${demData?.Name}`,
            ShowDistributionWorksData: `${demData?.ShowDistributionWorksData}`,
            DemandList: listOfDemands,
            TransmissionWorks: `${demData?.TransmissionWorks}`,
            DistributionConstraints: `${demData?.DistributionConstraints}`,
            Voltage: `${demData?.Voltage}`,
            ContactData: {
                Email: `${demData?.ContactData?.Email}`,
                Title: `${demData?.ContactData?.Title}`,
                Tel: `${demData?.ContactData?.Tel}`,
                Name: `${demData?.ContactData?.Name}`,
            },
            MaximumLoad: `${demData?.MaximumLoad}`,
            ParentDemandGridSupplyPoint: `${demData?.ParentDemandGridSupplyPoint}`,
            NameplateRating: `${demData?.NameplateRating}`,
            ShowContractedDemandData: `${demData?.ShowContractedDemandData}`,
            ParentId: `${demData?.ParentId}`,
            TransmissionReinforcementCompletionDate:`${demData?.TransmissionReinforcementCompletionDate}`,
            TransmissionConstraintStatus: `${demData?.TransmissionConstraintStatus}`,
            DistributionReinforcementWorks: `${demData?.DistributionReinforcementWorks}`,
            TransmissionConstraints: `${demData?.TransmissionConstraints}`,
            DistributionConstraintStatus: `${demData?.DistributionConstraintStatus}`,
            PrimarySubstations: `${demData?.PrimarySubstations}`,
            TransmissionConstraintsDisplayText: `${demData?.TransmissionConstraintsDisplayText}`,
            DistributionConstraintsDisplayText: `${demData?.DistributionConstraintsDisplayText}`,
            MarkerType: `${demData?.MarkerType}`,
            DistributionReinforcementCompletionDate: `${demData?.DistributionReinforcementCompletionDate}`,
            Id: `${demData?.Id}`,
            ShowQuotedJobData: `${demData?.ShowQuotedJobData}`,
            Area: `${demData?.Area}`
        },
    });
}

export default fetchDemandData;
