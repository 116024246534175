/**
 * Generates a GeoJSON FeatureCollection
 * @param {JSON} generationDatas
 * @return {results} GeoJSON FeatureCollection
 */
const fetchGenerationData = generationData => {

  const constrained = [];
  const unconstrained = [];
  const partiallyConstrained = [];

  const bulk_constrained = [];
  const bulk_unconstrained = [];
  const bulk_partiallyConstrained = [];

  const substation_constrained = [];
  const substation_unconstrained = [];
  const substation_partiallyConstrained = [];

  var listOfGeneration = generationData; //.generation;
  if (listOfGeneration !== undefined) {
    for (var i = 0; i < listOfGeneration.length; i++) {
      const id = i;
      var genData = listOfGeneration[i];

      if (genData?.Classification === "GSP-RED") {
        constrained.push(createGEOJSON(genData));
      } else if (genData?.Classification === "GSP-GREEN") {
        unconstrained.push(createGEOJSON(genData));
      } else if (genData?.Classification === "GSP-AMBER") {
        partiallyConstrained.push(createGEOJSON(genData));
      }else if (genData?.Classification === "BSP-RED") {
        bulk_constrained.push(createGEOJSON(genData));
      } else if (genData?.Classification === "BSP-GREEN") {
        bulk_unconstrained.push(createGEOJSON(genData));
      } else if (genData?.Classification === "BSP-AMBER") {
        bulk_partiallyConstrained.push(createGEOJSON(genData));
      }else if (genData?.Classification === "PSS-RED") {
        substation_constrained.push(createGEOJSON(genData));
      } else if (genData?.Classification === "PSS-GREEN") {
        substation_unconstrained.push(createGEOJSON(genData));
      } else if (genData?.Classification === "PSS-AMBER") {
        substation_partiallyConstrained.push(createGEOJSON(genData));
      }
    }
  }
    return Promise.resolve({
      constrained: {
        type: 'FeatureCollection',
        features: constrained,
      }, unconstrained: {
        type: 'FeatureCollection',
        features: unconstrained,
      }, partiallyConstrained: {
        type: 'FeatureCollection',
        features: partiallyConstrained,
      },bulk_constrained: {
        type: 'FeatureCollection',
        features: bulk_constrained,
      }, bulk_unconstrained: {
        type: 'FeatureCollection',
        features: bulk_unconstrained,
      }, bulk_partiallyConstrained: {
        type: 'FeatureCollection',
        features: bulk_partiallyConstrained,
      },substation_constrained: {
        type: 'FeatureCollection',
        features: substation_constrained,
      }, substation_unconstrained: {
        type: 'FeatureCollection',
        features: substation_unconstrained,
      }, substation_partiallyConstrained: {
        type: 'FeatureCollection',
        features: substation_partiallyConstrained,
      },
    });
  };

  const createGEOJSON = (genData) =>{

    const generators = genData?.Generators;
    const listOfGenerators = [];
    if (generators !== undefined) {
      for (var i = 0; i < generators.length; i++) {
        const id = i;
        var generatorData = generators[i];
        var genObj = {
          Status: `${generatorData?.Status}`,
          TechnologyType: `${generatorData?.TechnologyType}`,
          ProjectName: `${generatorData?.ProjectName}`,
          Voltage: `${generatorData?.Voltage}`,
          ShowConnectionDate: `${generatorData?.ShowConnectionDate}`,
          ConnectionDate: `${generatorData?.ConnectionDate}`,
          Id: `${generatorData?.Id}`,
          ContractedCapacity: `${generatorData?.ContractedCapacity}`,
        }
        listOfGenerators.push(genObj);
      }
    }

    const quotedJobs = genData?.QuotedJobs;
    const listOfQuotedJobs = [];
    if (quotedJobs !== undefined) {
      for (var i = 0; i < quotedJobs.length; i++) {
        const id = i;
        var quotedJobData = quotedJobs[i];
        var quotedJobObj = {
          TechnologyType: `${quotedJobData?.TechnologyType}`,
          ProjectName: `${quotedJobData?.ProjectName}`,
          Reinforcement: `${quotedJobData?.Reinforcement}`,
          QuotationExpiryDate: `${quotedJobData?.QuotationExpiryDate}`,
          ShowQuotationExpiryDate: `${quotedJobData?.ShowQuotationExpiryDate}`,
          Id: `${quotedJobData?.Id}`,
          ContractedCapacity:`${quotedJobData?.ContractedCapacity}`,
          Contestable: `${quotedJobData?.Contestable}`,
          ShowReinforcement: `${quotedJobData?.ShowReinforcement}`,
          NonContestable: `${quotedJobData?.NonContestable}`,
        }
        listOfQuotedJobs.push(quotedJobObj);
      }
    }

    let classificationText = genData?.Classification;
    let icon = "/assets/img/constrainedGSP.svg";
    if (classificationText === "GSP-GREEN") {
      icon = "/assets/img/unconstrainedGSP.svg";
    } else if (classificationText === "GSP-AMBER") {
      icon = "/assets/img/partiallyConstrainedGSP.svg";
    } else if (classificationText === "BSP-GREEN") {
      icon = "/assets/img/bulk_unconstrained.svg";
    } else if (classificationText === "BSP-AMBER") {
      icon = "/assets/img/bulk_partial.svg";
    } else if (classificationText === "BSP-RED") {
      icon = "/assets/img/bulk_constrained.svg";
    } else if (classificationText === "PSS-GREEN") {
      icon = "/assets/img/substation_unconstrained.svg";
    } else if (classificationText === "PSS-AMBER") {
      icon = "/assets/img/substation_partial.svg";
    } else if (classificationText === "PSS-RED") {
      icon = "/assets/img/substation_constrained.svg";
    }

    const place_name = '<img src='+icon+' height=15></img>' + `  ${genData?.Name.toUpperCase()}`;
  
    return({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [genData?.Location.Longitude, genData?.Location.Latitude],
      },
      place_name: place_name,
      place_type: "SourceData",
      properties: {
        DataSource: "Generation",
        ShowDistributionWorks: `${genData?.ShowDistributionWorks}`,
        ParentMarker: `${genData?.ParentMarker}`,
        ShowDistributionReinforcementCompletionDate: `${genData?.ShowDistributionReinforcementCompletionDate}`,
        ShowReversePowerflowCapability: `${genData?.ShowReversePowerflowCapability}`,
        // ConsortiaCount: `${genData?.ConsortiaCount}`,
        SiteStatus: `${genData?.SiteStatus}`,
        QuotedJobs: listOfQuotedJobs,
        CompositeId: `${genData?.CompositeId}`,
        DownstreamStatusDescription: `${genData?.DownstreamStatusDescription}`,
        MinimumLoad: `${genData?.MinimumLoad}`,
        ParentName: `${genData?.ParentName}`,
        BulkSupplyPoints: `${genData?.BulkSupplyPoints}`,
        ParentMarkerType: `${genData?.ParentMarkerType}`,
        Name: `${genData?.Name}`,
        ShowContractedGeneratorData: `${genData?.ShowContractedGeneratorData}`,
        ParentGenerationGridSupplyPoint: `${genData?.ParentGenerationGridSupplyPoint}`,
        TransmissionWorks: `${genData?.TransmissionWorks}`,
        UpstreamStatusDisplayText: `${genData?.UpstreamStatusDisplayText}`,
        ReversePowerflowCapability: `${genData?.ReversePowerflowCapability}`,
        Classification: `${genData?.Classification}`,
        Voltage: `${genData?.Voltage}`,
        MaximumLoad: `${genData?.MaximumLoad}`,
        SoWNeeded: `${genData?.SoWNeeded}`,
        OtherConstraints: `${genData?.OtherConstraints}`,
        NameplateRating: `${genData?.NameplateRating}`,
        ContractedGeneration: `${genData?.ContractedGeneration}`,
        SumOfQuotedGeneration: `${genData?.SumOfQuotedGeneration}`,
        SiteClassification: `${genData?.SiteClassification}`,
        DownstreamStatusDisplayText: `${genData?.DownstreamStatusDisplayText}`,
        ParentId: `${genData?.ParentId}`,
        DownstreamStatus: `${genData?.DownstreamStatus}`,
        TransmissionReinforcementCompletionDate: `${genData?.TransmissionReinforcementCompletionDate}`,
        UpstreamStatus: `${genData?.UpstreamStatus}`,
        DistributionReinforcementWorks: `${genData?.DistributionReinforcementWorks}`,
        Generators: listOfGenerators,
        PrimarySubstations: `${genData?.PrimarySubstations}`,
        ShowTransmissionWorks: `${genData?.ShowTransmissionWorks}`,
        PartOfSwan: `${genData?.PartOfSwan}`,
        UpstreamStatusDescription: `${genData?.UpstreamStatusDescription}`,
        MarkerType: `${genData?.MarkerType}`,
        ShowTransmissionReinforcementCompletionDate: `${genData?.ShowTransmissionReinforcementCompletionDate}`,
        BreakFaultLevelRating: `${genData?.BreakFaultLevelRating}`,
        DistributionReinforcementCompletionDate: `${genData?.DistributionReinforcementCompletionDate}`,
        Id: `${genData?.Id}`,
        ShowQuotedJobData: `${genData?.ShowQuotedJobData}`,
        AvailableHeadroomCapacity: `${genData?.AvailableHeadroomCapacity}`,
        Area: `${genData?.Area}`
      },
    });
  }

  export default fetchGenerationData;
