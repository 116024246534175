import { IonButton, IonIcon, IonGrid, IonRow, IonCol, IonSegment, IonSegmentButton, IonLabel, IonList, IonItemDivider, IonToggle } from '@ionic/react';
import { arrowBackSharp, caretDown, caretForward, closeSharp, cloudOfflineOutline, flash, funnel, helpCircleSharp, mapSharp, menuSharp, swapVerticalSharp } from 'ionicons/icons';
import React from 'react';
import MediaQuery from 'react-responsive';
import { logFirebaseEvent, logScreenView } from '../helpers/firebase';
import { GenerationCollection, FetchState, Feature } from './generationJSONStructure';
import './Sidebar.css';

type GenerationAvailabilityProps = {
  gAJSON: any;
  fetchState: FetchState;
  showSidebar: any;
  showFilter: any;
  sortByTime: any;
  sortAtoZ: any;
  showNetworkCapacity: any;
  showGenerationAvailability: any;
  handleTouchStart: any;
  handleTouchMove: any;
  handleTouchEnd: any;
  showGADetails: any;
  showSubstationDetails: any;
  northGAOn: any;
  filterGANorth: any;
  southGAOn: any;
  filterGASouth: any;
  gAToggles: any;
  setGAToggles: any;
  toggleLicences: any;
  toggleContactUs: any;
}

// const stateOfToggles = {
//   gspNorth: false,
//   bspNorth: false,
//   pssNorth: false,
//   gspSouth: false,
//   bspSouth: false,
//   pssSouth: false
// }

function displayGSPData(fC: GenerationCollection, sortAtoZ: boolean, showDetails: any, showSubstationDetails: any, region: any) {

  if (fC !== undefined) {

    var constrained = fC.constrained.features;
    var unconstrained = fC.unconstrained.features;
    var partial = fC.partiallyConstrained.features;

    let cs: any = [];
    if (region === "south") {
      cs = constrained.filter((p: { properties: any; }) => p.properties.Area === "England");
    } else {
      cs = constrained.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
    }

    let uncs: any = [];
    if (region === "south") {
      uncs = unconstrained.filter((p: { properties: any; }) => p.properties.Area === "England");
    } else {
      uncs = unconstrained.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
    }

    let part: any = [];
    if (region === "south") {
      part = partial.filter((p: { properties: any; }) => p.properties.Area === "England");
    } else {
      part = partial.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
    }

    var allGPS: Feature[] = [];
    allGPS = allGPS.concat(cs);
    allGPS = allGPS.concat(uncs);
    allGPS = allGPS.concat(part);

    let sortedGSP: Feature[] = [];
    if (sortAtoZ) {
      sortedGSP = allGPS.sort((a, b) => a?.properties?.Name.localeCompare(b?.properties?.Name));
    } else {
      sortedGSP = allGPS.sort((a, b) => b?.properties?.Name.localeCompare(a?.properties.Name));
    }

    return (
      drawTable(sortedGSP, showDetails, showSubstationDetails, "grid supply points")
    );
  }
}

function displayBSPData(fC: GenerationCollection, sortAtoZ: boolean, showDetails: any, showSubstationDetails: any, region: any) {

  if (fC !== undefined) {

    var constrained = fC.bulk_constrained.features;
    var unconstrained = fC.bulk_unconstrained.features;
    var partial = fC.bulk_partiallyConstrained.features;

    let cs: any = [];
    if (region === "south") {
      cs = constrained.filter((p: { properties: any; }) => p.properties.Area === "England");
    } else {
      cs = constrained.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
    }

    let uncs: any = [];
    if (region === "south") {
      uncs = unconstrained.filter((p: { properties: any; }) => p.properties.Area === "England");
    } else {
      uncs = unconstrained.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
    }

    let part: any = [];
    if (region === "south") {
      part = partial.filter((p: { properties: any; }) => p.properties.Area === "England");
    } else {
      part = partial.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
    }

    var allGPS: Feature[] = [];
    allGPS = allGPS.concat(cs);
    allGPS = allGPS.concat(uncs);
    allGPS = allGPS.concat(part);

    let sortedGSP: Feature[] = [];
    if (sortAtoZ) {
      sortedGSP = allGPS.sort((a, b) => a?.properties?.Name.localeCompare(b?.properties?.Name));
    } else {
      sortedGSP = allGPS.sort((a, b) => b?.properties?.Name.localeCompare(a?.properties?.Name));
    }

    return (
      drawTable(sortedGSP, showDetails, showSubstationDetails, "bulk supply points")
    );
  }
}

function displaySubstationData(fC: GenerationCollection, sortAtoZ: boolean, showDetails: any, showSubstationDetails: any, region: any) {

  if (fC !== undefined) {

    var constrained = fC.substation_constrained.features;
    var unconstrained = fC.substation_unconstrained.features;
    var partial = fC.substation_partiallyConstrained.features;

    let cs: any = [];
    if (region === "south") {
      cs = constrained.filter((p: { properties: any; }) => p.properties.Area === "England");
    } else {
      cs = constrained.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
    }

    let uncs: any = [];
    if (region === "south") {
      uncs = unconstrained.filter((p: { properties: any; }) => p.properties.Area === "England");
    } else {
      uncs = unconstrained.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
    }

    let part: any = [];
    if (region === "south") {
      part = partial.filter((p: { properties: any; }) => p.properties.Area === "England");
    } else {
      part = partial.filter((p: { properties: any; }) => p.properties.Area === "Scotland");
    }

    var allGPS: Feature[] = [];
    allGPS = allGPS.concat(cs);
    allGPS = allGPS.concat(uncs);
    allGPS = allGPS.concat(part);

    let sortedGSP: Feature[] = [];
    if (sortAtoZ) {
      sortedGSP = allGPS.sort((a, b) => a?.properties?.Name.localeCompare(b?.properties?.Name));
    } else {
      sortedGSP = allGPS.sort((a, b) => b?.properties?.Name.localeCompare(a?.properties?.Name));
    }

    return (
      drawTable(sortedGSP, showDetails, showSubstationDetails, "substations")
    );
  }
}

function drawTable(fs: Feature[], showDetails: any, showSubstationDetails: any, typeText: any) {

  if (fs !== undefined && fs.length > 0) {
    return fs.map((genData, index) => {
      if (genData?.geometry?.type === "Point") {

        let classificationText = genData?.properties?.Classification;
        let isSubstation = false;

        let icon = "/assets/img/constrainedGSP.svg";
        if (classificationText === "GSP-GREEN") {
          icon = "/assets/img/unconstrainedGSP.svg";
        } else if (classificationText === "GSP-AMBER") {
          icon = "/assets/img/partiallyConstrainedGSP.svg";
        } else if (classificationText === "BSP-GREEN") {
          icon = "/assets/img/bulk_unconstrained.svg";
        } else if (classificationText === "BSP-AMBER") {
          icon = "/assets/img/bulk_partial.svg";
        } else if (classificationText === "BSP-RED") {
          icon = "/assets/img/bulk_constrained.svg";
        } else if (classificationText === "PSS-GREEN") {
          isSubstation = true;
          icon = "/assets/img/substation_unconstrained.svg";
        } else if (classificationText === "PSS-AMBER") {
          isSubstation = true;
          icon = "/assets/img/substation_partial.svg";
        } else if (classificationText === "PSS-RED") {
          isSubstation = true;
          icon = "/assets/img/substation_constrained.svg";
        }

        return (
          <IonRow key={'row' + index} onClick={isSubstation ? () => showSubstationDetails(genData, true) : () => showDetails(genData)} >
            <IonCol size='30px' key={index + 'col1'}>
              <IonRow>
                <IonIcon id="GSPIcon" src={icon} />
              </IonRow>
            </IonCol>
            <IonCol key={index + 'col2'} className="dataListItemText">
              <div>
                <b id="GSPName">{genData.properties.Name?.toUpperCase()}</b>
              </div>
              <div>
                <b>Upstream Status:</b> {' ' + genData.properties.UpstreamStatusDisplayText}
              </div>
              <div>
                <b>Downstream Status:</b> {' ' + genData.properties.DownstreamStatusDisplayText}
              </div>
              <div className="multiLine">
                <b>Break Fault Level vs Rating (kA):</b> {' ' + genData.properties.BreakFaultLevelRating}
              </div>
              {/* <div>
                <b>Consortia Count:</b> {' ' + genData.properties.ConsortiaCount}
              </div> */}
              {classificationText.startsWith("BSP") ?
                <div>
                  <b>Corresponding {genData?.properties?.ParentMarkerType}:</b> {' ' + genData.properties.ParentGenerationGridSupplyPoint}
                </div>
                :
                <></>}
            </IonCol>
          </IonRow>
        );
      } else {
        // return an empty fragment for the areas to supress warning of no turn in arrow function
        return (<React.Fragment key={"emptySummary" + index}></React.Fragment>);
      }

    });
  } else {
    return (
      <h2 id="noData">Please adjust filters to view {typeText}</h2>
    );
  }
}

function dataLoading() {
  return (<IonList color="secondary" id='info' className="dataUnavailable" >
    <IonIcon id="loading" src="/assets/img/loading.svg"></IonIcon>
    <h4>Retrieving Data</h4>
    <p>Please Wait...</p>
  </IonList>);
}

function dataUnavailable() {
  return (<IonList color="secondary" id='info' className="dataUnavailable" >
    <IonIcon id="offline" src={cloudOfflineOutline}></IonIcon>
    <h4>Data unavailable</h4>
    <p>The data server is currently unavailable, please try again later.</p>
  </IonList>);
}

function noDataToShow() {
  return (<IonList color="secondary" id='info' className="dataUnavailable" >
    <IonIcon id="offline" src={flash}></IonIcon>
    <h4>No Data to Show</h4>
    <p>Please try adjusting your filter settings</p>
  </IonList>);
}


class GenerationAvailability extends React.Component<GenerationAvailabilityProps> {

  public state: any;

  constructor(props: any) {
    super(props);
    this.state = {
      gAToggles: this.props.gAToggles
    }
  }

  private codeSnip: any;

  chooseDisplay(e: any) {
    e.stopPropagation();
    let type: any = e.detail.value;
    if (e.detail.value == "networkCapacity") {
      logFirebaseEvent("user_interaction", { display_type: "Network Capacity" });
      this.props.showNetworkCapacity();
    } else {
      logFirebaseEvent("user_interaction", { display_type: "Generation Availability" });
      this.props.showGenerationAvailability(e);
    }
  }

  componentDidMount() {
    logScreenView("odp_gA_summary", "GA_Summary_ODP");
  }


  render() {
    let genCol: GenerationCollection = this.props.gAJSON as GenerationCollection;

    let totalConstrained = 0;
    let totalUnconstrained = 0;
    let totalPartiallyConstrained = 0;

    if (this.props.fetchState === FetchState.IDLE || this.props.fetchState === FetchState.FETCHING) {
      this.codeSnip = dataLoading();
    } else if (this.props.fetchState === FetchState.FAILED) {
      this.codeSnip = dataUnavailable();
    } else if (this.props.fetchState === FetchState.SUCCESS) {
      if (genCol !== undefined) {

        totalConstrained = genCol.constrained.features.length + genCol.bulk_constrained.features.length + genCol.substation_constrained.features.length;
        totalUnconstrained = genCol.unconstrained.features.length + genCol.bulk_unconstrained.features.length + genCol.substation_unconstrained.features.length;
        totalPartiallyConstrained = genCol.partiallyConstrained.features.length + genCol.bulk_partiallyConstrained.features.length + genCol.substation_partiallyConstrained.features.length;

        let totalGSPsToShow = totalConstrained + totalUnconstrained + totalPartiallyConstrained;

        if (totalGSPsToShow === 0) {
          this.codeSnip = noDataToShow();
        } else {
          this.codeSnip = null;
        }
      } else {
        this.codeSnip = noDataToShow();
      }
    }

    return (<>
      <div className="header" onTouchStart={touchStartEvent => this.props.handleTouchStart(touchStartEvent)} onTouchMove={touchMoveEvent => this.props.handleTouchMove(touchMoveEvent)} onTouchEnd={() => this.props.handleTouchEnd()}>
        <MediaQuery maxWidth={640}>
          <IonButton id="backHidden" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showSidebar}>
            <IonIcon md={arrowBackSharp} /> Back
          </IonButton>
        </MediaQuery>

        <MediaQuery minWidth={640}>
          {(matches: any) =>
            matches
              ?
              <IonButton id="close" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showSidebar}>
                Close
                <IonIcon md={closeSharp} />
              </IonButton>
              :
              <IonButton id="close" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showSidebar}>
                Map
                <IonIcon style={{ 'marginLeft': 8 }} md={mapSharp} />
              </IonButton>
          }
        </MediaQuery>

        <div className="icon">
          <IonIcon id="header" md={menuSharp} />
        </div>

        <h3 className='title'>Generation Availability</h3>
        <IonGrid id="headerGrid" key="grid1">
          <IonRow key="1row1">
            <IonCol id="titleColWithLine" key="1column1">
              <b>Unconstrained</b>
            </IonCol>
            <IonCol id="titleColWithLine" key="1column2">
              <b>Constrained</b>
            </IonCol>
            <IonCol id="titleCol" key="1column3">
              <b>Partially Constrained</b>
            </IonCol>
          </IonRow>

          <IonRow key="1row2">
            <IonCol id="line" key="2column1">
              {totalUnconstrained > 0 ? totalUnconstrained : '-'}
            </IonCol>
            <IonCol id="line" key="2column2">
              {totalConstrained > 0 ? totalConstrained : '-'}
            </IonCol>
            <IonCol id="noLine" key="2column3">
              {totalPartiallyConstrained > 0 ? totalPartiallyConstrained : '-'}
            </IonCol>
          </IonRow>
        </IonGrid>

      </div>

      <div className="content">
        <div className="generationListHeader">

          <IonSegment mode="md" onIonChange={(e) => this.chooseDisplay(e)} value="generationAvailability">
            <IonSegmentButton mode="md" value="generationAvailability">
              <IonLabel id="segmented">Generation Availability</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton mode="md" value="networkCapacity">
              <IonLabel id="segmented">Network Capacity</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          {/* <h5 className="h5" id="gspTitle">Grid Supply Points:</h5> */}
          <div className="filterSortbuttons">
            <IonButton size="small" color="tertiary" fill="outline" shape="round" onClick={() => window.open('https://www.ssen.co.uk/globalassets/library/connections---useful-documents/connection-flowcharts-and-guides/generation-availiability-and-network-capacity-user-guide-.pdf', '_blank')} >
              Need Help
              <IonIcon slot="end" md={helpCircleSharp} style={{ 'paddingBottom': 2 }} />
            </IonButton>
            <IonButton size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showFilter} disabled={this.props.fetchState !== FetchState.SUCCESS} >
              Filter
              <IonIcon slot="end" md={funnel} />
            </IonButton>
            <IonButton id="sort-by" color="tertiary" size="small" fill="outline" shape="round" onClick={this.props.sortByTime} disabled={this.props.fetchState !== FetchState.SUCCESS}>
              {this.props.sortAtoZ ? "A to Z" : "Z to A"}
              <IonIcon slot="end" md={swapVerticalSharp} />
            </IonButton>
          </div>
        </div>

        {this.codeSnip !== null ?
          this.codeSnip
          :
          <IonList id="gspDetailsList" className="gspDetailsList contentScroll">
            <IonGrid key="grid2" className="dataList" color="secondary" >

              {/* <IonItemDivider onClick={() => {
                stateOfToggles.north = !this.state.gAToggles.north;
                this.setState({ gAToggles: stateOfToggles });
              }}>
                {!this.state.gAToggles.north && <IonIcon md={caretForward} />}
                {this.state.gAToggles.north && <IonIcon md={caretDown} color="primary" />} */}
              <div id="areaFilterItem" className="shadedItem" >
                <IonLabel id="gspDetailsItem">Scotland</IonLabel>
                <IonToggle id="filterTog" checked={this.props.northGAOn} onIonChange={this.props.filterGANorth} />
              </div>
              {/* </IonItemDivider>
              {this.state.gAToggles.north && */}
              {this.props.northGAOn &&
                <>
                  <IonItemDivider onClick={() => {
                    this.state.gAToggles.gspNorth = !this.state.gAToggles.gspNorth;
                    this.props.setGAToggles(this.state.gAToggles);
                  }}>
                    {!this.state.gAToggles.gspNorth && <IonIcon md={caretForward} />}
                    {this.state.gAToggles.gspNorth && <IonIcon md={caretDown} color="primary" />}
                    <IonLabel id="gspDetailsItem">Grid Supply Points</IonLabel>
                  </IonItemDivider>
                  {this.state.gAToggles.gspNorth &&
                    <>
                      {displayGSPData(genCol, this.props.sortAtoZ, this.props.showGADetails, this.props.showSubstationDetails, "north")}
                    </>
                  }

                  {/* <IonItemDivider onClick={() => {
                    stateOfToggles.bspNorth = !this.state.gAToggles.bspNorth;
                    this.setState({ gAToggles: stateOfToggles });
                  }}>
        
                    {!this.state.gAToggles.bspNorth && <IonIcon md={caretForward} />}
                    {this.state.gAToggles.bspNorth && <IonIcon md={caretDown} color="primary" />}
                    <IonLabel id="gspDetailsItem">Bulk Supply Points</IonLabel>
                  </IonItemDivider>
                  {this.state.gAToggles.bspNorth &&
                    <>
                      {displayBSPData(genCol, this.props.sortAtoZ, this.props.showGADetails, this.props.showSubstationDetails, "north")}
                    </>
                  } */}
                  <IonItemDivider onClick={() => {
                    this.state.gAToggles.pssNorth = !this.state.gAToggles.pssNorth;
                    this.props.setGAToggles(this.state.gAToggles);
                    // this.setState({ gAToggles: stateOfToggles });
                  }}>
                    {!this.state.gAToggles.pssNorth && <IonIcon md={caretForward} />}
                    {this.state.gAToggles.pssNorth && <IonIcon md={caretDown} color="primary" />}
                    <IonLabel id="gspDetailsItem">Substations</IonLabel>
                  </IonItemDivider>
                  {this.state.gAToggles.pssNorth &&
                    <>
                      {displaySubstationData(genCol, this.props.sortAtoZ, this.props.showGADetails, this.props.showSubstationDetails, "north")}
                    </>
                  }
                </>
              }
              {/* } */}
              {/* <IonItemDivider onClick={() => {
                stateOfToggles.south = !this.state.gAToggles.south;
                this.setState({ gAToggles: stateOfToggles });
              }}>
                {!this.state.gAToggles.south && <IonIcon md={caretForward} />}
                {this.state.gAToggles.south && <IonIcon md={caretDown} color="primary" />} */}
              <div id="areaFilterItem" className="shadedItem" >
                <IonLabel id="gspDetailsItem">England</IonLabel>
                <IonToggle id="filterTog" checked={this.props.southGAOn} onIonChange={this.props.filterGASouth} />
              </div>
              {/* </IonItemDivider>
              {this.state.gAToggles.south && */}
              {this.props.southGAOn &&
                <>
                  <IonItemDivider onClick={() => {
                    this.state.gAToggles.gspSouth = !this.state.gAToggles.gspSouth;
                    this.props.setGAToggles(this.state.gAToggles);
                  }}>
                    {!this.state.gAToggles.gspSouth && <IonIcon md={caretForward} />}
                    {this.state.gAToggles.gspSouth && <IonIcon md={caretDown} color="primary" />}
                    <IonLabel id="gspDetailsItem">Grid Supply Points</IonLabel>
                  </IonItemDivider>
                  {this.state.gAToggles.gspSouth &&
                    <>
                      {displayGSPData(genCol, this.props.sortAtoZ, this.props.showGADetails, this.props.showSubstationDetails, "south")}
                    </>
                  }

                  <IonItemDivider onClick={() => {
                    this.state.gAToggles.bspSouth = !this.state.gAToggles.bspSouth;
                    this.props.setGAToggles(this.state.gAToggles);
                  }}>
                    {!this.state.gAToggles.bspSouth && <IonIcon md={caretForward} />}
                    {this.state.gAToggles.bspSouth && <IonIcon md={caretDown} color="primary" />}
                    <IonLabel id="gspDetailsItem">Bulk Supply Points</IonLabel>
                  </IonItemDivider>
                  {this.state.gAToggles.bspSouth &&
                    <>
                      {displayBSPData(genCol, this.props.sortAtoZ, this.props.showGADetails, this.props.showSubstationDetails, "south")}
                    </>
                  }
                  <IonItemDivider onClick={() => {
                    this.state.gAToggles.pssSouth = !this.state.gAToggles.pssSouth;
                    this.props.setGAToggles(this.state.gAToggles);
                  }}>
                    {!this.state.gAToggles.pssSouth && <IonIcon md={caretForward} />}
                    {this.state.gAToggles.pssSouth && <IonIcon md={caretDown} color="primary" />}
                    <IonLabel id="gspDetailsItem">Substations</IonLabel>
                  </IonItemDivider>
                  {this.state.gAToggles.pssSouth &&
                    <>
                      {displaySubstationData(genCol, this.props.sortAtoZ, this.props.showGADetails, this.props.showSubstationDetails, "south")}
                    </>
                  }
                </>
              }
              {/* } */}

            </IonGrid>
          </IonList>
        }

        <div className="policyFooter">
          <small><a target="_blank" rel="noopener noreferrer" href="https://www.ssen.co.uk/PrivacyNotice"><b>Privacy Policy</b></a> | <a target="_blank" rel="noopener noreferrer" href="https://www.ssen.co.uk/Cookies"><b>Cookie Notice</b></a> | <button className="linkButton" onClick={() => { this.props.toggleLicences(true); }}><b>Licences</b></button> | <button className="linkButton" onClick={() => { this.props.toggleContactUs(true); }}><b>Contact Us</b></button></small>
        </div>
      </div>
    </>);
  }
}

export default GenerationAvailability;