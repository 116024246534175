import React, { useEffect } from 'react';
import { IonButton, IonCol, IonGrid, IonIcon, IonItem, IonItemDivider, IonLabel, IonList, IonRow } from '@ionic/react';
import './FilterPage.css';
import { arrowBackSharp, caretDown, caretForward, closeSharp, mapOutline, mapSharp } from 'ionicons/icons';
import MediaQuery from 'react-responsive';
import { Feature } from './generationJSONStructure';
import { logScreenView } from '../helpers/firebase';
// import { isCollectingAnalytics } from '../helpers/httpHelper';

type GADetailsProps = {
  feature: any;
  showDetails: any;
  showSideBar: any;
  zoomToMapReturnBounds: any;
  handleTouchStart: any;
  handleTouchMove: any;
  handleTouchEnd: any;
  associatedSubstations: any;
  showSubstations: any;
  clearAssAssets: any;
  showSubstationDetails: any;
  associatedBSPs: any
  showBSPDetails: any;
  showAssociatedBSPs: any;
  parentGSP: any;
  showParentDetails: any;
  showCorrespondingGSP: any;
  shouldShowCorrespondingLink: any;
}

const stateOfToggles = {
  info: true,
  additionalInfo: false,
  contractedGenerators: false,
  quotedGenerators: false,
  registerForConsortia: false,
  substations: true,
  assBsps: true
}


function drawSubstations(fs: Feature[], showSubstationDetails: any, showSubstations: any) {

  if (fs !== undefined) {

    if (fs?.length > 1) {
      fs?.sort((a, b) => a?.properties.Name.localeCompare(b?.properties.Name));
    }

    return fs?.map((genData, index) => {
      if (genData?.geometry?.type === "Point") {

        let icon = "/assets/img/substation_constrained.svg";
        if (genData.properties.Classification === "PSS-GREEN") {
          icon = "/assets/img/substation_unconstrained.svg";
        } else if (genData.properties.Classification === "PSS-AMBER") {
          icon = "/assets/img/substation_partial.svg";
        }

        return (
          <IonRow key={'row' + index} onClick={() => { showSubstations(); showSubstationDetails(genData, false); }}>
            <IonCol size='30px' key={index + 'col1'}>
              <IonRow>
                <IonIcon id="GSPIcon" src={icon} />
              </IonRow>
            </IonCol>
            <IonCol key={index + 'col2'} className="dataListItemText">
              <div>
                <b id="GSPName">{genData.properties.Name}</b>
              </div>
              <div>
                <b>Upstream Status:</b> {' ' + genData.properties.UpstreamStatusDisplayText}
              </div>
              <div>
                <b>Downstream Status:</b> {' ' + genData.properties.DownstreamStatusDisplayText}
              </div>
              <div>
                <b>Break Fault Level vs Rating (kA):</b> {' ' + genData.properties.BreakFaultLevelRating}
              </div>
            </IonCol>
          </IonRow>
        );
      } else {
        // return an empty fragment for the areas to supress warning of no turn in arrow function
        return (<React.Fragment key={"emptySummary" + index}></React.Fragment>);
      }

    });
  }
}

function drawBSPS(fs: Feature[], showBSPDetails: any, showBSPs: any) {

  if (fs !== undefined) {

    if (fs?.length > 1) {
      fs?.sort((a, b) => a?.properties.Name.localeCompare(b?.properties.Name));
    }

    return fs?.map((genData, index) => {
      if (genData?.geometry?.type === "Point") {

        let icon = "/assets/img/bulk_constrained.svg";
        if (genData.properties.Classification === "BSP-GREEN") {
          icon = "/assets/img/bulk_unconstrained.svg";
        } else if (genData.properties.Classification === "BSP-AMBER") {
          icon = "/assets/img/bulk_partial.svg";
        }

        return (
          <IonRow key={'row' + index} onClick={() => { showBSPs(); showBSPDetails(genData); }}>
            <IonCol size='30px' key={index + 'col1'}>
              <IonRow>
                <IonIcon id="GSPIcon" src={icon} />
              </IonRow>
            </IonCol>
            <IonCol key={index + 'col2'} className="dataListItemText">
              <div>
                <b id="GSPName">{genData.properties.Name}</b>
              </div>
              <div>
                <b>Upstream Status:</b> {' ' + genData.properties.UpstreamStatusDisplayText}
              </div>
              <div>
                <b>Downstream Status:</b> {' ' + genData.properties.DownstreamStatusDisplayText}
              </div>
              <div>
                <b>Break Fault Level vs Rating (kA):</b> {' ' + genData.properties.BreakFaultLevelRating}
              </div>
            </IonCol>
          </IonRow>
        );
      } else {
        // return an empty fragment for the areas to supress warning of no turn in arrow function
        return (<React.Fragment key={"emptySummary" + index}></React.Fragment>);
      }

    });
  }
}

class GADetails extends React.Component<GADetailsProps>{

  public state: any;

  constructor(props: any) {
    super(props);
    this.state = {
      gAToggles: {
        info: true,
        additionalInfo: false,
        contractedGenerators: false,
        quotedGenerators: false,
        registerForConsortia: false,
        substations: true,
        assBsps: true
      }
    }
  }

  componentDidMount() {
    logScreenView("odp_gA_details", "GA_Details_ODP");
  }

  closeGADetails = () => {
    this.props.zoomToMapReturnBounds();
    this.props.showDetails();
    this.props.clearAssAssets(); //should this be in mapboxcontainer?
  }

  showCorrespoindingGSP = () => {
    let thisItem = this.props.feature.properties;
    this.props.showCorrespondingGSP(thisItem?.ParentMarker, true);
  }

  render() {

    let gAColl = this.props.feature?.properties;
    const gALat = this.props.feature?.geometry?.coordinates[1].toFixed(4);
    const gALong = this.props.feature?.geometry?.coordinates[0].toFixed(4);


    //TODO:: Work out why this is a string!!
    let listOfContractedGenerators: any = [];
    let listOfQuotedGenerators: any = [];

    if (gAColl != null) {
      if (gAColl.Generators instanceof Array) {
        listOfContractedGenerators = gAColl.Generators;
      } else {
        let obj = JSON.parse(gAColl.Generators);
        listOfContractedGenerators = obj;
      }

      if (gAColl.QuotedJobs instanceof Array) {
        listOfQuotedGenerators = gAColl.QuotedJobs;
      } else {
        let obj = JSON.parse(gAColl.QuotedJobs);
        listOfQuotedGenerators = obj;
      }
    }

    let classificationText = gAColl?.Classification;
    let icon = "/assets/img/constrainedGSP.svg";
    if (classificationText === "GSP-GREEN") {
      icon = "/assets/img/unconstrainedGSP.svg";
    } else if (classificationText === "GSP-AMBER") {
      icon = "/assets/img/partiallyConstrainedGSP.svg";
    } else if (classificationText === "BSP-GREEN") {
      icon = "/assets/img/bulk_unconstrained.svg";
    } else if (classificationText === "BSP-AMBER") {
      icon = "/assets/img/bulk_partial.svg";
    } else if (classificationText === "BSP-RED") {
      icon = "/assets/img/bulk_constrained.svg";
    }

    let isGSP = true;
    if (classificationText !== null && classificationText !== undefined && classificationText?.startsWith("BSP")) {
      isGSP = false;
    }

    let siteClassColor = "/assets/img/red.svg";
    if (gAColl?.SiteClassification == "Amber") {
      siteClassColor = "/assets/img/amber.svg"
    } else if (gAColl?.SiteClassification == "Green") {
      siteClassColor = "/assets/img/green.svg"
    }

    let upstreamColor = "/assets/img/red.svg";
    if (gAColl?.UpstreamStatusDisplayText == "Partially Constrained") {
      upstreamColor = "/assets/img/amber.svg"
    } else if (gAColl?.UpstreamStatusDisplayText == "Unconstrained") {
      upstreamColor = "/assets/img/green.svg"
    }

    let downstreamColor = "/assets/img/red.svg";
    if (gAColl?.DownstreamStatusDisplayText == "Partially Constrained") {
      downstreamColor = "/assets/img/amber.svg"
    } else if (gAColl?.DownstreamStatusDisplayText == "Unconstrained") {
      downstreamColor = "/assets/img/green.svg"
    }

    return (

      <>
        <div className="header" onTouchStart={touchStartEvent => this.props.handleTouchStart(touchStartEvent)} onTouchMove={touchMoveEvent => this.props.handleTouchMove(touchMoveEvent)} onTouchEnd={() => this.props.handleTouchEnd()}>
          {this.props.parentGSP !== null ?
            <IonButton id="back" size="small" color="tertiary" fill="outline" shape="round" onClick={() => this.props.showParentDetails(this.props.parentGSP)}>
              <IonIcon md={arrowBackSharp} />
              Back
            </IonButton>
            :
            <IonButton id="back" size="small" color="tertiary" fill="outline" shape="round" onClick={this.closeGADetails}>
              <IonIcon md={arrowBackSharp} />
              List
            </IonButton>
          }

          <MediaQuery minWidth={640}>
            {(matches: any) =>
              matches
                ?
                <IonButton id="close" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showSideBar}>
                  Close
                  <IonIcon md={closeSharp} />
                </IonButton>
                :
                <IonButton id="close" size="small" color="tertiary" fill="outline" shape="round" onClick={this.props.showSideBar}>
                  Map
                  <IonIcon style={{ 'marginLeft': 8 }} md={mapSharp} />
                </IonButton>
            }
          </MediaQuery>

          <div className="detailsIconWithButtons">
            <IonIcon id="detailsheader" src={icon} />
          </div>
          <h3 className='detailsTitle'>{gAColl?.Name?.toUpperCase()}</h3>
          <IonGrid key="gADetailsGrid" id="detailsheaderGrid" color="secondary" >
            <IonRow key="detailsRow1">
              <IonCol key='col1row1' className="detailsHeaderLeftColext">
                Location (Lat, Long)
              </IonCol>
              <div className="textSeperator highOpacity"></div>
              <IonCol key='col2row1' className="detailsHeaderRightColText">
                {gALat},{gALong}
              </IonCol>
            </IonRow>
            {gAColl?.MinimumLoad !== "undefined" &&
              <IonRow key="detailsRow2">
                <IonCol key='col1row2' className="detailsHeaderLeftColext">
                  Minimum Load (MW)
                </IonCol>
                <div className="textSeperator highOpacity"></div>
                <IonCol key='col2row2' className="detailsHeaderRightColText">
                  {parseFloat(gAColl?.MinimumLoad).toFixed(2)}
                </IonCol>
              </IonRow>
            }
            {gAColl?.MaximumLoad !== "undefined" &&
              <IonRow key="detailsRow3">
                <IonCol key='col1row3' className="detailsHeaderLeftColext">
                  Maximum Load (MW)
                </IonCol>
                <div className="textSeperator highOpacity"></div>
                <IonCol key='col2row3' className="detailsHeaderRightColText">
                  {parseFloat(gAColl?.MaximumLoad).toFixed(2)}
                </IonCol>
              </IonRow>
            }
            {((gAColl?.ShowContractedGeneratorData === "true") || (gAColl?.ContractedGeneration !== "" && gAColl?.ContractedGeneration !== "undefined")) &&
              <IonRow key="detailsRow4">
                <IonCol key='col1row4' className="detailsHeaderLeftColext">
                  Contracted Generation (MVA)
                </IonCol>
                <div className="textSeperator highOpacity"></div>
                <IonCol key='col2row4' className="detailsHeaderRightColText">
                  {parseFloat(gAColl?.ContractedGeneration).toFixed(2)}
                </IonCol>
              </IonRow>
            }
            {((gAColl?.ShowReversePowerflowCapability === "true") || (gAColl?.ReversePowerflowCapability !== "" && gAColl?.ReversePowerflowCapability !== "undefined")) &&
              <IonRow key="detailsRow5">
                <IonCol key='col1row5' className="detailsHeaderLeftColext">
                  Reverse Powerflow Capacity (%)
                </IonCol>
                <div className="textSeperator highOpacity"></div>
                <IonCol key='col2row5' className="detailsHeaderRightColText">
                  {gAColl?.ReversePowerflowCapability}
                </IonCol>
              </IonRow>
            }
            <IonRow key="detailsRow6">
              <IonCol key='col1row6' className="detailsHeaderLeftColext">
                Sum of Quoted Generation (MVA)
              </IonCol>
              <div className="textSeperator highOpacity"></div>
              <IonCol key='col2row6' className="detailsHeaderRightColText">
                {gAColl?.SumOfQuotedGeneration}
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        <div className="content" id="gspDetailsContent">
          <IonList id="gspDetailsList" className="gspDetailsList contentScroll">
            <IonItemDivider onClick={() => {
              stateOfToggles.info = !this.state.gAToggles.info;
              this.setState({ gAToggles: stateOfToggles });
            }}>
              {!this.state.gAToggles.info && <IonIcon md={caretForward} />}
              {this.state.gAToggles.info && <IonIcon md={caretDown} color="primary" />}
              {isGSP ? <IonLabel id="gspDetailsItem">Grid Supply Point Information</IonLabel> : <IonLabel id="gspDetailsItem">Bulk Supply Point (BSP) Information</IonLabel>}
            </IonItemDivider>
            {this.state.gAToggles.info &&
              <>
                <IonItem color="secondary">
                  <IonGrid className="gspDetailsGrid">
                    <IonCol key='gspCol' className="gspDetailsItemText">
                      {gAColl?.SiteClassification !== "undefined" &&
                        <div>
                          <b>Site Classification:</b>
                          <div id="siteClassification">
                            <IonIcon id="iconClassification" src={siteClassColor}></IonIcon>
                            <IonLabel id="labelClassification">{'' + gAColl?.SiteClassification}</IonLabel>
                          </div>
                        </div>
                      }
                      <div>
                        <b>Upstream Status:</b>
                        <div id="siteClassification">
                          <IonIcon id="iconClassification" src={upstreamColor}></IonIcon>
                          <IonLabel id="labelClassification">{' ' + gAColl?.UpstreamStatusDisplayText}</IonLabel>
                        </div>
                      </div>
                      <div>
                        <b>Downstream Status:</b>
                        <div id="siteClassification">
                          <IonIcon id="iconClassification" src={downstreamColor}></IonIcon>
                          <IonLabel id="labelClassification">{' ' + gAColl?.DownstreamStatusDisplayText}</IonLabel>
                        </div>
                      </div>
                      <div key='subInfoTNP'>
                        <b>Transformer Nameplate Rating (MVA):</b> {' ' + gAColl?.NameplateRating}
                      </div>
                      <div>
                        <b>Break Fault Level vs Rating (kA):</b> {' ' + gAColl?.BreakFaultLevelRating}
                      </div>
                      {((gAColl?.ShowTransmissionWorks === "true" && gAColl?.TransmissionWorks !== "") || (gAColl?.TransmissionWorks !== "" && gAColl?.TransmissionWorks !== "undefined")) &&
                        <div>
                          <b>Transmission Works:</b> {' ' + gAColl?.TransmissionWorks}
                        </div>}
                      {((gAColl?.ShowTransmissionReinforcementCompletionDate === "true" && gAColl?.TransmissionReinforcementCompletionDate !== "") || (gAColl?.TransmissionReinforcementCompletionDate !== "" && gAColl?.TransmissionReinforcementCompletionDate !== "undefined")) &&
                        <div>
                          <b>Transmission Reinforcement Completion Date:</b> {' ' + gAColl?.TransmissionReinforcementCompletionDate}
                        </div>}
                      {((gAColl?.ShowDistributionWorks === "true" && gAColl?.DistributionReinforcementWorks !== "") || (gAColl?.DistributionReinforcementWorks !== "" && gAColl?.DistributionReinforcementWorks !== "undefined")) &&
                        <div>
                          <b>Distribution Works:</b> {' ' + gAColl?.DistributionReinforcementWorks}
                        </div>}
                      {((gAColl?.ShowDistributionReinforcementCompletionDate === "true" && gAColl?.DistributionReinforcementCompletionDate !== "") || (gAColl?.DistributionReinforcementCompletionDate !== "" && gAColl?.DistributionReinforcementCompletionDate !== "undefined")) &&
                        <div>
                          <b>Distribution Reinforcement Completion Date:</b> {' ' + gAColl?.DistributionReinforcementCompletionDate}
                        </div>}
                      {/* <div>
                        <b>Consortia Count:</b> {' ' + gAColl?.ConsortiaCount}
                      </div> */}

                      {gAColl?.ParentMarker !== "null" &&
                        <div className="correspondingGSP">
                          <b>Corresponding {gAColl?.ParentMarkerType}:</b>
                          {this.props.shouldShowCorrespondingLink ?
                            <a onClick={this.showCorrespoindingGSP}>
                              {' ' + gAColl?.ParentGenerationGridSupplyPoint}
                            </a>
                            :
                            <>
                              {' ' + gAColl?.ParentGenerationGridSupplyPoint}
                            </>}
                        </div>
                      }
                    </IonCol>
                  </IonGrid>
                </IonItem>
              </>
            }


            {(isGSP && listOfContractedGenerators?.length !== 0) &&
              <>
                <IonItemDivider onClick={() => {
                  stateOfToggles.contractedGenerators = !this.state.gAToggles.contractedGenerators;
                  this.setState({ gAToggles: stateOfToggles });
                }}>
                  {!this.state.gAToggles.contractedGenerators && <IonIcon md={caretForward} />}
                  {this.state.gAToggles.contractedGenerators && <IonIcon md={caretDown} color="primary" />}
                  <IonLabel id="gspDetailsItem">Contracted Generators</IonLabel>
                </IonItemDivider>
                {this.state.gAToggles.contractedGenerators &&
                  <>
                    <IonItem color="secondary">
                      <IonGrid className="gspDetailsGrid">
                        <IonCol key='gspCol2' className="gspDetailsItemText">
                          {listOfContractedGenerators.map((elem: any, index: any) => {
                            return (
                              <div key={"contractedGen" + index} className="listOfGens">
                                <div key={"pName" + index}>
                                  <b>Project Name:</b> <div className="textSeperator"></div>  <span>{' ' + elem?.ProjectName}</span>
                                </div>
                                <div key={"tech" + index}>
                                  <b>Technology Type:</b> <div className="textSeperator"></div> <span>{' ' + elem?.TechnologyType}</span>
                                </div>
                                <div key={"state" + index}>
                                  <b>State:</b> <div className="textSeperator"></div> <span>{' ' + elem?.Status}</span>
                                </div>
                                <div key={"voltage" + index}>
                                  <b>Voltage (kV):</b> <div className="textSeperator"></div> <span>{' ' + elem?.Voltage}</span>
                                </div>
                                <div key={"capacity" + index}>
                                  <b>Contracted Capacity (MVA):</b> <div className="textSeperator"></div> <span>{' ' + elem?.ContractedCapacity}</span>
                                </div>
                                {((elem?.ConnectionDate !== "" && elem?.ConnectionDate !== "undefined") || (elem?.ShowConnectionDate === "true")) &&
                                  <div key={"connectionDate" + index}>
                                    <b>Connection Date:</b> <div className="textSeperator"></div> <span>{' ' + elem?.ConnectionDate}</span>
                                  </div>
                                }
                              </div>
                            )
                          })}
                        </IonCol>
                      </IonGrid>
                    </IonItem>
                  </>
                }

                {(listOfQuotedGenerators?.length !== 0 || gAColl.ShowQuotedJobData === "true") &&
                  <>
                    <IonItemDivider onClick={() => {
                      stateOfToggles.quotedGenerators = !this.state.gAToggles.quotedGenerators;
                      this.setState({ gAToggles: stateOfToggles });
                    }}>
                      {!this.state.gAToggles.quotedGenerators && <IonIcon md={caretForward} />}
                      {this.state.gAToggles.quotedGenerators && <IonIcon md={caretDown} color="primary" />}
                      <IonLabel id="gspDetailsItem">Quoted Generators</IonLabel>
                    </IonItemDivider>
                    {this.state.gAToggles.quotedGenerators &&
                      <>
                        <IonItem color="secondary">
                          <IonGrid className="gspDetailsGrid">
                            <IonCol key='quotedJobsCol2' className="gspDetailsItemText">
                              {listOfQuotedGenerators.map((elem: any, index: any) => {
                                return (
                                  <div key={"quotedJobs" + index} className="listOfGens">
                                    <div key={"pNameQJ" + index}>
                                      <b>Project Name:</b> <div className="textSeperator"></div> {' ' + elem?.ProjectName}
                                    </div>
                                    <div key={"techQJ" + index}>
                                      <b>Technology Type:</b> <div className="textSeperator"></div> {' ' + elem?.TechnologyType}
                                    </div>
                                    <div key={"capacityQJ" + index}>
                                      <b>Contracted Capacity (MVA):</b> <div className="textSeperator"></div> {' ' + elem?.ContractedCapacity}
                                    </div>
                                    {((elem?.ShowQuotationExpiryDate === "true") || (elem?.QuotationExpiryDate !== "undefined" && elem?.QuotationExpiryDate !== "")) &&
                                      <div key={"expiryQJ" + index}>
                                        <b>Quotation Expiry Date:</b> <div className="textSeperator"></div> {' ' + elem?.QuotationExpiryDate}
                                      </div>
                                    }
                                    <div key={"contestableQJ" + index}>
                                      <b>Contestable:</b> <div className="textSeperator"></div> {' ' + elem?.Contestable}
                                    </div>
                                    <div key={"noncontestableQJ" + index}>
                                      <b>Non-contestable:</b> <div className="textSeperator"></div> {' ' + elem?.NonContestable}
                                    </div>
                                    {((elem?.ShowReinforcement === "true") || (elem?.Reinforcement !== "undefined" && elem?.Reinforcement !== "")) &&
                                      <div key={"reinforcement" + index}>
                                        <b>Reinforcement:</b> <div className="textSeperator"></div> {' ' + elem?.Reinforcement}
                                      </div>
                                    }
                                  </div>
                                )
                              })}
                            </IonCol>
                          </IonGrid>
                        </IonItem>
                      </>
                    }
                  </>
                }
              </>
            }

            <>
              <IonItemDivider onClick={() => {
                stateOfToggles.additionalInfo = !this.state.gAToggles.additionalInfo;
                this.setState({ gAToggles: stateOfToggles });
              }}>
                {!this.state.gAToggles.additionalInfo && <IonIcon md={caretForward} />}
                {this.state.gAToggles.additionalInfo && <IonIcon md={caretDown} color="primary" />}
                <IonLabel id="gspDetailsItem">Additional Information</IonLabel>
              </IonItemDivider>
              {this.state.gAToggles.additionalInfo &&
                <>
                  <IonItem color="secondary">
                    <IonGrid className="gspDetailsGrid">
                      <IonCol key='gspCol' className="gspDetailsItemText">
                        <div>
                          <b>Sow Needed?</b> {' ' + gAColl?.SoWNeeded}
                        </div>
                        <div>
                          <b>Part of SWAN?</b> {' ' + gAColl?.PartOfSwan}
                        </div>
                        <div>
                          <b>Other Constraints:</b> {' ' + gAColl?.OtherConstraints}
                        </div>
                        <div>
                          <b>Voltage (kV)</b> {' ' + gAColl?.Voltage}
                        </div>
                      </IonCol>
                    </IonGrid>
                  </IonItem>
                </>
              }

            </>


            <IonItemDivider onClick={() => {
              stateOfToggles.registerForConsortia = !this.state.gAToggles.registerForConsortia;
              this.setState({ gAToggles: stateOfToggles });
            }}>
              {!this.state.gAToggles.registerForConsortia && <IonIcon md={caretForward} />}
              {this.state.gAToggles.registerForConsortia && <IonIcon md={caretDown} color="primary" />}
              <IonLabel id="gspDetailsItem">Registration of Consortia</IonLabel>
            </IonItemDivider>
            {this.state.gAToggles.registerForConsortia &&
              <>
                <IonItem color="secondary">
                  <IonLabel className='multiLine'>If you are interested in exploring the potential to set up a consortium to
                    enable developers to share the cost of reinforcement, please <a href="https://www.ssen.co.uk/ConsortiaRegistration/Register/" target="_blank" rel="noopener noreferrer">register</a>.</IonLabel>
                </IonItem>
              </>
            }

            {isGSP && this.props.associatedBSPs?.length !== 0 &&
              <>
                <IonItemDivider onClick={() => {
                  stateOfToggles.assBsps = !this.state.gAToggles.assBsps;
                  this.setState({ gAToggles: stateOfToggles });
                }}>
                  {!this.state.gAToggles.assBsps && <IonIcon md={caretForward} />}
                  {this.state.gAToggles.assBsps && <IonIcon md={caretDown} color="primary" />}
                  <IonLabel id="gspDetailsItem">Bulk Supply Points</IonLabel>
                </IonItemDivider>
                {this.state.gAToggles.assBsps &&
                  <>
                    <IonItem color="secondary">
                      <IonGrid>
                        <IonRow class="ion-justify-content-center">
                          <IonButton shape="round" className="sidebarButtonFixedWidth" size="small" onClick={() => this.props.showAssociatedBSPs()}>
                            <IonIcon slot="end" icon={mapOutline} />
                            View BSPs on Map
                          </IonButton>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                    <IonGrid key="grid2" className="dataList" color="secondary" >
                      {drawBSPS(this.props.associatedBSPs, this.props.showBSPDetails, this.props.showAssociatedBSPs)}
                    </IonGrid>
                  </>
                }
              </>
            }

            {this.props.associatedSubstations?.length !== 0 &&
              <>
                <IonItemDivider onClick={() => {
                  stateOfToggles.substations = !this.state.gAToggles.substations;
                  this.setState({ gAToggles: stateOfToggles });
                }}>
                  {!this.state.gAToggles.substations && <IonIcon md={caretForward} />}
                  {this.state.gAToggles.substations && <IonIcon md={caretDown} color="primary" />}
                  <IonLabel id="gspDetailsItem">Substations</IonLabel>
                </IonItemDivider>
                {this.state.gAToggles.substations &&
                  <>
                    <IonItem color="secondary">
                      <IonGrid>
                        <IonRow class="ion-justify-content-center">
                          <IonButton shape="round" className="sidebarButtonFixedWidth" size="small" onClick={() => this.props.showSubstations()}>
                            <IonIcon slot="end" icon={mapOutline} />
                            View Substations on Map
                          </IonButton>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                    <IonGrid key="grid2" className="dataList" color="secondary" >
                      {drawSubstations(this.props.associatedSubstations, this.props.showSubstationDetails, this.props.showSubstations)}
                    </IonGrid>
                  </>
                }
              </>}
          </IonList>
        </div>
      </>
    );
  };
}

export default GADetails;